import {makeAutoObservable, runInAction, toJS} from "mobx";
import {history} from "../..";
import agent from "../api/agent";
import {User, UserFormValues} from "../models/user";
import {store} from "./store";
import {toast} from "react-toastify";


export default class UserStore {
    user: User | null = null;
    isVetter: boolean = false;
    isActionController: boolean = false;
    isPartyGroupRolesLoaded: boolean = false;
    isActionOwner: boolean = false;
    userDepartment: string | null = null;
    emailUrl: string = '';


    constructor() {
        makeAutoObservable(this)
        
        console.log('User Store constructor')

    }

    setEmailUrl = (url: string) => {
        this.emailUrl = url;
    }
    
    
    get isLoggedIn() {
        return !!this.user;
    }

    loadPartyGroupRoles = async () => {

        try {
            const result = await agent.PartyGroupRoles.list();
            console.log('loadPartyGroupRoles')
            console.log('loadPartyGroupRoles User', this.user)
            runInAction(() => {
                if (result.find(item => item.diRoleId === 'Vetter' && item.appUserId === this.user?.id))
                    this.isVetter = true;
                console.log('isVetter', this.isVetter)
                console.log('User', this.user?.username)

                if (result.find(item => item.diRoleId === 'ActionOwner' && item.appUserId === this.user?.id))
                    this.isActionOwner = true;

                if (result.find(item => item.diRoleId === 'ActionController' && item.appUserId === this.user?.id))
                    this.isActionController = true;
            });


        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    loadUserDepartment = async () => {
        try {
            const result = toJS(await agent.Users.GetUserDepartment());
            runInAction(() => {
                // @ts-ignore
                this.userDepartment = result!.groupName
            });


        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    login = async (creds: UserFormValues) => {
        try {
            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            console.log('UserFormValues User', this.user)
            store.adminStore.loadPartyGroups()
            console.log('emailUrl', this.emailUrl)
            if (this.emailUrl !== '') {
                console.log('Entered Method')
                const url = this.emailUrl
                this.setEmailUrl('')
                history.push(url);
            } else {
                history.push('/ideas');
            }
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    }

    logout = () => {
        store.commonStore.setToken(null);
        window.localStorage.removeItem('jwt');
        this.user = null;
        console.log('logout User', this.user)
        store.adminStore.setIsStaticDataLoaded(false)
        history.push('/');
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            console.log('getUser User', this.user)
           
            runInAction(() => this.user = user);
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    register = async (creds: UserFormValues) => {
        try {
            const user = await agent.Account.register(creds);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            history.push('/ideas');
            store.modalStore.closeModal();
        } catch (error) {
            throw error;
        }
    }

    setImage = (image: string) => {
        if (this.user) this.user.image = image;
    }

    setDisplayName = (name: string) => {
        if (this.user) this.user.displayName = name;
    }
}