import React, {useEffect} from "react";
import {Segment, Tab} from "semantic-ui-react";
import OriginatorIdeaList from "./OriginatorIdeaList";
import {observer} from "mobx-react-lite";
import OriginatorIdeaFilters from "./OriginatorIdeaFilters";
import {useStore} from "../../../app/stores/store";
import VetterIdeaFilters from "./VetterIdeaFilters";
import VetterdeaList from "./VetterdeaList";
import ActionIdeaFilters from "./ActionIdeaFilters";
import ActionIdeaList from "./ActionIdeaList";
import ControllerIdeaFilters from "./ControllerIdeaFilters";
import ControllerIdeaList from "./ControllerIdeaList";
import {toJS} from "mobx";

export default observer(function IdeaDashboard() {
    const {ideaStore} = useStore();
    const {userStore} = useStore();
    const {isVetter, isActionOwner, isActionController, loadPartyGroupRoles} = userStore;
    const {activeIndex, panes, setPanes, updatePanes} = ideaStore;

    useEffect(() => {
        loadPartyGroupRoles()
        setPanes(
            [
                {
                    menuItem: {key: 'originator', icon: 'idea', content: 'My Initiatives'},
                    render: () => <Tab.Pane>
                        <Segment basic vertical>
                            <OriginatorIdeaFilters/>
                        </Segment>
                        <Segment basic vertical>
                            <OriginatorIdeaList/>
                        </Segment>
                    </Tab.Pane>,
                },

            ]
        )
        if (isVetter) updatePanes(vetter) //panes.push(vetter)
        if (isActionOwner) updatePanes(actionOwner) //panes.push(actionOwner)
        if (isActionController) updatePanes(actionController) //panes.push(actionController)


    }, [setPanes, isVetter, isActionOwner, isActionController]);

    const vetter = {
        menuItem: {key: 'vetter', icon: 'check', content: 'Pending Initiatives'},
        render: () => <Tab.Pane>
            <Segment basic vertical>
                <VetterIdeaFilters/>
            </Segment>
            <Segment basic vertical>
                <VetterdeaList/>
            </Segment>
        </Tab.Pane>,
    }

    const actionOwner = {
        menuItem: {key: 'actionOwner', icon: 'tasks', content: 'Action Sheet'},
        render: () => <Tab.Pane>
            <Segment basic vertical>
                <ActionIdeaFilters/>
            </Segment>
            <Segment basic vertical>
                <ActionIdeaList/>
            </Segment>
        </Tab.Pane>,
    }

    const actionController = {
        menuItem: {key: 'actionController', icon: 'usd', content: 'Financial Controller Sheet'},
        render: () => <Tab.Pane>
            <Segment basic vertical>
                <ControllerIdeaFilters/>
            </Segment>
            <Segment basic vertical>
                <ControllerIdeaList/>
            </Segment>
        </Tab.Pane>,
    }


    console.log('panes', toJS(panes))
    return (
        <div>
            <Segment basic vertical>
                <Tab panes={panes} defaultActiveIndex={activeIndex}/>
            </Segment>

        </div>

    );
})

