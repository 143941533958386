import React, {useEffect, useMemo, useState} from 'react';
import {Button, Grid, Segment} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import FormInput from '../../../app/common/form/FormInput';
import {useStore} from '../../../app/stores/store';
import {useHistory} from 'react-router-dom';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {toJS} from "mobx";
import {MemoizedFormDropDownList} from "../../../app/common/form/MemoizedFormDropDownList";
import {CreateBaseLineParameterFormValues} from "../../../app/models/baseLineParameter";


const BaseLineParameterForm = () => {
    const history = useHistory();
    const {ideaStore, adminStore} = useStore();


    const MyForm = React.useRef<any>()

    let {loadingInitial, filteredGroupLivers} = ideaStore;

    let {
        createBaseLineParameter, baseLineParameterLoaded,diTypes, groupLivers,
        setBaseLineParameterLoaded, selectedBaseLineParameter, clearSelectedBaseLineParameter, editBaseLineParameter
    } = adminStore;
    let diTypeData = useMemo(() => diTypes ? toJS(diTypes) : [], [diTypes])
    groupLivers = useMemo(() => diTypes ? toJS(groupLivers) : [], [groupLivers])
    filteredGroupLivers = useMemo(() => filteredGroupLivers ? toJS(filteredGroupLivers) : [], [filteredGroupLivers])


    const [baseLineParameter, setBaseLineParameter] = useState<CreateBaseLineParameterFormValues>(new CreateBaseLineParameterFormValues());
    const [buttonFlag, setButtonFlag] = useState(false);

    useEffect(() => {
        setBaseLineParameter(new CreateBaseLineParameterFormValues(selectedBaseLineParameter));


    }, [selectedBaseLineParameter]);


    const cancelForm = () => {
        history.push(`/ideas/`);
    }
    
    const clearForm = () => {
        setBaseLineParameter(new CreateBaseLineParameterFormValues());
        clearSelectedBaseLineParameter();
        setBaseLineParameterLoaded(false);
    }
    
    const handleFormSubmit = (baseLineParameter: CreateBaseLineParameterFormValues) => {
        setButtonFlag(true)
        if (!baseLineParameterLoaded) {
            createBaseLineParameter(baseLineParameter);
        } else {
            setBaseLineParameterLoaded(false)
            editBaseLineParameter(baseLineParameter);
        }
        clearForm()
    };

    const requiredValidator = (value: any, getter: any, fieldProps: any) =>
        value
            ? ''
            : fieldProps.name == 'baseLineParameterId' ? "Error: This field BaseLine Parameter Id is required."
                : fieldProps.name == 'baseLineParameterDescription' ? "Error: This field BaseLine Parameter Description is required."
                    : fieldProps.name == 'diTypeId' ? "Error: This field COI Type is required."
                        : fieldProps.name == 'groupLiverId' ? "Error: This field Group Lever is required."
                            : "Error: This field is required.";

    if (loadingInitial) return <LoadingComponent content='Loading currency...'/>

    return (
        <>
            <Grid>
                <Grid.Column width={16}>
                    <Segment clearing>
                        <Form
                            initialValues={baseLineParameter}
                            ref={MyForm}
                            onSubmit={values => handleFormSubmit(values as CreateBaseLineParameterFormValues)}
                            key={baseLineParameter.baseLineParameterId}
                            render={(formRenderProps) => (

                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>
                                        <Grid>
                                            <Grid.Row columns={3}>
                                                {/*<Grid.Column>
                                                    <Field
                                                        id={'baseLineParameterId'}
                                                        name={'baseLineParameterId'}
                                                        label={'BaseLine Parameter Id*'}
                                                        component={FormInput}
                                                        autoComplete={"off"}
                                                        validator={requiredValidator}
                                                        disabled={baseLineParameterLoaded}
                                                        hint={'Hint: BaseLine Parameter Id.'}
                                                    />
                                                </Grid.Column>*/}
                                                <Grid.Column>
                                                    <Field
                                                        id={'baseLineParameterDescription'}
                                                        name={'baseLineParameterDescription'}
                                                        label={'BaseLine Parameter Description *'}
                                                        component={FormInput}
                                                        autoComplete={"off"}
                                                        validator={requiredValidator}
                                                        hint={'Hint: BaseLine Parameter Description.'}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={'diTypeId'}
                                                        name={'diTypeId'}
                                                        textField={"diTypeDescription"}
                                                        label={'COI Type *'}
                                                        data={diTypeData}
                                                        component={MemoizedFormDropDownList}
                                                        validator={requiredValidator}
                                                        disabled={baseLineParameterLoaded}
                                                        hint={'Hint: Select COI Type.'}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={'groupLiverId'}
                                                        name={'groupLiverId'}
                                                        textField={"groupLiverDescription"}
                                                        label={'Group Lever *'}
                                                        data={groupLivers}
                                                        component={MemoizedFormDropDownList}
                                                        validator={requiredValidator}
                                                        disabled={baseLineParameterLoaded}
                                                        hint={'Hint: Select group lever.'}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>


                                        <div className="k-form-buttons">
                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {baseLineParameterLoaded ? "Update BaseLine Parameter" : "Add New BaseLine Parameter"}
                                            </Button>
                                            <Button onClick={cancelForm}>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => {
                                                clearForm()
                                            }
                                            }>
                                                Clear
                                            </Button>
                                        </div>

                                        {buttonFlag && loadingInitial &&
                                        <LoadingComponent content='Processing BaseLine Parameter...'/>}
                                    </fieldset>

                                </FormElement>

                            )}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>

        </>


    );
}

export default observer(BaseLineParameterForm);


