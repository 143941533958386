export interface DiType {
    diTypeId: number;
    diTypeDescription: string;
    createdStamp: string
    createdBy: string
}


export class CreateDiTypeFormValues {

    diTypeId: number = 0;
    diTypeDescription: string = '';
    createdStamp: string = '';
    createdBy: string = ''

    constructor(diType?: CreateDiTypeFormValues) {
        if (diType) {
            this.diTypeDescription = diType.diTypeDescription
            this.diTypeId = diType.diTypeId
            this.createdStamp = diType.createdStamp
            this.createdBy = diType.createdBy
        }
    }
}
