import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from '../../../app/stores/store';
import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {Grid, GridColumn as Column, GridSortChangeEvent, GridToolbar,} from "@progress/kendo-react-grid";
import {Header} from "semantic-ui-react";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {toJS} from "mobx";

export default observer(function CosTop10List() {
    const {ideaStore} = useStore();
    let {
        loadCosTop10ReportData, top10ChartData,
        loadingInitial
    } = ideaStore;

    const _exporter: any = React.createRef<ExcelExport>();
    const excelExport = () => {
        save(_exporter);
    };

    const save = (component: any) => {
        const options = component.current.workbookOptions();
        const rows = options.sheets[0].rows;

        rows.forEach((row: any) => {
            if (row.type === 'data') {
                row.cells.forEach((cell: any) => {
                    if (Array.isArray(cell.value)) {
                        cell.value = cell.value.join(' ');
                    }
                });
            }
        });

        component.current.save(options);
    };

    //const _export = React.useRef(null);
    /*    const excelExport = () => {
            if (_export.current !== null) {
                // @ts-ignore
                _export.current!.save();
            }
        };*/

    let todaysDate: Date = new Date();

    const initialSort: Array<SortDescriptor> = [
        {field: "saving", dir: "desc"},
    ];
    const [sort, setSort] = React.useState(initialSort);


    useEffect(() => {
        loadCosTop10ReportData();

    }, [])

    console.log('top10ChartData', toJS(top10ChartData))

    if (loadingInitial) return <LoadingComponent content='Generating report...'/>
    return (

        <Fragment>
            <ExcelExport data={top10ChartData}
                         ref={_exporter}>

                <Grid
                    style={{height: "400px"}}
                    data={orderBy(top10ChartData, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e: GridSortChangeEvent) => {
                        setSort(e.sort);
                    }}
                >
                    <GridToolbar>
                        <button title="Export Excel" className="k-button k-primary" onClick={excelExport}>
                            Export to Excel
                        </button>
                    </GridToolbar>
                    <Column field="ideaId" title="ideaId" width="0"/>
                    <Column field="ideaNumber" title="COI #"/>
                    <Column field="ideaTitle" title="COI"/>
                    <Column field="saving" title="Potential Impact"/>
                    <Column field="actionOwner" title="Action Owner"/>
                    <Column field="location" title="Location"/>
                </Grid>

            </ExcelExport>
            <Header as='h4' color='orange'>
                Report values are in Thousands Saudi Riyals
            </Header>
            <Header as='h5' color='blue'>
                {todaysDate.toLocaleString()}
            </Header>
        </Fragment>
    );
})







