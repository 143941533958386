import React from "react";
import {Segment, Tab} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import CurrenciesList from "../dashboard/CurrenciesList";
import CurrenciesForm from "../form/CurrenciesForm";
import RejectionReasonsList from "./RejectionReasonsList";
import RejectionReasonForm from "../form/RejectionReasonForm";
import UsersList from "./UsersList";
import UsersForm from "../form/UsersForm";
import CoiTypesList from "./CoiTypesList";
import GroupLiversList from "./GroupLiversList";
import BaseLineParametersList from "./BaseLineParametersList";
import SavingCategoriesList from "./SavingCategoriesList";
import GroupLiversForm from "../form/GroupLiversForm";
import DiTypesForm from "../form/DiTypesForm";
import SavingCategoriesForm from "../form/SavingCategoriesForm";
import BaseLineParametersForm from "../form/BaseLineParametersForm";


export default observer(function AdminsDashboard() {


    const panes =
        [
            {
                menuItem: {key: 'currencies', content: 'Currencies'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <CurrenciesList/>
                    </Segment>
                    <Segment basic vertical>
                        <CurrenciesForm/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'users', content: 'Users'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <UsersList/>
                    </Segment>
                    <Segment basic vertical>
                        <UsersForm/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'rejectionReasons', content: 'Rejection Reasons'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <RejectionReasonsList/>
                    </Segment>
                    <Segment basic vertical>
                        <RejectionReasonForm/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'coiTypes', content: 'COI Types'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <CoiTypesList/>
                    </Segment>
                    <Segment basic vertical>
                        <DiTypesForm/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'groupLivers', content: 'Group Livers'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <GroupLiversList/>
                    </Segment>
                    <Segment basic vertical>
                        <GroupLiversForm/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'baseLine', content: 'BaseLine Parameters'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <BaseLineParametersList/>
                    </Segment>
                    <Segment basic vertical>
                        <BaseLineParametersForm/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'savingCategories', content: 'Saving Categories'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <SavingCategoriesList/>
                    </Segment>
                    <Segment basic vertical>
                        <SavingCategoriesForm/>
                    </Segment>
                </Tab.Pane>,
            },
            /*{
                menuItem: { key: 'orgStructure', content: 'Organization Structure' },
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <PartyGroupsList />
                    </Segment>
                    <Segment basic vertical>
                        <PartyGroupsForm />
                    </Segment>
                </Tab.Pane>,
            },*/
        ]


    return (
        <Segment basic vertical>
            <Tab panes={panes}/>
        </Segment>

    );
})

//<Header as='h3' image={reportsLogo} content='COS Reports' />

