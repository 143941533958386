export interface BaseLineParameter {
    baseLineParameterDescription: string;
    baseLineParameterId: number;
    groupLiverDescription: string;
    groupLiverId: number;
    diTypeId: number;
    createdStamp: string
    createdBy: string
}

export class CreateBaseLineParameterFormValues {
    baseLineParameterDescription: string = '';
    baseLineParameterId: number = 0;
    groupLiverId: number = 0;
    diTypeId: number = 0;
    createdStamp: string = '';
    createdBy: string = ''

    constructor(baseLineParameter?: CreateBaseLineParameterFormValues) {
        if (baseLineParameter) {
            this.baseLineParameterId = baseLineParameter.baseLineParameterId
            this.baseLineParameterDescription = baseLineParameter.baseLineParameterDescription
            this.groupLiverId = baseLineParameter.groupLiverId
            this.diTypeId = baseLineParameter.diTypeId
            this.createdStamp = baseLineParameter.createdStamp
            this.createdBy = baseLineParameter.createdBy
        }
    }
}
