import React, {useEffect, useMemo, useState} from 'react';
import {Button, Grid, Icon, Segment} from 'semantic-ui-react';
import {CreateIdeaFormValues} from '../../../app/models/idea';
import {observer} from 'mobx-react-lite';
import FormTextArea from '../../../app/common/form/FormTextArea';
import FormInput from '../../../app/common/form/FormInput';
import FormNumericTextBox from '../../../app/common/form/FormNumericTextBox';
import {useStore} from '../../../app/stores/store';
import {useHistory, useParams} from 'react-router-dom';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import {savingValidator} from './Validators';
import {toJS} from 'mobx'
import {MemoizedFormDropDownList} from "../../../app/common/form/MemoizedFormDropDownList";
import {FormDropDownTree2} from "../../../app/common/form/FormDropDownTree2";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import './styles-dialog.css';
import {FormMultiColumnComboBox} from "../../../app/common/form/FormMultiColumnComboBox";


const VetIdeaForm = () => {
    const history = useHistory();
    const {ideaStore, userStore, adminStore} = useStore();
    const {ideaId} = useParams<{ ideaId: string }>();

    const MyForm = React.useRef<any>()

    let {loadGroupLivers,
    loadAffiliates,
    loadBaseLineParameters,
    loadSavingCategories,
    loadDiTypes,
    loadDiRoles,
    loadRejectionReasons,
    loadUsers,
    loadCurrencies,
    loadPartyGroups
} = adminStore;

    useEffect(() => {
        loadGroupLivers();
        loadAffiliates();
        loadBaseLineParameters();
        loadSavingCategories();
        loadDiTypes();
        loadDiRoles();
        loadRejectionReasons();
        loadUsers();
        loadCurrencies()
        loadPartyGroups();

    }, []);
    
    let {user} = userStore;
    let {
        updateIdeaAsVetter, loadVetterIdea, loadingInitial, possibleVetters,
        clearCascadedListSelections, filteredGroupLivers, ideaAssignedToAnotherVetter,
         setSelectedDiType, setSelectedGroupLiver
        , setBaseLineParameter, vetterRejectIdea, vetterApproveIdea
        , filteredBaseLineParameters, filteredSavingCategories, setActiveIndex

    } = ideaStore;

    let {adminStore: {users, currencies, diTypes, partyGroups, rejectionReasons}} = useStore();

    const [idea, setIdea] = useState<CreateIdeaFormValues>(new CreateIdeaFormValues());
    const [changeVetterDialogVisible, setChangeVetterDialogVisible] = React.useState(false);
    const [rejectDialogVisible, setRejectDialogVisible] = React.useState(false);
    const [approveDialogVisible, setApproveDialogVisible] = React.useState(false);
    const [rejected, setRejected] = React.useState(false);
    const [approved, setApproved] = React.useState(false);
    const [vetterChanged, setVetterChanged] = React.useState(false);
    const [buttonFlag, setButtonFlag] = useState(false);


    const toggleDialogReject = () => {
        setRejectDialogVisible(!rejectDialogVisible);
    };

    const toggleDialogChangeVetter = () => {
        setChangeVetterDialogVisible(!changeVetterDialogVisible);
    };

    const toggleDialogApprove = () => {
        setApproveDialogVisible(!approveDialogVisible);
    };

   
    
    useEffect(() => {
        loadVetterIdea(ideaId).then(idea => setIdea(new CreateIdeaFormValues(idea)));
    }, [ideaId, loadVetterIdea]);
    console.log('idea', toJS(idea))


    let dropDownTreeData = useMemo(() => partyGroups ? toJS(partyGroups) : [], [partyGroups])
    let currencyData = useMemo(() => currencies ? toJS(currencies) : [], [currencies])
    diTypes = useMemo(() => diTypes ? toJS(diTypes) : [], [diTypes])
    filteredGroupLivers = useMemo(() => filteredGroupLivers ? toJS(filteredGroupLivers) : [], [filteredGroupLivers])
    filteredBaseLineParameters = useMemo(() => filteredBaseLineParameters ? filteredBaseLineParameters : [], [filteredBaseLineParameters])
    filteredSavingCategories = useMemo(() => filteredSavingCategories ? filteredSavingCategories : [], [filteredSavingCategories])
    rejectionReasons = useMemo(() => rejectionReasons ? toJS(rejectionReasons) : [], [rejectionReasons])
    users = useMemo(() => users ? toJS(users) : [], [users])
    possibleVetters = useMemo(() => possibleVetters ? toJS(possibleVetters) : [], [possibleVetters])

    possibleVetters = possibleVetters.filter((x: any) => x.displayName !== toJS(user)!.displayName)
    users = users.filter((x: any) => x.id !== user!.displayName)

    const selectField = 'selected';
    const expandField = 'expanded';
    const dataItemKey = 'partyGroupId';
    const textField = 'groupName';
    const subItemsField = 'items';

    const cancelForm = () => {
        setActiveIndex(1);
        clearCascadedListSelections();
        history.push(`/ideas/`);
    }
    const handleFormSubmit = (idea: CreateIdeaFormValues) => {

        setButtonFlag(true)
        setActiveIndex(1);
        clearCascadedListSelections();

        if (rejected) {
            vetterRejectIdea(idea).then(() => history.push(`/ideas/`));
        } else if (approved) {
            vetterApproveIdea(idea).then(() => history.push(`/ideas/`));
        } else if (vetterChanged) {
            ideaAssignedToAnotherVetter(idea).then(() => history.push(`/ideas/`));
        } else {
            idea.rejectionReasonId = null;
            idea.actionOwnerId = null;
            updateIdeaAsVetter(idea).then(() => history.push(`/ideas/`));
        }
    };


    const onDiTypeChange = React.useCallback(
        (event) => {
            const diType = event.value;
            setSelectedDiType(diType);

            MyForm.current.onChange('groupLiverId', {value: null});
            MyForm.current.onChange('baseLineParameterId', {value: null});
            MyForm.current.onChange('savingCategoryId', {value: null});
        },
        [setSelectedDiType]
    );

    const onGroupLiverChange = React.useCallback(
        (event) => {
            const groupLiver = event.value;
            setSelectedGroupLiver(groupLiver);
            MyForm.current.onChange('baseLineParameterId', {value: null});
            MyForm.current.onChange('savingCategoryId', {value: null});
        },
        [setSelectedGroupLiver]
    );

    const onBaseLineParameterChange = React.useCallback(
        (event) => {
            const baseLineParameter = event.value;
            setBaseLineParameter(baseLineParameter);
            MyForm.current.onChange('savingCategoryId', {value: null});

        },
        [setBaseLineParameter]
    );
    const requiredValidator = (value: any, getter: any, fieldProps: any) =>
        value
            ? ''
            : fieldProps.name == 'title' ? "Error: This field COI Title is required."
                : fieldProps.name == 'description' ? "Error: This field COI Description is required."
                    : fieldProps.name == 'partyGroupId' ? "Error: This field Saving Location is required."
                        : fieldProps.name == 'currentSituation' ? "Error: This field Current Situation is required."
                            : fieldProps.name == 'improvedSituation' ? "Error: This field Improved Situation is required."
                                : fieldProps.name == 'currencyId' ? "Error: This field Currency is required."
                                    : fieldProps.name == 'diTypeId' ? "Error: This field COI Type is required."
                                        : fieldProps.name == 'groupLiverId' ? "Error: This field Group Lever is required."
                                            : fieldProps.name == 'baseLineParameterId' ? "Error: This field Baseline Parameter is required."
                                                : fieldProps.name == 'savingCategoryId' ? "Error: This field Saving Category is required."
                                                    : fieldProps.name == 'rejectionReasonId' ? "Error: This field Rejection Reason is required."
                                                        : fieldProps.name == 'actionOwnerId' ? "Error: This field Action Owner is required."
                                                        : fieldProps.name == 'vetterRejectComment' ? "Error: This field Rejection Comment is required."
                                                            : "Error: This field is required.";

    if (loadingInitial) return <LoadingComponent content='Loading Initiative...'/>

    return (
        <Segment>
            <Form
                initialValues={idea}
                ref={MyForm}
                onSubmit={values => handleFormSubmit(values as CreateIdeaFormValues)}
                key={idea.ideaId}
                render={(formRenderProps) => (

                    <FormElement>
                        <fieldset className={'k-form-fieldset'}>

                            <Field
                                id={'title'}
                                name={'title'}
                                label={'COI Title *'}
                                component={FormInput}
                                autoComplete={"off"}
                                validator={requiredValidator}
                                hint={'Hint: Brief description about your Cost Optimization Initiative.'}
                            />

                            <Field
                                id={'description'}
                                name={'description'}
                                label={'COI Description *'}
                                autoComplete={"off"}
                                rows={3}
                                component={FormTextArea}
                                validator={requiredValidator}
                                hint={'Hint: Full description about your Cost Optimization Initiative.'}
                            />
                            <Field
                                id={'partyGroupId'}
                                name={'partyGroupId'}
                                data={dropDownTreeData}
                                label={"Saving in Department *"}
                                textField={textField}
                                dataItemKey={dataItemKey}
                                selectField={selectField}
                                expandField={expandField}
                                subItemsField={subItemsField}
                                component={FormDropDownTree2}
                                validator={requiredValidator}
                                hint={'Hint: Select location.'}
                            />
                            <Field
                                id={'currentSituation'}
                                name={'currentSituation'}
                                label={'Current Situation *'}
                                autoComplete={"off"}
                                validator={requiredValidator}
                                component={FormInput}
                                hint={'Hint: Describe the current situation before implementing your Cost Optimization Initiative'}
                            />

                            <Field
                                id={'improvedSituation'}
                                name={'improvedSituation'}
                                label={'Improved Situation *'}
                                autoComplete={"off"}
                                validator={requiredValidator}
                                component={FormInput}
                                hint={'Hint: Describe the future situation after implementing your Cost Optimization Initiative'}
                            />


                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Field
                                            id={'estimatedAnnualSaving'}
                                            format="n2"
                                            name={'estimatedAnnualSaving'}
                                            label={'Estimated Annual Saving *,   Enter Value in 000s'}
                                            component={FormNumericTextBox}
                                            validator={savingValidator}
                                            hint={'Hint: Estimated Annual Saving from your Cost Optimization Initiative.'}
                                        />

                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field
                                            id={"currencyId"}
                                            name={"currencyId"}
                                            label={"Currency *"}
                                            component={MemoizedFormDropDownList}
                                            dataItemKey={"currencyId"}
                                            textField={"currencyDescription"}
                                            data={currencyData}
                                            validator={requiredValidator}
                                            hint={'Hint: Select the currency used in your calculations.'}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>


                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Field
                                            id={'diTypeId'}
                                            name={'diTypeId'}
                                            textField={"diTypeDescription"}
                                            label={'COI Type *'}
                                            data={diTypes}
                                            onChange={onDiTypeChange}
                                            component={MemoizedFormDropDownList}
                                            validator={requiredValidator}
                                            hint={'Hint: Select your Cost Optimization Initiative Type.'}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field
                                            id={'groupLiverId'}
                                            name={'groupLiverId'}
                                            textField={"groupLiverDescription"}
                                            label={'Group Lever *'}
                                            data={filteredGroupLivers}
                                            onChange={onGroupLiverChange}
                                            component={MemoizedFormDropDownList}
                                            validator={requiredValidator}
                                            disabled={!formRenderProps.valueGetter('diTypeId')}
                                            hint={'Hint: Select group lever.'}
                                        />
                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>

                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Field
                                            id={'baseLineParameterId'}
                                            name={'baseLineParameterId'}
                                            textField={"baseLineParameterDescription"}
                                            label={'Baseline Parameter *'}
                                            data={filteredBaseLineParameters}
                                            onChange={onBaseLineParameterChange}
                                            component={MemoizedFormDropDownList}
                                            validator={requiredValidator}
                                            disabled={!formRenderProps.valueGetter('groupLiverId')}
                                            hint={'Hint: Select baseline parameter.'}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field
                                            id={'savingCategoryId'}
                                            name={'savingCategoryId'}
                                            textField={"savingCategoryDescription"}
                                            label={'Saving Category *'}
                                            data={filteredSavingCategories}
                                            component={MemoizedFormDropDownList}
                                            validator={requiredValidator}
                                            disabled={!formRenderProps.valueGetter('baseLineParameterId')}
                                            hint={'Hint: Select saving category.'}
                                        />
                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>
                            {changeVetterDialogVisible && <Dialog
                                width={500}
                                title={"Assign to another vetter"}
                                onClose={() => {
                                    setVetterChanged(false);
                                    toggleDialogChangeVetter()
                                }}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={10}>
                                            <Field
                                                id={'vetterId'}
                                                name={'vetterId'}
                                                data={possibleVetters}
                                                textField={"displayName"}
                                                label={'Vetters for this departments'}
                                                component={MemoizedFormDropDownList}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>

                                </Grid>
                                <DialogActionsBar>

                                    <Button
                                        className="k-button"
                                        onClick={(formRenderProps: any) => {
                                            setVetterChanged(true);
                                            formRenderProps.onSubmit = true;
                                        }}
                                    >Send to new Vetter</Button>
                                </DialogActionsBar>
                                {buttonFlag && <LoadingComponent content='Processing Initiative...'/>}
                            </Dialog>}
                            {rejectDialogVisible && <Dialog
                                width={500}
                                title={"Reject COI"}
                                onClose={() => {
                                    setRejected(false);
                                    toggleDialogReject()
                                }}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={10}>
                                            <Field
                                                id={'rejectionReasonId'}
                                                name={'rejectionReasonId'}
                                                data={rejectionReasons}
                                                textField={"rejectionReasonDescription"}
                                                label={'Rejection Reason'}
                                                component={MemoizedFormDropDownList}
                                                validator={requiredValidator}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={10}>
                                            <Field
                                                id={'vetterRejectComment'}
                                                name={'vetterRejectComment'}
                                                label={'Additional Comments'}
                                                rows={3}
                                                component={FormTextArea}
                                                validator={requiredValidator}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <DialogActionsBar>
                                    <Button
                                        type='button'
                                        className="k-button"
                                        onClick={() => {
                                            setRejected(false);
                                            toggleDialogReject()
                                        }}>Later</Button>
                                    <Button
                                        className="k-button"
                                        onClick={(formRenderProps: any) => {
                                            setRejected(true);
                                            formRenderProps.onSubmit = true;
                                        }}
                                    >Reject COI</Button>
                                </DialogActionsBar>
                                {buttonFlag && <LoadingComponent content='Processing Initiative...'/>}
                            </Dialog>}

                            {approveDialogVisible && <Dialog
                                width={500}
                                title={"Approve COI"}
                                onClose={() => {
                                    setApproved(false);
                                    toggleDialogApprove()
                                }}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={10}>
                                            <Field
                                                id={'actionOwnerId'}
                                                name={'actionOwnerId'}
                                                data={users}
                                                textField={"displayName"}
                                                label={'Action Owner'}
                                                component={FormMultiColumnComboBox}
                                                validator={requiredValidator}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>


                                    <Grid.Row>
                                        <Grid.Column width={10}>
                                            <Field
                                                id={'vetterApproveComment'}
                                                name={'vetterApproveComment'}
                                                label={'Additional Comments'}
                                                rows={3}
                                                component={FormTextArea}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <DialogActionsBar>
                                    <Button
                                        type='button'
                                        className="k-button"
                                        onClick={() => {
                                            setApproved(false);
                                            toggleDialogApprove()
                                        }}
                                        disabled={buttonFlag}
                                    >Later</Button>
                                    <Button
                                        className="k-button"
                                        onClick={(formRenderProps: any) => {
                                            setApproved(true);
                                            formRenderProps.onSubmit = true;
                                        }}
                                        disabled={buttonFlag}
                                    >Approve COI</Button>
                                </DialogActionsBar>
                                {buttonFlag && <LoadingComponent content='Processing Initiative...'/>}
                            </Dialog>}

                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <div className="k-form-buttons">
                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit || buttonFlag}
                                            >
                                                Save
                                            </Button>
                                            <Button onClick={cancelForm}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Grid.Column>


                                    <Grid.Column floated={"right"}>
                                        <div className="k-form-buttons">
                                            <Button type='button' icon labelPosition='left'
                                                    onClick={toggleDialogChangeVetter} color='green'>
                                                <Icon name='user'/>
                                                Assign to another Vetter
                                            </Button>

                                            <Button type='button' icon labelPosition='left' onClick={toggleDialogReject}
                                                    color='red'>
                                                <Icon name='thumbs down'/>
                                                Reject
                                            </Button>
                                            <Button type='button' icon labelPosition='right'
                                                    onClick={toggleDialogApprove} color='blue'>
                                                <Icon name='thumbs up'/>
                                                Approve
                                            </Button>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            {buttonFlag && <LoadingComponent content='Processing Initiative...'/>}
                        </fieldset>
                    </FormElement>

                )}
            />
        </Segment>
    );
}

export default observer(VetIdeaForm);
