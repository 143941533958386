import {makeAutoObservable, runInAction, toJS} from "mobx";
import agent from "../api/agent";
import {toast} from "react-toastify";
import {CreateCurrencyFormValues, Currency} from "../models/currency";
import {CreateRejectionReasonFormValues, RejectionReason} from "../models/rejectionReason";
import {AppUser, CreateAppUserFormValues} from "../models/appUser";
import {CreateDiTypeFormValues, DiType} from "../models/diType";
import {CreatePartyGroupFormValues, PartyGroup} from "../models/partyGroup";
import {CreateGroupLiverFormValues, GroupLiver} from "../models/groupLiver";
import {BaseLineParameter, CreateBaseLineParameterFormValues} from "../models/baseLineParameter";
import {CreateSavingCategoryFormValues, SavingCategory} from "../models/savingCategory";
import {CreatePartyGroupRoleFormValues, PartyGroupRole, PartyGroupRoleGrid} from "../models/partyGroupRole";
import {DiRole} from "../models/diRole";


export default class AdminStore {
    isStaticDataLoaded = false;
    Affiliates: any;
    users: any;
    loadingInitial = false;
    isPartyGroupRolesLoaded: boolean = false;


    //----------------------
    partyGroupRoles: PartyGroupRole[] = [];
    partyGroupRolesGrid: PartyGroupRoleGrid[] = [];
    currenciesRegistry = new Map<number, Currency>();
    rejectionReasonsRegistry = new Map<number, RejectionReason>();
    appUsersRegistry = new Map<string, AppUser>();
    diTypesRegistry = new Map<number, DiType>();
    diRolesRegistry = new Map<string, DiRole>();
    partyGroupsRegistry = new Map<string, PartyGroup>();
    groupLiversRegistry = new Map<number, GroupLiver>();
    baseLineParametersRegistry = new Map<number, BaseLineParameter>();
    savingCategoriesRegistry = new Map<number, SavingCategory>();
    appUserLoaded = false;
    currencyLoaded = false;
    rejectionReasonLoaded = false;
    diTypeLoaded = false;
    partyGroupLoaded = false;
    groupLiverLoaded = false;
    baseLineParameterLoaded = false;
    savingCategoryLoaded = false;
    selectedAppUser: AppUser | undefined = undefined
    selectedCurrency: Currency | undefined = undefined
    selectedRejectionReason: RejectionReason | undefined = undefined
    selectedDiType: DiType | undefined = undefined
    selectedPartyGroup: PartyGroup | undefined = undefined
    selectedGroupLiver: GroupLiver | undefined = undefined
    selectedBaseLineParameter: BaseLineParameter | undefined = undefined
    selectedSavingCategory: SavingCategory | undefined = undefined

    constructor() {
        makeAutoObservable(this)
        console.log('User Admin constructor')

    }

    //---------------------- 
    get currencies() {
        return Array.from(this.currenciesRegistry.values());
    }

    get appUsers() {
        return Array.from(this.appUsersRegistry.values());
    }

    get rejectionReasons() {
        return Array.from(this.rejectionReasonsRegistry.values());
    }

    get diTypes() {
        return Array.from(this.diTypesRegistry.values());
    }
    
    get diRoles() {
        return Array.from(this.diRolesRegistry.values());
    }

    get partyGroups() {
        return Array.from(this.partyGroupsRegistry.values());
    }

    get groupLivers() {
        return Array.from(this.groupLiversRegistry.values());
    }

    get baseLineParameters() {
        return Array.from(this.baseLineParametersRegistry.values());
    }

    get savingCategories() {
        return Array.from(this.savingCategoriesRegistry.values());
    }

    setIsStaticDataLoaded = (state: boolean) => {
        this.isStaticDataLoaded = state;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }
    loadAffiliates = async () => {
        try {
            const Affiliates = await agent.PartyGroups.ListAffiliates();
            runInAction(() => {
                this.Affiliates = Affiliates;
            })
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    loadUsers = async () => {
        try {
            const users = await agent.Users.listUsers();
            runInAction(() => {
                this.users = users;
            })
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    //-------------------------------------
    getAppUser = (appUserId: string) => {
        return this.appUsersRegistry.get(appUserId);
         
    };

    getCurrency = (currencyId: number) => {
        return this.currenciesRegistry.get(currencyId);
    };

    getRejectionReason = (rejectionReasonId: number) => {
        return this.rejectionReasonsRegistry.get(rejectionReasonId);
    };

    getDiType = (diTypeId: number) => {
        return this.diTypesRegistry.get(diTypeId);
    };

    getPartyGroup = (partyGroupId: string) => {
        return this.partyGroupsRegistry.get(partyGroupId);
    };

    getGroupLiver = (groupLiverId: number) => {
        return this.groupLiversRegistry.get(groupLiverId);
    };

    getBaseLineParameter = (baseLineParameterId: number) => {
        return this.baseLineParametersRegistry.get(baseLineParameterId);
    };

    getSavingCategory = (savingCategoryId: number) => {
        return this.savingCategoriesRegistry.get(savingCategoryId);
    };

//----------------
    loadAppUser = async (appUserId: string) => {
        let appUser = this.getAppUser(appUserId);
        if (appUser) {
            const partyGroupRoles = appUser.partyGroupRoleKey
            this.loadPartyGroupRoles(partyGroupRoles)
            this.selectedAppUser = appUser;
        }
    };

    loadCurrency = async (currencyId: number) => {
        let currency = this.getCurrency(currencyId);
        if (currency) {
            this.selectedCurrency = currency;
        }
    };

    loadRejectionReason = async (rejectionReasonId: number) => {
        let rejectionReason = this.getRejectionReason(rejectionReasonId);
        if (rejectionReason) {
            this.selectedRejectionReason = rejectionReason;
        }
    };

    loadDiType = async (diTypeId: number) => {
        let diType = this.getDiType(diTypeId);
        if (diType) {
            this.selectedDiType = diType;
        }
    };

    loadPartyGroup = async (partyGroupId: string) => {
        let partyGroup = this.getPartyGroup(partyGroupId);
        if (partyGroup) {
            this.selectedPartyGroup = partyGroup;
        }
    };

    loadGroupLiver = async (groupLiverId: number) => {
        let groupLiver = this.getGroupLiver(groupLiverId);
        if (groupLiver) {
            this.selectedGroupLiver = groupLiver;
        }
    };

    loadBaseLineParameter = async (baseLineParameterId: number) => {
        let baseLineParameter = this.getBaseLineParameter(baseLineParameterId);
        if (baseLineParameter) {
            this.selectedBaseLineParameter = baseLineParameter;
        }
    };

    loadSavingCategory = async (savingCategoryId: number) => {
        let savingCategory = this.getSavingCategory(savingCategoryId);
        if (savingCategory) {
            this.selectedSavingCategory = savingCategory;
        }
    };

//----------------
    clearSelectedAppUser = () => {
        this.partyGroupRolesGrid = []
        this.selectedAppUser = undefined;
    }

    clearSelectedCurrency = () => {
        this.selectedCurrency = undefined;
    }

    clearSelectedRejectionReason = () => {
        this.selectedRejectionReason = undefined;
    }

    clearSelectedDiType = () => {
        this.selectedDiType = undefined;
    }

    clearSelectedPartyGroup = () => {
        this.selectedPartyGroup = undefined;
    }

    clearSelectedGroupLiver = () => {
        this.selectedGroupLiver = undefined;
    }

    clearSelectedBaseLineParameter = () => {
        this.selectedBaseLineParameter = undefined;
    }

    clearSelectedSavingCategory = () => {
        this.selectedSavingCategory = undefined;
    }

    //-----------------------------
    
    loadPartyGroupRoles = (PartyGroupRoleKey: PartyGroupRole[] | undefined) => {
        this.partyGroupRolesGrid = []

        PartyGroupRoleKey!.forEach((item) => {
            this.partyGroupRolesGrid.push(new PartyGroupRoleGrid(item.appUserId, item.diRoleId, item.partyGroupId,new Date(item.dateFrom), 
                item.dateTo === null ? null : new Date(item.dateTo), item.diRoleDescription, item.groupName, item.createdStamp, item.createdBy));

        })

    }

    updateNewPartyGroupRole = (role: CreatePartyGroupRoleFormValues) => {
        const newRole = new PartyGroupRoleGrid(role.appUserId!,
            role.diRoleId, role.partyGroupId,
            (role.dateFrom === null) ? null : role.dateFrom!, role.dateTo, role.diRoleDescription, role.groupName)
        this.partyGroupRolesGrid.push(newRole)
    }

    updateExistingPartyGroupRole = (role: CreatePartyGroupRoleFormValues) => {
        let existingRole = this.partyGroupRolesGrid.filter((item: PartyGroupRoleGrid) => item.diRoleId == role.diRoleId)

        existingRole[0].appUserId = role.appUserId!;
        existingRole[0].diRoleId = role.diRoleId!;
        existingRole[0].partyGroupId = role.partyGroupId!;
        existingRole[0].dateFrom = role.dateFrom;
        existingRole[0].dateTo = role.dateTo!;
        existingRole[0].createdStamp = role.createdStamp!;
        existingRole[0].createdBy = role.createdBy!;

    }
    
    loadAppUsers = async () => {
        this.setLoadingInitial(true);
        try {
            const results = await agent.Users.listAllUsers();
            results.forEach(appUser => {
                runInAction(() => {
                    this.appUsersRegistry.set(appUser.id, appUser);
                })
            })
            this.setLoadingInitial(false);
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');

        }
    }

    loadCurrencies = async () => {
        this.setLoadingInitial(true);
        try {
            const results = await agent.Currencies.list();
            results.forEach(currency => {
                runInAction(() => {
                    this.currenciesRegistry.set(currency.currencyId, currency);
                })
            })
            this.setLoadingInitial(false);
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');

        }
    }

    loadRejectionReasons = async () => {
        this.setLoadingInitial(true);
        try {
            const results = await agent.RejectionReasons.list();
            results.forEach(rejectionReason => {
                runInAction(() => {
                    this.rejectionReasonsRegistry.set(rejectionReason.rejectionReasonId, rejectionReason);
                })
            })
            this.setLoadingInitial(false);
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');

        }
    }

    loadDiTypes = async () => {
        this.setLoadingInitial(true);
        try {
            const results = await agent.DiTypes.list();
            results.forEach(diType => {
                runInAction(() => {
                    this.diTypesRegistry.set(diType.diTypeId, diType);
                })
            })
            this.setLoadingInitial(false);
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');

        }
    } 
    
    loadDiRoles = async () => {
        this.setLoadingInitial(true);
        try {
            const results = await agent.DiRoles.list();
            results.forEach(diRole => {
                runInAction(() => {
                    this.diRolesRegistry.set(diRole.diRoleId, diRole);
                })
            })
            this.setLoadingInitial(false);
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');

        }
    }

    loadPartyGroups = async () => {
        this.setLoadingInitial(true);
        try {
            const results = await agent.PartyGroups.list();
            results.forEach(partyGroup => {
                runInAction(() => {
                    this.partyGroupsRegistry.set(partyGroup.partyGroupId, partyGroup);
                })
            })
            this.setLoadingInitial(false);
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');

        }
    }

    loadGroupLivers = async () => {
        this.setLoadingInitial(true);
        try {
            const results = await agent.GroupLivers.list();
            results.forEach(groupLiver => {
                runInAction(() => {
                    this.groupLiversRegistry.set(groupLiver.groupLiverId, groupLiver);
                })
            })
            this.setLoadingInitial(false);
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');

        }
    }

    loadBaseLineParameters = async () => {
        this.setLoadingInitial(true);
        try {
            const results = await agent.BaseLineParameters.list();
            results.forEach(baseLineParameter => {
                runInAction(() => {
                    this.baseLineParametersRegistry.set(baseLineParameter.baseLineParameterId, baseLineParameter);
                })
            })
            this.setLoadingInitial(false);
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');

        }
    }

    loadSavingCategories = async () => {
        this.setLoadingInitial(true);
        try {
            const results = await agent.SavingCategories.list();
            results.forEach(savingCategory => {
                runInAction(() => {
                    this.savingCategoriesRegistry.set(savingCategory.savingCategoryId, savingCategory);
                })
            })
            this.setLoadingInitial(false);
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');

        }
    }


    //----------

    createCurrency = async (currency: CreateCurrencyFormValues) => {
        try {
            await agent.Currencies.createCurrency(currency);
            runInAction(() => {
                this.loadCurrencies();
            })
            toast.success('New Currency Created Successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    };

    createAppUser = async (appUser: CreateAppUserFormValues) => {
        try {
            this.partyGroupRoles = []
            this.partyGroupRolesGrid.forEach( item => {
                const role = new PartyGroupRole()
                role.appUserId = item.appUserId
                role.diRoleId = item.diRoleId
                role.partyGroupId = item.partyGroupId
                role.dateFrom = item.dateFrom
                role.dateTo = item.dateTo!
                role.createdStamp = item.createdStamp
                role.createdBy = item.createdBy

                this.partyGroupRoles.push(role)
            })
            appUser.partyGroupRoleKey = this.partyGroupRoles
            await agent.Users.createAppUser(appUser);
            runInAction(() => {
                this.loadAppUsers();
            })
            toast.success('New User Created Successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    };

    createRejectionReason = async (rejectionReason: CreateRejectionReasonFormValues) => {
        try {
            await agent.RejectionReasons.createRejectionReason(rejectionReason);
            runInAction(() => {
                this.loadRejectionReasons();
            })
            toast.success('Rejection Reason Created Successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    };

    createDiType = async (diType: CreateDiTypeFormValues) => {
        try {
            await agent.DiTypes.createDiType(diType);
            runInAction(() => {
                this.loadDiTypes();
            })
            toast.success('COI Type Created Successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    };

    createPartyGroup = async (partyGroup: CreatePartyGroupFormValues) => {
        try {
            await agent.PartyGroups.createPartyGroup(partyGroup);
            runInAction(() => {
                this.loadPartyGroups();
            })
            toast.success('Organization Unit Created Successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    };

    createGroupLiver = async (groupLiver: CreateGroupLiverFormValues) => {
        try {
            await agent.GroupLivers.createGroupLiver(groupLiver);
            runInAction(() => {
                this.loadGroupLivers();
            })
            toast.success('Group Liver Created Successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    };

    createBaseLineParameter = async (baseLineParameter: CreateBaseLineParameterFormValues) => {
        try {
            await agent.BaseLineParameters.createBaseLineParameter(baseLineParameter);
            runInAction(() => {
                this.loadBaseLineParameters();
            })
            toast.success('Baseline Parameter Created Successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    };

    createSavingCategory = async (savingCategory: CreateSavingCategoryFormValues) => {
        try {
            await agent.SavingCategories.createSavingCategory(savingCategory);
            runInAction(() => {
                this.loadSavingCategories();
            })
            toast.success('Saving Category Created Successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    };


    //---------------

    editAppUser = async (appUser: CreateAppUserFormValues) => {
        this.setLoadingInitial(true);
        try {
            this.partyGroupRoles = []
            this.partyGroupRolesGrid.forEach( item => {
                const role = new PartyGroupRole()
                role.appUserId = item.appUserId
                role.diRoleId = item.diRoleId
                role.partyGroupId = item.partyGroupId
                role.dateFrom = item.dateFrom
                role.dateTo = item.dateTo!
                role.createdStamp = item.createdStamp
                role.createdBy = item.createdBy
                
                this.partyGroupRoles.push(role)
            })
            appUser.partyGroupRoleKey = this.partyGroupRoles
            await agent.Users.editAppUser(appUser);
            runInAction(() => {
                this.clearSelectedAppUser();
                this.loadAppUsers();
            })
            this.setLoadingInitial(false);
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    }

    editCurrency = async (currency: CreateCurrencyFormValues) => {
        this.setLoadingInitial(true);
        try {
            await agent.Currencies.editCurrency(currency);
            runInAction(() => {
                this.clearSelectedCurrency();
                this.loadCurrencies();
            })
            this.setLoadingInitial(false);
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    }

    editRejectionReason = async (rejectionReason: CreateRejectionReasonFormValues) => {
        this.setLoadingInitial(true);
        try {
            await agent.RejectionReasons.editRejectionReason(rejectionReason);
            runInAction(() => {
                this.clearSelectedRejectionReason();
                this.loadRejectionReasons();
            })
            this.setLoadingInitial(false);
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    }

    editDiType = async (diType: CreateDiTypeFormValues) => {
        this.setLoadingInitial(true);
        try {
            await agent.DiTypes.editDiType(diType);
            runInAction(() => {
                this.clearSelectedDiType();
                this.loadDiTypes();
            })
            this.setLoadingInitial(false);
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    }

    editPartyGroup = async (partyGroup: CreatePartyGroupFormValues) => {
        this.setLoadingInitial(true);
        try {
            await agent.PartyGroups.editPartyGroup(partyGroup);
            runInAction(() => {
                this.clearSelectedPartyGroup();
                this.loadPartyGroups();
            })
            this.setLoadingInitial(false);
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    }

    editGroupLiver = async (groupLiver: CreateGroupLiverFormValues) => {
        this.setLoadingInitial(true);
        try {
            await agent.GroupLivers.editGroupLiver(groupLiver);
            runInAction(() => {
                this.clearSelectedGroupLiver();
                this.loadGroupLivers();
            })
            this.setLoadingInitial(false);
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    }

    editBaseLineParameter = async (baseLineParameter: CreateBaseLineParameterFormValues) => {
        this.setLoadingInitial(true);
        try {
            await agent.BaseLineParameters.editBaseLineParameter(baseLineParameter);
            runInAction(() => {
                this.clearSelectedBaseLineParameter();
                this.loadBaseLineParameters();
            })
            this.setLoadingInitial(false);
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    }

    editSavingCategory = async (savingCategory: CreateSavingCategoryFormValues) => {
        this.setLoadingInitial(true);
        try {
            await agent.SavingCategories.editSavingCategory(savingCategory);
            runInAction(() => {
                this.clearSelectedSavingCategory();
                this.loadSavingCategories();
            })
            this.setLoadingInitial(false);
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    }

    //--------

    setAppUserLoaded = (state: boolean) => {
        this.appUserLoaded = state;
    }

    setCurrencyLoaded = (state: boolean) => {
        this.currencyLoaded = state;
    }

    setRejectionReasonLoaded = (state: boolean) => {
        this.rejectionReasonLoaded = state;
    }

    setDiTypeLoaded = (state: boolean) => {
        this.diTypeLoaded = state;
    }

    setPartyGroupLoaded = (state: boolean) => {
        this.partyGroupLoaded = state;
    }

    setGroupLiverLoaded = (state: boolean) => {
        this.groupLiverLoaded = state;
    }

    setBaseLineParameterLoaded = (state: boolean) => {
        this.baseLineParameterLoaded = state;
    }

    setSavingCategoryLoaded = (state: boolean) => {
        this.savingCategoryLoaded = state;
    }

}