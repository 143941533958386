const content = `<p>By submitting your <strong>Cost Optimization initiative</strong> or approving another Cost Optimization Idea, you consent to the transfer and storage of your Idea to COMPANY and all its controlled entities in the Saudia Arabia and elsewhere, and their authorized third-party contractors or agents, and to the processing of such Ideas for the purposes of Cost Optimization system processes within COMPANY including but not limited to:<br />
1 - Tracking ideas related to cost savings.<br />
2 - Revenue increases and cash events.<br />
3 - Allowing others to view corporate-wide Cost Optimization initiatives, and creating reports to track progress toward the targets (the “Purpose”).<br />
 
COMPANY shall use the information you provide solely for this Purpose and shall store the information for as long as is strictly necessary to be able to complete the Purpose. You confirm that you are fully informed of the reasons for processing and transfer of your <strong>Cost Optimization initiative</strong> and you are aware of all your rights regarding such information and all applicable laws. <br />

You understand that you can delete or update your Cost Optimization initiative if you are the action owner and the initiative is in the draft stage (before approval by an Action Controller). If you submit an idea for review, you understand you will receive confirmation of your submission only. If your idea results in an action plan, you understand you must notify in advance to any task owner, contributor or other individuals with roles in Cost Optimization process that their name will appear in the Cost Optimization system associated with this action and the information will be used as described above. <br />
If you assign a different action owner to this initiative, you understand you must notify the action owner in advance that their name will appear in the Cost Optimization system associated with this idea and the information will be used as described above.<br />

Thanks for understanding and keep your good ideas coming.
</p>`;


export default content;