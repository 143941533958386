import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Grid as KendoGrid,
    GRID_COL_INDEX_ATTRIBUTE,
    GridCellProps,
    GridColumn as Column,
    GridFilterChangeEvent,
    GridPageChangeEvent,
    GridSortChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-grid";
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor, State,} from "@progress/kendo-data-query";

import {useStore} from '../../../app/stores/store';
import {Link} from "react-router-dom";
import {Button, Checkbox, Grid} from "semantic-ui-react";
import {DropdownFilterCell} from "../../../app/common/form/DropDownFilterCell";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {useTableKeyboardNavigation} from "@progress/kendo-react-data-tools";

export default observer(function OriginatorIdeaList() {
    const {ideaStore} = useStore();
    let {
        loadOriginatorIdeas, originatorIdeaRegistry,
        ideas, loadingInitial
    } = ideaStore;

    const _export = React.useRef(null);
    const excelExport = () => {
        if (_export.current !== null) {
            // @ts-ignore
            _export.current!.save();
        }
    };
    const handleSelectionChange = (event: any) => {
        let newData = data.map(item => {
            if (event.dataItem.title === item.title) {
                item.selected = !item.selected
            }
            return item;
        })
        setData(newData);
    }
    const initialDataState: State = {skip: 0, take: 4};

    const [filter, setFilter] = React.useState<CompositeFilterDescriptor>();
    const [page, setPage] = React.useState<any>(initialDataState);
    const [checked, setChecked] = React.useState<boolean>(false)
    const toggle = () => {
        setChecked(!checked)
    }

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };
    const initialSort: Array<SortDescriptor> = [
        {field: "createdStamp", dir: "desc"},
    ];
    const [sort, setSort] = React.useState(initialSort);

    const filterChange = (event: GridFilterChangeEvent) => {

        setFilter(event.filter);
        setData(filterBy(ideas, event.filter));

    };
    const [data, setData] = React.useState(ideas);
    const diTypes = Array.from(
        new Set(ideas.map((p) => p.diTypeDescription))
    );
    const DiTypesFilterCell: any = (props: GridCellProps) => (
        <DropdownFilterCell
            {...props}
            data={diTypes}
            defaultValue={"Select Idea Type"}
        />
    );

    let ideaStatus = Array.from(
        new Set(ideas.map((p) => p.ideaStatusDescription))
    );
    ideaStatus = ideaStatus.filter(status => status != null)

    const IdeaStatusFilterCell: any = (props: GridCellProps) => (
        <DropdownFilterCell
            {...props}
            data={ideaStatus}
            defaultValue={"Select Status"}
        />
    );

    useEffect(() => {
        if (originatorIdeaRegistry.size < 1) loadOriginatorIdeas();
        setData(ideas)
    }, [ideas])


    const IdeaTitleCell = (props: any) => {
        const field = props.field || '';
        const value = props.dataItem[field];
        const navigationAttributes = useTableKeyboardNavigation(props.id);
        return (
            <td
                className={props.className}
                style={{...props.style, color: 'blue'}}
                colSpan={props.colSpan}
                role={'gridcell'}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                {...{
                    [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex
                }}
                {...navigationAttributes}
            > {props.dataItem.processStatusDescription === 'Submitted By Originator' ||
            props.dataItem.processStatusDescription === 'Modified By Originator'
                ? (<Button basic as={Link}
                           to={`/manage/${props.dataItem.ideaId}`}
                           color='blue'
                           size='mini'>
                    {props.dataItem.title}
                </Button>)
                : (props.dataItem.title)}

            </td>
        )
    }

    if (loadingInitial) return <LoadingComponent content='Loading Initiatives...'/>
    let dataToExport = checked ? data : filterBy(data, {field: 'selected', operator: 'eq', value: true})
    return (

        <Fragment>
            <ExcelExport data={dataToExport}
                         ref={_export}>
                <KendoGrid className="main-grid" style={{height: "400px"}}
                           data={orderBy(data, sort).slice(page.skip, page.take + page.skip)}
                           sortable={true}
                           sort={sort}
                           onSortChange={(e: GridSortChangeEvent) => {
                               setSort(e.sort);
                           }}
                           skip={page.skip}
                           take={page.take}
                           total={data.length}
                           pageable={true}
                           onPageChange={pageChange}
                           filterable={true}
                           filter={filter}
                           onFilterChange={filterChange}
                           selectedField='selected'
                           onSelectionChange={handleSelectionChange}
                           resizable={true}
                           reorderable={true}
                >
                    <GridToolbar>
                        <Grid>
                            <Grid.Row columns={2} verticalAlign="middle">
                                <Grid.Column>
                                    <button title="Export Excel" className="k-button k-primary" onClick={excelExport}>
                                        Export to Excel
                                    </button>
                                </Grid.Column>
                                <Grid.Column>
                                    <Checkbox
                                        label='Export All'
                                        onChange={toggle}
                                        checked={checked}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>

                    </GridToolbar>
                    <Column field="selected" width="50px" headerCell={() => null} locked/>
                    <Column field="title" title="COI" cell={IdeaTitleCell} width={150} locked={true}/>
                    <Column field="ideaStatusDescription" title="COI Status" width={150}
                            filterCell={IdeaStatusFilterCell}/>
                    <Column field="diTypeDescription" title="COI Type" width={150} filterCell={DiTypesFilterCell}/>
                    <Column field="groupName" title="Location" width={120}/>
                    <Column field="groupLiverDescription" title="Group Lever" width={120}/>
                    <Column field="baseLineParameterDescription" title="Baseline" width={120}/>
                    <Column field="savingCategoryDescription" title="Saving Category" width={150}/>
                    <Column field="processStatusDescription" title="Process Stage" width={150}/>
                    <Column field="createdStamp" title="Created Date" format="{0: dd/MM/yyyy}" width={120}/>
                    <Column field="ideaNumber" title="COI#" width={100}/>
                    <Column field="ideaId" title="COI ID" width={0}/>

                </KendoGrid>
            </ExcelExport>
        </Fragment>
    )
})
