import React from "react";
import {useStore} from '../../../app/stores/store';
import {observer} from "mobx-react-lite";
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import {Button} from '@progress/kendo-react-buttons';
import {Grid, Header} from "semantic-ui-react";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import CreateIdeaMenu from "./CreateIdeaMenu";
import FormDatePicker from "../../../app/common/form/FormDatePicker";

export default observer(function OriginatorIdeaFilters() {

    const {ideaStore: {setPredicate, loadOriginatorIdeas}} = useStore();
    let eDate: Date = new Date();
    let tempDate: Date = new Date();
    let sDate = new Date(tempDate.setMonth(tempDate.getMonth() - 1))
    const handleSubmit = (dataItem: any) => {

        if (Object.keys(dataItem.values).length > 0) {
            if (dataItem.values.startDate !== undefined) {
                setPredicate("startDate", dataItem.values.startDate.toDateString());
            }
            if (dataItem.values.endDate !== undefined) {
                setPredicate("endDate", dataItem.values.endDate.toDateString());
            }

            loadOriginatorIdeas();
        } else {
            loadOriginatorIdeas();
        }


    };

    return (
        <>

            <Form
                onSubmitClick={handleSubmit}
                render={(formRenderProps) => (
                    <Grid>
                        <Grid.Column floated='left' width={10}>
                            <FormElement>
                                <Grid columns={10}>
                                    <Header floated='left' icon='search' content='Find Initiatives'/>
                                    <Grid.Column width={4}>
                                        <Field
                                            id={'startDate'}
                                            name={'startDate'}
                                            label={'Start Date'}
                                            component={FormDatePicker}
                                            defaultValue={sDate}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Field
                                            id={'endDate'}
                                            name={'endDate'}
                                            label={'End Date'}
                                            component={FormDatePicker}
                                            defaultValue={eDate}
                                        />
                                    </Grid.Column>


                                </Grid>

                                <div className="k-form-buttons">
                                    <Button
                                        primary={true}
                                        type={'submit'}

                                    >
                                        Search
                                    </Button>
                                    <Button onClick={formRenderProps.onFormReset}>
                                        Clear
                                    </Button>
                                </div>
                            </FormElement>
                        </Grid.Column>

                        <Grid.Column floated='right' width={6}>
                            <CreateIdeaMenu/>
                        </Grid.Column>
                    </Grid>

                )}
            />
        </>

    )
})