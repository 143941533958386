import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from '../../../app/stores/store';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
    Chart,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    exportVisual,
} from "@progress/kendo-react-charts";

import "hammerjs";
import {exportSVG} from "@progress/kendo-drawing";
import {saveAs} from "@progress/kendo-file-saver";
import {Button} from "semantic-ui-react";

export default observer(function CosUtilization() {
    const {ideaStore} = useStore();
    const [series, setSeries] = React.useState<any>([
        {category: "Engaged", value: 0},
        {category: "Outstanding", value: 0},

    ])

    let {
        loadCosUtilizationReportData, cosUtilizationChartData,
        loadingInitial
    } = ideaStore;

    const refContainer: any = React.useRef();

    const onExportVisual = () => {
        const chartVisual = exportVisual(refContainer.current);
        if (chartVisual) {
            exportSVG(chartVisual).then(dataURI => saveAs(dataURI, 'CosUtilization.svg'));
        }
    };

    let todaysDate: Date = new Date();

    const labelContent = (props: any) => {
        let formatedNumber = Number(props.dataItem.value).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.dataItem.category}  ${formatedNumber}`;
    };

    console.log(cosUtilizationChartData, cosUtilizationChartData)
    useEffect(() => {
        loadCosUtilizationReportData();

    }, [])


    if (loadingInitial) return <LoadingComponent content='Generating report...'/>
    return (
        <Fragment>
            <Chart ref={refContainer}>
                <ChartTitle
                    text={todaysDate.toLocaleString()}
                    color="blue"
                    font="10pt sans-serif"
                />
                <ChartLegend position="bottom"/>
                <ChartSeries>
                    <ChartSeriesItem
                        type="pie"
                        data={cosUtilizationChartData}
                        field="value"
                        categoryField="category"
                        labels={{visible: true, content: labelContent}}
                    />
                </ChartSeries>
            </Chart>
            <Button onClick={() => onExportVisual()}>Export as visual element</Button>
        </Fragment>
    );
})







