import {observer} from 'mobx-react-lite';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {Container, Dropdown, Image, Menu} from 'semantic-ui-react';
import {useStore} from '../stores/store';

export default observer(function NavBar() {
    const {userStore: {user, logout, isLoggedIn}} = useStore();
    return (
        <Menu inverted fixed='top'>
            <Container>
                <Menu.Item as={NavLink} exact to='/' header>
                    <img src='/assets/bklogo.png' alt='logo' style={{marginRight: '10px'}}/>
                    Cost Optimization
                </Menu.Item>
                {isLoggedIn &&
                <>
                    <Menu.Item as={NavLink} to='/ideas' name='Initiatives'/>

                    <Menu.Item position='right'>
                        <Menu.Item as={NavLink} to='/admin' name='Settings' icon='settings'/>
                        <Image src={user?.image || '/assets/user.png'} avatar spaced='right'/>
                        <Dropdown pointing='top left' text={user?.displayName}>
                            <Dropdown.Menu>

                                <Dropdown.Item onClick={logout} text='Logout' icon='power'/>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                </>}
            </Container>
        </Menu>
    )
})