import React, {useEffect, useState} from 'react';
import {Button, Grid, Segment} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import FormInput from '../../../app/common/form/FormInput';
import {useStore} from '../../../app/stores/store';
import {useHistory} from 'react-router-dom';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {CreateRejectionReasonFormValues} from "../../../app/models/rejectionReason";


const RejectionReasonForm = () => {
    const history = useHistory();
    const {ideaStore, adminStore} = useStore();


    const MyForm = React.useRef<any>()

    let {loadingInitial} = ideaStore;

    let {
        createRejectionReason,
        rejectionReasonLoaded,
        setRejectionReasonLoaded,
        selectedRejectionReason,
        clearSelectedRejectionReason,
        editRejectionReason
    } = adminStore;


    const [rejectionReason, setRejectionReason] = useState<CreateRejectionReasonFormValues>(new CreateRejectionReasonFormValues());
    const [buttonFlag, setButtonFlag] = useState(false);

    useEffect(() => {
        setRejectionReason(new CreateRejectionReasonFormValues(selectedRejectionReason));


    }, [selectedRejectionReason]);

    const clearForm = () => {
        setRejectionReason(new CreateRejectionReasonFormValues());
        clearSelectedRejectionReason();
        setRejectionReasonLoaded(false);
    }
    const cancelForm = () => {
        history.push(`/ideas/`);
    }
    const handleFormSubmit = (rejectionReason: CreateRejectionReasonFormValues) => {
        setButtonFlag(true)
        if (!rejectionReasonLoaded) {
            createRejectionReason(rejectionReason);
        } else {
            setRejectionReasonLoaded(false)
            editRejectionReason(rejectionReason);
        }
        clearForm()
    };


    const requiredValidator = (value: any, getter: any, fieldProps: any) =>
        value
            ? ''
            : fieldProps.name == 'rejectionReasonId' ? "Error: This field Rejection Reason Id is required."
                : fieldProps.name == 'rejectionReasonDescription' ? "Error: This field Rejection Reason Description is required."
                    : "Error: This field is required.";

    if (loadingInitial) return <LoadingComponent content='Loading currency...'/>

    return (
        <>
            <Grid>
                <Grid.Column width={12}>
                    <Segment clearing>
                        <Form
                            initialValues={rejectionReason}
                            ref={MyForm}
                            onSubmit={values => handleFormSubmit(values as CreateRejectionReasonFormValues)}
                            key={rejectionReason.rejectionReasonId}
                            render={(formRenderProps) => (

                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>
                                        {/*<Field
                                            id={'rejectionReasonId'}
                                            name={'rejectionReasonId'}
                                            label={'Rejection Reason Id *'}
                                            component={FormInput}
                                            autoComplete={"off"}
                                            validator={requiredValidator}
                                            disabled={rejectionReasonLoaded}
                                            hint={'Hint: Rejection Reason ID.'}
                                        />*/}

                                        <Field
                                            id={'rejectionReasonDescription'}
                                            name={'rejectionReasonDescription'}
                                            label={'Rejection Reason *'}
                                            component={FormInput}
                                            autoComplete={"off"}
                                            validator={requiredValidator}
                                            hint={'Hint: Rejection Reason Description.'}
                                        />


                                        <div className="k-form-buttons">
                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit }
                                            >
                                                {rejectionReasonLoaded ? "Update Rejection Reason" : "Add New Rejection Reason"}
                                            </Button>
                                            <Button onClick={cancelForm}>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => {
                                                clearForm()
                                            }
                                            }>
                                                Clear
                                            </Button>
                                        </div>

                                        {buttonFlag && loadingInitial &&
                                        <LoadingComponent content='Processing Rejection Reason...'/>}
                                    </fieldset>

                                </FormElement>

                            )}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>

        </>


    );
}

export default observer(RejectionReasonForm);


