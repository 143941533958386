import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid, GridColumn as Column, GridPageChangeEvent, GridSortChangeEvent,} from "@progress/kendo-react-grid";

import {useStore} from '../../../app/stores/store';
import {Button} from "semantic-ui-react";
import {orderBy, SortDescriptor, State} from "@progress/kendo-data-query";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default observer(function SavingCategoriesList() {
    const {ideaStore, adminStore} = useStore();
    let {
        loadingInitial
    } = ideaStore;

    let {
        loadSavingCategories, savingCategories, savingCategoriesRegistry, loadSavingCategory,
        setSavingCategoryLoaded
    } = adminStore;


    const initialDataState: State = {skip: 0, take: 4};

    const [page, setPage] = React.useState<any>(initialDataState);

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };
    const initialSort: Array<SortDescriptor> = [
        {field: "savingCategoryId", dir: "asc"},
    ];
    const [sort, setSort] = React.useState(initialSort);


    useEffect(() => {
        if (savingCategoriesRegistry.size < 1) loadSavingCategories();
    }, [loadSavingCategories])

    const setSavingCategory = (savingCategoryId: number) => {
        loadSavingCategory(savingCategoryId)
        setSavingCategoryLoaded(true)
    }

    const savingCategoryIdCell = (props: any) => {
        return (
            <td

            ><Button basic as={Button}
                     onClick={() => setSavingCategory(props.dataItem.savingCategoryId)}
                     color='blue'
                     size='mini'>
                {props.dataItem.savingCategoryId}
            </Button>


            </td>
        )
    }

    if (loadingInitial) return <LoadingComponent content='Loading saving category...'/>
    return (

        <Fragment>

            <Grid className="main-grid"  style={{height: "400px"}}
                  data={orderBy(savingCategories, sort).slice(page.skip, page.take + page.skip)}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e: GridSortChangeEvent) => {
                      setSort(e.sort);
                  }}
                  skip={page.skip}
                  take={page.take}
                  total={savingCategories.length}
                  pageable={true}
                  onPageChange={pageChange}

            >

                <Column field="savingCategoryId" title="Saving Category Id" cell={savingCategoryIdCell} width={200}/>
                <Column field="savingCategoryDescription" title="Saving Category" width={300}/>
                <Column field="baseLineParameterDescription" title="BaseLine Parameter" width={300}/>
                <Column field="groupLiverDescription" title="Group Liver" width={300}/>
                <Column field="diTypeDescription" title="COI Type" width={300}/>

            </Grid>

        </Fragment>
    )
})
