import { makeAutoObservable, runInAction, toJS} from "mobx";
import agent from "../api/agent";

import {Pagination, PagingParams} from "../models/pagination";
import {CreateIdeaFormValues, Idea} from "../models/idea";
import {FinancialImpact, FinancialImpactGrid} from "../models/financialImpact";
import {ActionPlanTask, ActionPlanTaskGrid, CreateTaskFormValues} from "../models/actionPlanTask";
import {IdeaTracking, IdeaTrackingGrid} from "../models/ideaTracking";
import {toast} from "react-toastify";
import {progressionByNumber} from "../models/progressionByNumber";
import {progressionByValue} from "../models/progressionByValue";
import {progressionByNumberMonthConvertedToString} from "../models/progressionByNumberMonthConvertedToString";
import {progressionByValueMonthConvertedToString} from "../models/progressionByValueMonthConvertedToString";
import {cosSummaryReportByDepartment} from "../models/cosSummaryReportByDepartment";
import {cosPotentialImpactReport} from "../models/cosPotentialImpactReport";
import {cosSummaryReportByAffiliate} from "../models/cosSummaryReportByAffiliate";
import {Currency} from "../models/currency";
import {cosPotentialImpactChartReportByAffiliate} from "../models/cosPotentialImpactChartReportByAffiliate";
import {cosPotentialImpactChartReportByDepartment} from "../models/cosPotentialImpactChartReportByDepartment";
import {cosTop10ChartData} from "../models/cosTop10ChartData";
import {cosUtilizationChartData} from "../models/cosUtilizationChartData";
import {cosUtilizationReportData} from "../models/cosUtilizationReportData";
import {progressionByValueActual} from "../models/progressionByValueActual";
import {savePerBusinessChartData} from "../models/savePerBusinessChartData";
import {savePerDepartmentChartData} from "../models/savePerDepartmentChartData";
import {savePerTypeChartData} from "../models/savePerTypeChartData";
import {savePerGroupLeverChartData} from "../models/savePerGroupLeverChartData";
import {savePerGroupLeverReportData} from "../models/savePerGroupLeverReportData";
import {savePerSavingCategoryReportData} from "../models/savePerSavingCategoryReportData";
import {savePerSavingCategoryChartData} from "../models/savePerSavingCategoryChartData";
import {store} from "./store";

export default class IdeaStore {
    originatorIdeaRegistry = new Map<string, Idea>();
    vetterIdeaRegistry = new Map<string, Idea>();
    actionOwnerIdeaRegistry = new Map<string, Idea>();
    actionControllerIdeaRegistry = new Map<string, Idea>();
    loading = false;
    loadingInitial = false;
    pagination: Pagination | null = null;
    pagingParams = new PagingParams(0, 1000);
    predicate = new Map();
    currenciesRegistry = new Map<string, Currency>();
    currencies: any;
    diTypes: any;
    partyGroups: any;
    selectedDiType: any;
    selectedGroupLiver: any;
    selectedBaseLineParameter: any;
    selectedIdea: any;
    activeIndex: any = 0;
    possibleVetters: any;
    taskOwners: any;
    actionControllers: any;
    ideaTrackings: IdeaTrackingGrid[] = [];
    financialImpacts: FinancialImpactGrid[] = [];
    actionPlanTasksGrid: ActionPlanTaskGrid[] = [];
    finImpacts: FinancialImpact[] = [];
    actionPlans: ActionPlanTask[] = [];
    cosSummaryReportData: cosSummaryReportByDepartment[] = [];
    cosSummaryByDepartmentChartData: cosSummaryReportByDepartment[] = [];
    cosSummaryByAffiliateChartData: cosSummaryReportByAffiliate[] = [];
    cosPotentialImpactData: cosPotentialImpactReport[] = [];
    cosPotentialImpactByAffiliateAndStatusChartData: cosPotentialImpactChartReportByAffiliate[] = [];
    cosPotentialImpactByDepartmentAndStatusChartData: cosPotentialImpactChartReportByDepartment[] = [];
    progressionByNumberReportData: progressionByNumber[] = [];
    progressionByNumberChartData: progressionByNumberMonthConvertedToString[] = [];
    progressionByNumberChartDataWithTotals: progressionByNumberMonthConvertedToString[] = [];
    savePerBusinessChartData: savePerBusinessChartData[] = [];
    savePerDepartmentChartData: savePerDepartmentChartData[] = [];
    savePerSavingCategoryReportData: savePerSavingCategoryReportData[] = [];
    savePerTypeChartData: savePerTypeChartData[] = [];
    savePerSavingCategoryChartData: savePerSavingCategoryChartData[] = [];
    savePerGroupLeverChartData: savePerGroupLeverChartData[] = [];
    savePerGroupLeverReportData: savePerGroupLeverReportData[] = [];
    progressionByValueReportData: progressionByValue[] = [];
    progressionByValueChartData: progressionByValueMonthConvertedToString[] = [];
    progressionByValueActualsChartData: progressionByValueActual[] = [];
    progressionByValueChartDataWithTotals: progressionByValueMonthConvertedToString[] = [];
    top10ReportData: cosTop10ChartData[] = [];
    top10ChartData: cosTop10ChartData[] = [];
    top10Categories: string[] = [];
    cosUtilizationChartData: cosUtilizationChartData[] = [];
    cosUtilizationReportData: cosUtilizationReportData[] = [];
    panes: any;
    ideaStatusAllowTasks: boolean = false;
    ideaStatusAllowFormEditing: boolean = true;
    ideaStatusAllowUpdatingTaskCompletedDate: boolean = true;
    ideaStatusAllowUpdatingTaskFields: boolean = true;
    ideaStatusNextStep: string = "Approve";
    createdStamp: Date = new Date(Date.now());
    reportCurrency: string = '';

    constructor() {
        makeAutoObservable(this);
        console.log('From Idea Store');
        
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        this.predicate.forEach((value, key) => {
            params.append(key, value);
        })
        return params;
    }

    get ideas() {
        return Array.from(this.originatorIdeaRegistry.values());
    }

    get vetterIdeas() {
        return Array.from(this.vetterIdeaRegistry.values());
    }

    get actionOwnerIdeas() {
        return Array.from(this.actionOwnerIdeaRegistry.values());
    }

    get actionControllerIdeas() {
        return Array.from(this.actionControllerIdeaRegistry.values());
    }

    get filteredGroupLivers() {
        const groupLivers = store.adminStore.groupLivers
        const idea = this.selectedIdea
        if (idea === undefined && this.selectedDiType != undefined) {
            console.log('filteredGroupLivers', 1)
            return groupLivers.filter(
                (groupLiver: any) => Number(groupLiver.diTypeId) === Number(this.selectedDiType));
        }

        if (idea !== undefined && this.selectedDiType === undefined) {
            console.log('filteredGroupLivers', 2)
            return groupLivers.filter(
                (groupLiver: any) => Number(groupLiver.diTypeId) === Number(idea!.diTypeId));
        }

        if (idea !== undefined && this.selectedDiType !== undefined) {
            console.log('filteredGroupLivers', 3)
            return groupLivers.filter(
                (groupLiver: any) => Number(groupLiver.diTypeId) === Number(this.selectedDiType));
        }
        console.log('filteredGroupLivers', 4)
        return groupLivers;

    }

    get filteredBaseLineParameters() {
        const baseLineParameters = store.adminStore.baseLineParameters
const baseLineParametersJS = toJS(baseLineParameters)
        const idea = this.selectedIdea
        //console.log('filteredBaseLineParameters', 0)
        if (this.selectedDiType != undefined && this.selectedGroupLiver !== undefined) {
            return baseLineParameters.filter(
                (baseLineParameter: any) => Number(baseLineParameter.groupLiverId) === Number(this.selectedGroupLiver)
                    && Number(baseLineParameter.diTypeId) === Number(this.selectedDiType));
        }

        if (idea === undefined && this.selectedGroupLiver !== undefined) {
            return baseLineParameters.filter(
                (baseLineParameter: any) => Number(baseLineParameter.groupLiverId) === Number(this.selectedGroupLiver)
                    && Number(baseLineParameter.diTypeId) === Number(this.selectedDiType));
        }

        if (idea !== undefined && this.selectedGroupLiver === undefined) {
            //console.log('filteredBaseLineParameters', 2)
            return baseLineParameters.filter(
                (baseLineParameter: any) => Number(baseLineParameter.groupLiverId) === Number(idea!.groupLiverId)
                    && Number(baseLineParameter.diTypeId) === Number(idea!.diTypeId));
        }

        if (idea !== undefined && this.selectedGroupLiver !== undefined) {
            //console.log('filteredBaseLineParameters', 3)
            return baseLineParameters.filter(
                (baseLineParameter: any) => Number(baseLineParameter.groupLiverId) === Number(this.selectedGroupLiver)
                    && Number(baseLineParameter.diTypeId) === Number(idea!.diTypeId));
        }
        //console.log('filteredBaseLineParameters', 4)
        return baseLineParameters;
    }

    get filteredSavingCategories() {
        const savingCategories = store.adminStore.savingCategories

        const idea = this.selectedIdea

        if (this.selectedDiType != undefined && this.selectedGroupLiver !== undefined && this.selectedBaseLineParameter !== undefined) {
            console.log('filteredSavingCategories', 1)
            return savingCategories.filter(
                (savingCategory: any) => Number(savingCategory.diTypeId) === Number(this.selectedDiType)
                    && Number(savingCategory.groupLiverId) === Number(this.selectedGroupLiver)
                    && Number(savingCategory.baseLineParameterId) === Number(this.selectedBaseLineParameter));
        }


        if (idea !== undefined && this.selectedDiType === undefined && this.selectedGroupLiver !== undefined) {
            console.log('filteredSavingCategories', 2)
            return savingCategories.filter(
                (savingCategory: any) => Number(savingCategory.diTypeId) === Number(idea!.diTypeId)
                    && Number(savingCategory.groupLiverId) === Number(this.selectedGroupLiver)
                    && Number(savingCategory.baseLineParameterId) === Number(this.selectedBaseLineParameter))
        }

        if (idea !== undefined && this.selectedDiType === undefined && this.selectedGroupLiver === undefined && this.selectedBaseLineParameter != undefined) {
            console.log('filteredSavingCategories', 3)
            return savingCategories.filter(
                (savingCategory: any) => Number(savingCategory.diTypeId) === Number(idea!.diTypeId)
                    && Number(savingCategory.groupLiverId) === Number(idea!.groupLiverId)
                    && Number(savingCategory.baseLineParameterId) === Number(this.selectedBaseLineParameter))
        }
        
        if (idea !== undefined && this.selectedDiType === undefined && this.selectedGroupLiver === undefined && this.selectedBaseLineParameter === undefined) {
            console.log('filteredSavingCategories', 4)
            return savingCategories.filter(
                (savingCategory: any) => Number(savingCategory.diTypeId) === Number(idea!.diTypeId)
                    && Number(savingCategory.groupLiverId) === Number(idea!.groupLiverId)
                    && Number(savingCategory.baseLineParameterId) === Number(idea.baseLineParameterId))
        }

        console.log('filteredSavingCategories', 5)
        return savingCategories;
    }

    updateFinancialImpact = (updatedImpact: any[]) => {
        this.financialImpacts = updatedImpact;
    }

    setFinancialImpact = (date: Date, numberOfMonths: number) => {
        let identifier = 1
        this.financialImpacts = []
        let ind = 1;
        do {
            this.financialImpacts.push(new FinancialImpactGrid(ind, date, 0, 0, identifier));
            date = new Date(date.setMonth(date.getMonth() + 1));
            numberOfMonths -= 1
            ind += 1;

            identifier += 1
        }
        while (numberOfMonths > 0)
        const financialImpacts = toJS(this.financialImpacts)
        console.log('setFinancialImpact', toJS(this.financialImpacts))

    }

    loadFinancialImpact = (financialImpact: FinancialImpact[] | undefined) => {
        this.financialImpacts = []
        let identifier = 1
        financialImpact!.forEach((item) => {
            // @ts-ignore

            var date = new Date(item.impactMonth!);
            var day = date.getDate();       // yields date
            var month = date.getMonth() + 1;    // yields month (add one as '.getMonth()' is zero indexed)
            var year = date.getFullYear();  // yields year
            var hour = date.getHours();     // yields hours 
            var minute = date.getMinutes(); // yields minutes
            var second = date.getSeconds(); // yields seconds

            // After this construct a string with the above results as below
            var time = month + "/" + day + "/" + year + " " + hour + ':' + minute + ':' + second;

            this.financialImpacts.push(new FinancialImpactGrid(item.id, new Date(time), item.estimated, item.actual, identifier));
            identifier += 1
        })
    }

    loadActionPlanTasks = (actionPlanTask: ActionPlanTask[] | undefined) => {
        this.actionPlanTasksGrid = []

        actionPlanTask!.forEach((item) => {

            this.actionPlanTasksGrid.push(new ActionPlanTaskGrid(item.id, item.ideaId, item.description, item.contributors, item.completedDate.toString() === "0001-01-01T00:00:00" ? null : new Date(item.completedDate), new Date(item.dueDate), item.taskOwnerId, item.taskOwnerName));

        })

    }

    updateNewActionPlanTasks = (task: CreateTaskFormValues) => {
        const newTask = new ActionPlanTaskGrid(1, task.ideaId,
            task.description, task.contributors,
            (task.completedDate === null) ? null : task.completedDate!,
            task.dueDate, task.taskOwnerId, task.taskOwnerName)
        this.actionPlanTasksGrid.push(newTask)
    }

    updateExistingActionPlanTasks = (task: CreateTaskFormValues) => {
        let existingTask = this.actionPlanTasksGrid.filter((item: any) => item.ideaId == task.ideaId
            && item.id == task.id)

        existingTask[0].description = task.description!;
        existingTask[0].contributors = task.contributors!;
        existingTask[0].completedDate = task.completedDate;
        existingTask[0].dueDate = task.dueDate;
        existingTask[0].taskOwnerId = task.taskOwnerId!;

    }

    loadIdeaTracking = (ideaTrackings: IdeaTracking[] | undefined) => {
        this.ideaTrackings = []
        let ind = 1;
        ideaTrackings!.forEach((item) => {
            // @ts-ignore
            this.ideaTrackings.push(new IdeaTrackingGrid(ind,
                item.ideaId, item.ideaStatusId, item.ideaStatusDesc, item.processStatusDesc, item.processStatusId, item.comments,
                new Date(item.statusDate)));
            ind += 1;
        })

    }

    clearCascadedListSelections = () => {
        console.log('clearCascadedListSelections')
        this.selectedIdea = undefined;
        this.selectedDiType = undefined;
        this.selectedGroupLiver = undefined;
        this.selectedBaseLineParameter = undefined;
    }

    setSelectedDiType = (diTypeId: number) => {
        this.selectedDiType = diTypeId;
    }

    setSelectedGroupLiver = (groupLiverId: number) => {
        this.selectedGroupLiver = groupLiverId;
    }

    setBaseLineParameter = (baseLineParameterId: number) => {
        this.selectedBaseLineParameter = baseLineParameterId;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    clearRegistry = () => {
        this.originatorIdeaRegistry.clear()
    }

    setActiveIndex = (index: number) => {
        this.activeIndex = index;
    }

    setPredicate = (predicate: string, value: string) => {
        this.predicate.set(predicate, value);
    }

    /*loadProgressionByValueReportData2 = async () => {
      this.setLoadingInitial(true);
      const today = new Date()
      const year = new Date().getFullYear()
      let reducerNumbers:any
      let coiNumber:string = ""
      let finalCoiNumber:string = ""
      const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const ideaStatus_names = ['COS 2', 'COS 3', 'COS 4', 'COS 5']
      try {
        this.progressionByValueChartData=[]
        this.progressionByValueChartDataWithTotals =[]
        this.progressionByValueActualsChartData = []
        await this.loadProgressionByNumberReportData() // for number of COI
  
        const progressionByValueReportData = await agent.Ideas.getProgressionByValueReportData(this.axiosParams);
        const maxMonth = Math.max.apply(Math, progressionByValueReportData.data.map(function(o) { return o.month; }))
        const currentMonth = new Date().getMonth()
  
        for (let step = 1; step < maxMonth+1; step++) {
  
  
          if ( currentMonth >= step -1){
            const oneMonthActual = progressionByValueReportData.data.filter(i => i.month <= step && i.actual! > 0)
            let reducerActual = oneMonthActual.reduce((a, b) => ({actual: a.actual! + b.actual!, month: step, saving:0, d2EstimatedDate:today, d3EstimatedDate:today,
              d4EstimatedDate:today, d5EstimatedDate:today, createdStamp: today, ideaId: ''}));
            this.progressionByValueActualsChartData.push({actual : Math.round(reducerActual.actual! * 10) / 10, month : month_names_short[reducerActual.month - 1]})
          }
  
          const thisMonthData = progressionByValueReportData.data.filter(i => i.month <= step )
  
          const ideaIds = new Set();
          thisMonthData.filter(entry => {
            if (ideaIds.has(entry.ideaId)) {
              return false;
            }
            ideaIds.add(entry.ideaId);
            return true;
          });
  
          const thisMonthDate = new Date(year, step, 0);
  
          ideaIds.forEach((id:any) => {
            const thisMonthDataForOneIdea = thisMonthData.filter(i => i.ideaId === id)
  
  
            if (thisMonthDataForOneIdea[0].ideaStatus === '5') {
  
              let oneMonthDateConsecutive:progressionByValueReportData[] = progressionByValueReportData.data.filter(i => i.month <= step && i.ideaId === id)
              let reducer = oneMonthDateConsecutive.reduce((a, b) =>
                  ({saving: a.saving + b.saving, month: step, d2EstimatedDate:today, d3EstimatedDate:today,
                    d4EstimatedDate:today, d5EstimatedDate:today, createdStamp: today, ideaId: ''}));
  
              this.progressionByValueChartData.push({saving : Math.round(reducer.saving * 10) / 10, month : month_names_short[reducer.month - 1]
                , ideaStatusId : ideaStatus_names[3]})
  
              return;
  
            }
  
            if ( (new Date(thisMonthDataForOneIdea[0].d2EstimatedDate)) <= thisMonthDate && (new Date(thisMonthDataForOneIdea[0].d3EstimatedDate)) >= thisMonthDate) {
              let oneMonthDateConsecutive:progressionByValueReportData[] = progressionByValueReportData.data.filter(i => i.month <= step && i.ideaId === id)
              let reducer = oneMonthDateConsecutive.reduce((a, b) =>
                  ({saving: a.saving + b.saving, month: step, d2EstimatedDate:today, d3EstimatedDate:today,
                    d4EstimatedDate:today, d5EstimatedDate:today, createdStamp: today, ideaId: ''}));
  
              this.progressionByValueChartData.push({saving : Math.round(reducer.saving * 10) / 10, month : month_names_short[reducer.month - 1]
                , ideaStatusId : ideaStatus_names[0]})
  
              return;
            }
  
            if ( (new Date(thisMonthDataForOneIdea[0].d3EstimatedDate)) <= thisMonthDate && (new Date(thisMonthDataForOneIdea[0].d4EstimatedDate)) >= thisMonthDate) {
              let oneMonthDateConsecutive:progressionByValueReportData[] = progressionByValueReportData.data.filter(i => i.month <= step && i.ideaId === id)
              let reducer = oneMonthDateConsecutive.reduce((a, b) =>
                  ({saving: a.saving + b.saving, month: step, d2EstimatedDate:today, d3EstimatedDate:today,
                    d4EstimatedDate:today, d5EstimatedDate:today, createdStamp: today, ideaId: ''}));
  
              this.progressionByValueChartData.push({saving : Math.round(reducer.saving * 10) / 10, month : month_names_short[reducer.month - 1]
                , ideaStatusId : ideaStatus_names[1]})
  
              return;
            }
  
            if ( (new Date(thisMonthDataForOneIdea[0].d4EstimatedDate)) <= thisMonthDate && (new Date(thisMonthDataForOneIdea[0].d5EstimatedDate)) >= thisMonthDate) {
              let oneMonthDateConsecutive:progressionByValueReportData[] = progressionByValueReportData.data.filter(i => i.month <= step && i.ideaId === id)
              let reducer = oneMonthDateConsecutive.reduce((a, b) =>
                  ({saving: a.saving + b.saving, month: step, d2EstimatedDate:today, d3EstimatedDate:today,
                    d4EstimatedDate:today, d5EstimatedDate:today, createdStamp: today, ideaId: ''}));
  
              this.progressionByValueChartData.push({saving : Math.round(reducer.saving * 10) / 10, month : month_names_short[reducer.month - 1]
                , ideaStatusId : ideaStatus_names[2]})
  
              return;
            }
  
          })
  
          /!*const oneMonthNumbers = this.progressionByNumberChartDataWithTotals.filter(i => i.month === month_names_short[step-1])
          if (this.progressionByValueChartData.length > 0){
            if (oneMonthNumbers.length > 0){
              reducerNumbers = oneMonthNumbers.reduce((a, b) => ({count: a.count + b.count, month:'',ideaStatusId: ''}));
              coiNumber = '\n'+reducerNumbers.count.toString()
            }
  
            let reducer = this.progressionByValueChartData.reduce((a, b) => ({saving: a.saving + b.saving, month:'',ideaStatusId: ''}));
            this.progressionByValueChartData.forEach(record => {
              this.progressionByValueChartDataWithTotals.push({saving: Math.round(record.saving * 10) / 10, month: record.month + coiNumber , ideaStatusId:record.ideaStatusId, total: Math.round(reducer.saving * 10) / 10 })
            })
          }*!/
  
        }
  
  
        /!* const currentMonth1:number = new Date().getMonth()
         
         this.progressionByValueChartDataWithTotals.forEach(i => {
           const indexOfMonth = month_names_short.indexOf(i.month)
           if (indexOfMonth <= currentMonth1) {
             i.month = i.month + finalCoiNumber
           }
           
         })*!/
  
        const progressionByNumberChartDataWithTotals = toJS(this.progressionByNumberChartDataWithTotals)
        const progressionByValueChartDataWithTotals1 = toJS(this.progressionByValueActualsChartData)
        this.setLoadingInitial(false)
  
      } catch (error) {
        this.setLoadingInitial(false);
        console.log(error);toast.error('error');
      }
    };*/
    /*loadProgressionByValueReportData = async () => {
      this.setLoadingInitial(true);
      const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const ideaStatus_names = ['COS 2', 'COS 3', 'COS 4', 'COS 5']
      try {
        this.progressionByValueChartData=[]
        this.progressionByValueChartDataWithTotals =[]
        this.progressionByValueActualsChartData = []
        const progressionByValueReportData = await agent.Ideas.getProgressionByValueReportData(this.axiosParams);
        const temp = progressionByValueReportData.data.filter(i => i.ideaStatusId=="")
        const maxMonth = Math.max.apply(Math, progressionByValueReportData.data.map(function(o) { return o.month; }))
        for (let step = 1; step < maxMonth+1; step++) {
  
          const currentMonth = new Date().getMonth()
  
          if ( currentMonth >= step -1){
            const oneMonthActual = progressionByValueReportData.data.filter(i => i.month <= step && i.actual! > 0)
            let reducerActual = oneMonthActual.reduce((a, b) => ({actual: a.actual! + b.actual!, month: step, saving:0, ideaStatusId:''}));
            this.progressionByValueActualsChartData.push({actual : Math.round(reducerActual.actual! * 10) / 10, month : month_names_short[reducerActual.month - 1]})
          }
  
          const temp = progressionByValueReportData.data.filter(i => i.month <= step )
  
          const maxStatusForThisMonth =  Math.max.apply(Math, temp.map(function(o) { return parseInt(o.ideaStatusId); }))
          for (let status = 2; status < maxStatusForThisMonth+1; status++) {
            if (status === 5){
              let oneMonthDateConsecutive:progressionByValue[] = progressionByValueReportData.data.filter(i => i.month <= step )
              let reducer = oneMonthDateConsecutive
                  .reduce((a, b) => ({saving: a.saving + b.saving, month: step, ideaStatusId:status.toString()}));
              this.progressionByValueChartData.push({saving : Math.round(reducer.saving * 10) / 10, month : month_names_short[reducer.month - 1], ideaStatusId : ideaStatus_names[parseInt(reducer.ideaStatusId) - 2]})
  
            }else {
              let oneMonthDateConsecutive:progressionByValue[] = progressionByValueReportData.data.filter(i => i.month <= step && i.ideaStatusId == status.toString())
              let reducer = oneMonthDateConsecutive.reduce((a, b) => ({saving: a.saving + b.saving, month: step, ideaStatusId:status.toString()}));
              this.progressionByValueChartData.push({saving : Math.round(reducer.saving * 10) / 10, month : month_names_short[reducer.month - 1], ideaStatusId : ideaStatus_names[parseInt(reducer.ideaStatusId) - 2]})
  
            }
  
          }
        }
        await this.loadProgressionByNumberReportData()
        let reducerNumbers:any
        let coiNumber:string = ""
        let finalCoiNumber:string = ""
        month_names_short.forEach(month => {
          const oneMonth = this.progressionByValueChartData.filter(i => i.month === month)
          const oneMonthNumbers = this.progressionByNumberChartDataWithTotals.filter(i => i.month === month)
          if (oneMonth.length > 0){
            if (oneMonthNumbers.length > 0){
              reducerNumbers = oneMonthNumbers.reduce((a, b) => ({count: a.count + b.count, month:'',ideaStatusId: ''}));
              coiNumber = '\n'+reducerNumbers.count.toString()
            }else {
              finalCoiNumber = coiNumber
            }
  
            let reducer = oneMonth.reduce((a, b) => ({saving: a.saving + b.saving, month:'',ideaStatusId: ''}));
            oneMonth.forEach(record => {
              this.progressionByValueChartDataWithTotals.push({saving: Math.round(record.saving * 10) / 10, month: record.month , ideaStatusId:record.ideaStatusId, total: Math.round(reducer.saving * 10) / 10 })
            })
          }
  
  
        })
  
        const currentMonth:number = new Date().getMonth()
  
        this.progressionByValueChartDataWithTotals.forEach(i => {
          const indexOfMonth = month_names_short.indexOf(i.month)
          if (indexOfMonth <= currentMonth) {
            i.month = i.month + finalCoiNumber
          }
  
        })
  
  
        const progressionByNumberChartDataWithTotals = toJS(this.progressionByNumberChartDataWithTotals)
        const progressionByValueChartData1 = toJS(this.progressionByValueChartData)
        const progressionByValueChartDataWithTotals1 = toJS(this.progressionByValueActualsChartData)
        this.setLoadingInitial(false)
  
      } catch (error) {
        this.setLoadingInitial(false);
        console.log(error);toast.error('error');
      }
    };*/

    loadOriginatorIdeas = async () => {
        this.setLoadingInitial(true);
        try {
            const result = await agent.Ideas.listOriginatorIdeas(this.axiosParams);
            result.data.forEach(idea => {
                runInAction(() => {
                    idea.createdStamp = new Date(idea.createdStamp);
                    this.originatorIdeaRegistry.set(idea.ideaId, idea);
                });
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');

        }
        this.resetDateParams()
    };

    loadProgressionByNumberReportData = async () => {
        this.setLoadingInitial(true);
        const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const ideaStatus_names = ['Draft', 'COS 2', 'COS 3', 'COS 4', 'COS 5']
        try {
            this.progressionByNumberChartData = [];
            this.progressionByNumberChartDataWithTotals = [];
            const progressionByNumberReportData = await agent.Ideas.getProgressionByNumberReportData(this.axiosParams);
            const maxMonth = Math.max.apply(Math, progressionByNumberReportData.data.map(function (o) {
                return o.month;
            }))
            for (let step = 1; step < maxMonth + 1; step++) {
                const temp = progressionByNumberReportData.data.filter(i => i.month <= step)
                const maxStatusForThisMonth = Math.max.apply(Math, temp.map(function (o) {
                    return parseInt(o.ideaStatusId);
                }))
                for (let status = 1; status < maxStatusForThisMonth + 1; status++) {
                    const progressionByNumberChartDataJS = toJS(this.progressionByNumberChartData)
                    let oneMonthDateConsecutive: progressionByNumber[] = progressionByNumberReportData.data.filter(i => i.month <= step && i.ideaStatusId == status.toString())
                    let reducer = oneMonthDateConsecutive.reduce((a, b) => ({
                        count: a.count + b.count,
                        month: step,
                        ideaStatusId: status.toString()
                    }));

                    this.progressionByNumberChartData.push({
                        count: reducer.count,
                        month: month_names_short[reducer.month - 1],
                        ideaStatusId: ideaStatus_names[parseInt(reducer.ideaStatusId) - 1]
                    })

                }

            }
            month_names_short.forEach(month => {
                const oneMonth = this.progressionByNumberChartData.filter(i => i.month === month)
                if (oneMonth.length > 0) {
                    let reducer = oneMonth.reduce((a, b) => ({count: a.count + b.count, month: '', ideaStatusId: ''}));
                    oneMonth.forEach(record => {
                        this.progressionByNumberChartDataWithTotals.push({
                            count: record.count,
                            month: record.month,
                            ideaStatusId: record.ideaStatusId,
                            total: reducer.count
                        })
                    })
                }


            })
            const progressionByNumberChartData1 = toJS(this.progressionByNumberChartData)
            const progressionByNumberChartDataWithTotals1 = toJS(this.progressionByNumberChartDataWithTotals)
            this.setLoadingInitial(false)

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    };

    loadProgressionByValueReportData3 = async () => {
        this.setLoadingInitial(true);
        const today = new Date()
        const year = new Date().getFullYear()
        let reducerNumbers: any
        let coiNumber: string = ""
        let finalCoiNumber: string = ""
        const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const ideaStatus_names = ['COS 2', 'COS 3', 'COS 4', 'COS 5']
        try {
            this.progressionByValueChartData = []
            this.progressionByValueChartDataWithTotals = []
            this.progressionByValueActualsChartData = []
            await this.loadProgressionByNumberReportData() // for number of COI
            const progressionByNumberChartDataWithTotals = toJS(this.progressionByNumberChartDataWithTotals)
            const progressionByValueReportData = await agent.Ideas.getProgressionByValueReportData(this.axiosParams);
            const maxMonth = Math.max.apply(Math, progressionByValueReportData.map(function (o) {
                return o.month;
            }))
            const currentMonth = new Date().getMonth()

            for (let step = 1; step < maxMonth + 1; step++) {


                if (currentMonth >= step - 1) {
                    const oneMonthActual = progressionByValueReportData.filter(i => i.month <= step && i.actual! > 0)
                    let reducerActual = oneMonthActual.reduce((a, b) => ({
                        actual: a.actual! + b.actual!,
                        month: step,
                        saving: 0,
                        ideaId: '',
                        ideaStatusId: ''
                    }));
                    this.progressionByValueActualsChartData.push({
                        actual: Math.round(reducerActual.actual!),
                        month: month_names_short[reducerActual.month - 1]
                    })
                }

                const thisMonthData = progressionByValueReportData.filter(i => i.month === step)
                let reducerForThisMonthData = thisMonthData.reduce((a, b) => ({
                    saving: a.saving + b.saving,
                    month: step,
                    ideaStatusId: '',
                    ideaId: ''
                }));

                const maxStatusForThisMonth = Math.max.apply(Math, thisMonthData.map(function (o) {
                    return parseInt(o.ideaStatusId);
                }))
                for (let status = 2; status < maxStatusForThisMonth + 1; status++) {

                    let oneMonthDateConsecutive: progressionByValue[] = progressionByValueReportData.filter(i => i.month === step && i.ideaStatusId == status.toString())

                    if (oneMonthDateConsecutive.length > 0) {
                        let reducer = oneMonthDateConsecutive.reduce((a, b) => ({
                            saving: a.saving + b.saving,
                            month: step,
                            ideaStatusId: status.toString()
                        }));

                        const oneMonthNumbers = this.progressionByNumberChartDataWithTotals.filter(i => i.month === month_names_short[step - 1])
                        if (oneMonthNumbers.length > 0) {
                            reducerNumbers = oneMonthNumbers.reduce((a, b) => ({
                                count: a.count + b.count,
                                month: '',
                                ideaStatusId: ''
                            }));
                            coiNumber = '\n' + reducerNumbers.count.toString()
                        }
                        this.progressionByValueChartDataWithTotals.push({
                            saving: Math.round(reducer.saving),
                            month: month_names_short[reducer.month - 1] + coiNumber,
                            ideaStatusId: ideaStatus_names[parseInt(reducer.ideaStatusId) - 2],
                            total: Math.round(reducerForThisMonthData.saving)
                        })

                    }


                }

            }


            /*if (this.progressionByValueChartData.length > 0){
              
    
              let reducer = this.progressionByValueChartData.reduce((a, b) => ({saving: a.saving + b.saving, month:'',ideaStatusId: ''}));
              this.progressionByValueChartData.forEach(record => {
                this.progressionByValueChartDataWithTotals.push({saving: Math.round(record.saving * 10) / 10, month: record.month + coiNumber , ideaStatusId:record.ideaStatusId, total: Math.round(reducer.saving * 10) / 10 })
              })
            }  */

            this.setLoadingInitial(false)
        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }


        /* const currentMonth1:number = new Date().getMonth()
         
         this.progressionByValueChartDataWithTotals.forEach(i => {
           const indexOfMonth = month_names_short.indexOf(i.month)
           if (indexOfMonth <= currentMonth1) {
             i.month = i.month + finalCoiNumber
           }
           
         })
               
         const progressionByNumberChartDataWithTotals = toJS(this.progressionByNumberChartDataWithTotals)
         const progressionByValueChartDataWithTotals1 = toJS(this.progressionByValueActualsChartData)
         this.setLoadingInitial(false)
   
       }*/
    };

    loadSavingPerGroupLeverReportData = async () => {
        this.setLoadingInitial(true);

        try {
            console.log('loadSavingPerGroupLeverReportData before DB', new Date())
            const groupLeverList = await agent.GroupLivers.list();

            const groupLevers = Array.from(
                new Set(groupLeverList.map((p) => p.groupLiverDescription))
            );

            this.savePerGroupLeverChartData = []


            const savePerGroupLeverReportData = await agent.Ideas.getSavingPerGroupLeverReportData(this.axiosParams);
            console.log('loadSavingPerGroupLeverReportData After DB', new Date())

            if (savePerGroupLeverReportData.data.length > 0) {
                let cumulativeReducer = 0
                let totalReducer = savePerGroupLeverReportData.data.reduce((a, b) => ({
                    saving: a.saving + b.saving,
                    groupLever: "",
                    estimated: 0,
                    ideaId: '',
                    actual: 0
                }));

                groupLevers.forEach((groupLever, index, groupLevers) => {
                    const oneGroupLever = savePerGroupLeverReportData.data.filter(i => i.groupLever === groupLevers[index])

                    if (oneGroupLever.length > 0) {

                        let reducer = oneGroupLever.reduce((a, b) => ({
                            saving: a.saving + b.saving,
                            groupLever: groupLever,
                            estimated: 0,
                            ideaId: '',
                            actual: 0
                        }));

                        cumulativeReducer += reducer.saving

                        this.savePerGroupLeverChartData.push({
                            saving: Math.round(((reducer.saving / totalReducer.saving) * 100) * 10) / 10,
                            groupLever: groupLever,
                            percent: Math.round(((cumulativeReducer / totalReducer.saving) * 100) * 10) / 10
                        })

                    }

                })
            }


            const savePerGroupLeverChartData = toJS(this.savePerGroupLeverChartData)

            this.savePerGroupLeverChartData.sort((a, b) => b.saving - a.saving);

            this.setLoadingInitial(false)

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
        console.log('loadSavingPerGroupLeverReportData After Processing', new Date())

    };

    loadSavingPerSavingCategoryReportData = async () => {
        this.setLoadingInitial(true);

        try {

            const savingCategoryList = await agent.SavingCategories.list();

            const savingCategories = Array.from(
                new Set(savingCategoryList.map((p) => p.savingCategoryDescription))
            );

            this.savePerSavingCategoryChartData = []


            const savePerSavingCategoriesReportData = await agent.Ideas.getSavingPerSavingCategoryReportData(this.axiosParams);

            if (savePerSavingCategoriesReportData.data.length > 0) {
                let cumulativeReducer = 0
                let totalReducer = savePerSavingCategoriesReportData.data.reduce((a, b) => ({
                    saving: a.saving + b.saving,
                    savingCategory: "",
                    estimated: 0,
                    ideaId: '',
                    actual: 0
                }));

                savingCategories.forEach((savingCategory, index, savingCategories) => {
                    const oneSavingCategory = savePerSavingCategoriesReportData.data.filter(i => i.savingCategory === savingCategories[index])

                    if (oneSavingCategory.length > 0) {

                        let reducer = oneSavingCategory.reduce((a, b) => ({
                            saving: a.saving + b.saving,
                            savingCategory: savingCategory,
                            estimated: 0,
                            ideaId: '',
                            actual: 0
                        }));

                        cumulativeReducer += reducer.saving

                        this.savePerSavingCategoryChartData.push({
                            saving: Math.round(((reducer.saving / totalReducer.saving) * 100) * 10) / 10,
                            savingCategory: savingCategory,
                            percent: Math.round(((cumulativeReducer / totalReducer.saving) * 100) * 10) / 10
                        })

                    }

                })
            }

            this.savePerSavingCategoryChartData.sort((a, b) => b.saving - a.saving);


            this.setLoadingInitial(false)

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    };

    loadSavingPerBusinessReportData = async () => {
        this.setLoadingInitial(true);
        try {
            const departmentsAndAffiliates = await agent.PartyGroups.ListDepartmentsAndAffiliates();

            const affiliates = Array.from(
                new Set(departmentsAndAffiliates.map((p) => p.affiliate))
            );

            this.savePerBusinessChartData = []
            const savePerBusinessReportData = await agent.Ideas.getSavingPerBusinessReportData(this.axiosParams);

            let notesActual = undefined;
            let notesEstimate = undefined;

            affiliates.forEach(affiliate => {
                const oneSavingType = savePerBusinessReportData.data.filter(i => i.partyGroupId === affiliate)

                if (oneSavingType.length > 0) {
                    let reducerActual = oneSavingType.reduce((a, b) => ({
                        actual: a.actual! + b.actual,
                        partyGroupId: affiliate,
                        estimated: 0,
                        ideaId: ''
                    }));
                    let reducerEstimate = oneSavingType.reduce((a, b) => ({
                        estimated: a.estimated! + b.estimated,
                        partyGroupId: affiliate,
                        actual: 0,
                        ideaId: ''
                    }));
                    notesActual = reducerActual.actual >= reducerEstimate.estimated ? "notesGreen" : undefined
                    notesEstimate = reducerEstimate.estimated >= reducerActual.actual ? "notesRed" : undefined

                    const difference = this.relDiff(reducerActual.actual!, reducerEstimate.estimated!);

                    if (notesActual === "notesGreen") {
                        this.savePerBusinessChartData.push({
                            saving: Math.round(reducerActual.actual! * 10) / 10,
                            partyGroup: affiliate,
                            savingType: 'Actual',
                            difference: difference,
                            notes: notesActual
                        })
                        this.savePerBusinessChartData.push({
                            saving: Math.round(reducerEstimate.estimated! * 10) / 10,
                            partyGroup: affiliate,
                            savingType: 'Estimate',
                            notes: notesEstimate
                        })

                    } else {
                        this.savePerBusinessChartData.push({
                            saving: Math.round(reducerActual.actual! * 10) / 10,
                            partyGroup: affiliate,
                            savingType: 'Actual',
                            notes: notesActual
                        })
                        this.savePerBusinessChartData.push({
                            saving: Math.round(reducerEstimate.estimated! * 10) / 10,
                            partyGroup: affiliate,
                            savingType: 'Estimate',
                            difference: difference,
                            notes: notesEstimate
                        })

                    }

                    /*this.savePerBusinessChartData.push({saving : Math.round(reducerActual.actual! * 10) / 10, partyGroup:affiliate, savingType:'Actual', difference: difference})
                    this.savePerBusinessChartData.push({saving : Math.round(reducerEstimate.estimated! * 10) / 10, partyGroup:affiliate, savingType:'Estimate', difference: difference})
                  */
                }

            })

            let reducerTotalEstimate = savePerBusinessReportData.data.reduce((a, b) => ({
                estimated: a.estimated + b.estimated,
                partyGroupId: "Total",
                actual: 0,
                ideaId: ''
            }));
            let reducerTotalActual = savePerBusinessReportData.data.reduce((a, b) => ({
                actual: a.actual + b.actual,
                partyGroupId: "Total",
                estimated: 0,
                ideaId: ''
            }));
            const difference = this.relDiff(reducerTotalActual.actual!, reducerTotalEstimate.estimated!);

            notesActual = reducerTotalActual.actual >= reducerTotalEstimate.estimated ? "notesGreen" : undefined
            notesEstimate = reducerTotalEstimate.estimated >= reducerTotalActual.actual ? "notesRed" : undefined
            if (notesActual === "notesGreen") {
                this.savePerBusinessChartData.push({
                    saving: Math.round(reducerTotalEstimate.estimated * 10) / 10,
                    partyGroup: "Total",
                    savingType: 'Estimate',
                    notes: notesEstimate
                })
                this.savePerBusinessChartData.push({
                    saving: Math.round(reducerTotalActual.actual * 10) / 10,
                    partyGroup: "Total",
                    savingType: 'Actual',
                    difference: difference,
                    notes: notesActual
                })

            } else {
                this.savePerBusinessChartData.push({
                    saving: Math.round(reducerTotalEstimate.estimated * 10) / 10,
                    partyGroup: "Total",
                    savingType: 'Estimate',
                    difference: difference,
                    notes: notesEstimate
                })
                this.savePerBusinessChartData.push({
                    saving: Math.round(reducerTotalActual.actual * 10) / 10,
                    partyGroup: "Total",
                    savingType: 'Actual',
                    notes: notesActual
                })

            }

            /* this.savePerBusinessChartData.push({saving : Math.round(reducerTotalEstimate.estimated * 10) / 10, partyGroup:"Total", savingType:'Estimate', difference: difference})
             this.savePerBusinessChartData.push({saving : Math.round(reducerTotalActual.actual * 10) / 10, partyGroup:"Total", savingType:'Actual', difference: difference})
             */


            const savePerBusinessChartData = toJS(this.savePerBusinessChartData)
            const progressionByValueChartData1 = toJS(this.progressionByValueChartData)
            const progressionByValueChartDataWithTotals1 = toJS(this.progressionByValueActualsChartData)
            this.setLoadingInitial(false)

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    };

    relDiff = (a: any, b: any) => {
        /* const p1 = ( a - b )
         const p2 = (a+b)/2
         const p3 = Math.abs( ( a - b ) / ( (a+b)/2 ) )
         const p4 = (100 * Math.abs( ( a - b ) / ( (a+b)/2 ) ))
         return (100 * Math.abs( ( a - b ) / ( (a+b)/2 ) )).toFixed() + ' %';*/

        let p1 = 0
        if (a > b) {
            p1 = (a - b) / a * 100
        } else {
            p1 = (a - b) / b * 100
        }
        return p1.toFixed() + ' %'

    }

    loadSavingPerDepartmentReportData = async () => {
        this.setLoadingInitial(true);
        try {
            const departmentsAndAffiliates = await agent.PartyGroups.ListDepartmentsAndAffiliates();


            this.savePerDepartmentChartData = []
            const savePerDepartmentChartData = await agent.Ideas.getSavingPerDepartmentReportData(this.axiosParams);


            let notesActual = undefined;
            let notesEstimate = undefined;
            departmentsAndAffiliates.forEach(department => {
                const oneSavingType = savePerDepartmentChartData.data.filter(i => i.partyGroupId === department.department && i.parentPartyGroupId === department.affiliate)

                if (oneSavingType.length > 0) {
                    let reducerActual = oneSavingType.reduce((a, b) => ({
                        actual: a.actual! + b.actual,
                        partyGroupId: department.department,
                        estimated: 0,
                        ideaId: ''
                    }));
                    let reducerEstimate = oneSavingType.reduce((a, b) => ({
                        estimated: a.estimated! + b.estimated,
                        partyGroupId: department.department,
                        actual: 0,
                        ideaId: ''
                    }));
                    notesActual = reducerActual.actual >= reducerEstimate.estimated ? "notesGreen" : undefined
                    notesEstimate = reducerEstimate.estimated >= reducerActual.actual ? "notesRed" : undefined
                    const difference = this.relDiff(reducerActual.actual!, reducerEstimate.estimated!);
                    if (notesActual === "notesGreen") {
                        this.savePerDepartmentChartData.push({
                            saving: Math.round(reducerActual.actual! * 10) / 10,
                            partyGroup: department.department,
                            savingType: 'Actual',
                            difference: difference,
                            notes: notesActual
                        })
                        this.savePerDepartmentChartData.push({
                            saving: Math.round(reducerEstimate.estimated! * 10) / 10,
                            partyGroup: department.department,
                            savingType: 'Estimate',
                            notes: notesEstimate
                        })

                    } else {
                        this.savePerDepartmentChartData.push({
                            saving: Math.round(reducerActual.actual! * 10) / 10,
                            partyGroup: department.department,
                            savingType: 'Actual',
                            notes: notesActual
                        })
                        this.savePerDepartmentChartData.push({
                            saving: Math.round(reducerEstimate.estimated! * 10) / 10,
                            partyGroup: department.department,
                            savingType: 'Estimate',
                            difference: difference,
                            notes: notesEstimate
                        })

                    }
                }

            })


            let reducerTotalEstimate = savePerDepartmentChartData.data.reduce((a, b) => ({
                estimated: a.estimated + b.estimated,
                partyGroupId: "Total",
                actual: 0,
                ideaId: ''
            }));
            let reducerTotalActual = savePerDepartmentChartData.data.reduce((a, b) => ({
                actual: a.actual + b.actual,
                partyGroupId: "Total",
                estimated: 0,
                ideaId: ''
            }));
            const difference = this.relDiff(reducerTotalActual.actual!, reducerTotalEstimate.estimated!);
            notesActual = reducerTotalActual.actual >= reducerTotalEstimate.estimated ? "notesGreen" : undefined
            notesEstimate = reducerTotalEstimate.estimated >= reducerTotalActual.actual ? "notesRed" : undefined
            if (notesActual === "notesGreen") {
                this.savePerDepartmentChartData.push({
                    saving: Math.round(reducerTotalEstimate.estimated * 10) / 10,
                    partyGroup: "Total",
                    savingType: 'Estimate',
                    notes: notesEstimate
                })
                this.savePerDepartmentChartData.push({
                    saving: Math.round(reducerTotalActual.actual * 10) / 10,
                    partyGroup: "Total",
                    savingType: 'Actual',
                    difference: difference,
                    notes: notesActual
                })

            } else {
                this.savePerDepartmentChartData.push({
                    saving: Math.round(reducerTotalEstimate.estimated * 10) / 10,
                    partyGroup: "Total",
                    savingType: 'Estimate',
                    difference: difference,
                    notes: notesEstimate
                })
                this.savePerDepartmentChartData.push({
                    saving: Math.round(reducerTotalActual.actual * 10) / 10,
                    partyGroup: "Total",
                    savingType: 'Actual',
                    notes: notesActual
                })

            }


            const savePerDepartmentChartData1 = toJS(this.savePerDepartmentChartData)

            this.setLoadingInitial(false)

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    };

    loadSavingPerTypeReportData = async () => {
        this.setLoadingInitial(true);
        try {
            const diTypesList = await agent.DiTypes.list();

            const diTypes = Array.from(
                new Set(diTypesList.map((p) => p.diTypeDescription))
            );

            this.savePerTypeChartData = []
            const savePerTypeReportData = await agent.Ideas.getSavingPerTypeReportData(this.axiosParams);

            let notesActual = undefined;
            let notesEstimate = undefined;

            diTypes.forEach(diType => {
                const oneSavingType = savePerTypeReportData.data.filter(i => i.diType === diType)

                if (oneSavingType.length > 0) {
                    let reducerActual = oneSavingType.reduce((a, b) => ({
                        actual: a.actual! + b.actual,
                        diType: diType,
                        estimated: 0,
                        ideaId: ''
                    }));
                    let reducerEstimate = oneSavingType.reduce((a, b) => ({
                        estimated: a.estimated! + b.estimated,
                        diType: diType,
                        actual: 0,
                        ideaId: ''
                    }));
                    notesActual = reducerActual.actual >= reducerEstimate.estimated ? "notesGreen" : undefined
                    notesEstimate = reducerEstimate.estimated >= reducerActual.actual ? "notesRed" : undefined

                    const difference = this.relDiff(reducerActual.actual!, reducerEstimate.estimated!);
                    if (notesActual === "notesGreen") {
                        this.savePerTypeChartData.push({
                            saving: Math.round(reducerActual.actual! * 10) / 10,
                            diType: diType,
                            savingType: 'Actual',
                            difference: difference,
                            notes: notesActual
                        })
                        this.savePerTypeChartData.push({
                            saving: Math.round(reducerEstimate.estimated! * 10) / 10,
                            diType: diType,
                            savingType: 'Estimate',
                            notes: notesEstimate
                        })

                    } else {
                        this.savePerTypeChartData.push({
                            saving: Math.round(reducerActual.actual! * 10) / 10,
                            diType: diType,
                            savingType: 'Actual',
                            notes: notesActual
                        })
                        this.savePerTypeChartData.push({
                            saving: Math.round(reducerEstimate.estimated! * 10) / 10,
                            diType: diType,
                            savingType: 'Estimate',
                            difference: difference,
                            notes: notesEstimate
                        })

                    }

                    /*this.savePerTypeChartData.push({saving : Math.round(reducerActual.actual! * 10) / 10, diType:diType, savingType:'Actual', difference: difference})
                    this.savePerTypeChartData.push({saving : Math.round(reducerEstimate.estimated! * 10) / 10, diType:diType, savingType:'Estimate', difference: difference})
                  */
                }

            })

            let reducerTotalEstimate = savePerTypeReportData.data.reduce((a, b) => ({
                estimated: a.estimated + b.estimated,
                diType: "Total",
                actual: 0,
                ideaId: ''
            }));
            let reducerTotalActual = savePerTypeReportData.data.reduce((a, b) => ({
                actual: a.actual + b.actual,
                diType: "Total",
                estimated: 0,
                ideaId: ''
            }));
            const difference = this.relDiff(reducerTotalActual.actual!, reducerTotalEstimate.estimated!);

            notesActual = reducerTotalActual.actual >= reducerTotalEstimate.estimated ? "notesGreen" : undefined
            notesEstimate = reducerTotalEstimate.estimated >= reducerTotalActual.actual ? "notesRed" : undefined
            if (notesActual === "notesGreen") {
                this.savePerTypeChartData.push({
                    saving: Math.round(reducerTotalEstimate.estimated * 10) / 10,
                    diType: "Total",
                    savingType: 'Estimate',
                    notes: notesEstimate
                })
                this.savePerTypeChartData.push({
                    saving: Math.round(reducerTotalActual.actual * 10) / 10,
                    diType: "Total",
                    savingType: 'Actual',
                    difference: difference,
                    notes: notesActual
                })

            } else {
                this.savePerTypeChartData.push({
                    saving: Math.round(reducerTotalEstimate.estimated * 10) / 10,
                    diType: "Total",
                    savingType: 'Estimate',
                    difference: difference,
                    notes: notesEstimate
                })
                this.savePerTypeChartData.push({
                    saving: Math.round(reducerTotalActual.actual * 10) / 10,
                    diType: "Total",
                    savingType: 'Actual',
                    notes: notesActual
                })

            }


            /*this.savePerTypeChartData.push({saving : Math.round(reducerTotalEstimate.estimated * 10) / 10, diType:"Total", savingType:'Estimate', difference: difference})
            this.savePerTypeChartData.push({saving : Math.round(reducerTotalActual.actual * 10) / 10, diType:"Total", savingType:'Actual', difference: difference})
            */


            const savePerTypeChartData = toJS(this.savePerTypeChartData)

            this.setLoadingInitial(false)

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    };

    loadCosTop10ReportData = async () => {
        this.top10ChartData = []
        this.top10ReportData = []
        this.setLoadingInitial(true);
        try {
            const top10ReportData = await agent.Ideas.getCosTop10ReportData(this.axiosParams);
            if (top10ReportData.data.length == 0) {
                return
            }
            const ideas = Array.from(
                new Set(top10ReportData.data.map((p) => p.ideaId))
            );

            for (const ideasKey in ideas) {
                const oneIdeaData = top10ReportData.data.filter(i => i.ideaId === ideas[ideasKey])

                const reducer = oneIdeaData.reduce((a, b) => ({
                    saving: a.saving + b.saving, ideaId: ideas[ideasKey],
                    ideaTitle: a.ideaTitle, ideaNumber: a.ideaNumber, actionOwner: a.actionOwner, location: a.location
                }));

                this.top10ReportData.push({
                    saving: [Math.round(reducer.saving * 10) / 10],
                    ideaId: reducer.ideaId,
                    ideaTitle: reducer.ideaTitle,
                    ideaNumber: reducer.ideaNumber,
                    actionOwner: reducer.actionOwner,
                    location: reducer.location
                })


            }


            const sortedTop10ReportData = this.top10ReportData.sort((a, b) => (a.saving[0] > b.saving[0]) ? 1 : ((b.saving[0] > a.saving[0]) ? -1 : 0))

            this.top10ChartData = sortedTop10ReportData.slice(Math.max(sortedTop10ReportData.length - 10, 0))
            const top10ChartData = toJS(this.top10ChartData)
            this.setLoadingInitial(false)

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    };

    loadCosUtilizationReportData = async () => {
        this.cosUtilizationChartData = []
        this.cosUtilizationReportData = []

        this.setLoadingInitial(true);
        try {
            const cosUtilizationReportData = await agent.Ideas.getCosUtilizationReportData(this.axiosParams);


            const utilization =
                (cosUtilizationReportData.actionControllers
                    + cosUtilizationReportData.actionOwners
                    + cosUtilizationReportData.vetters
                    + cosUtilizationReportData.taskOwners) / cosUtilizationReportData.users
            const outstanding = 1 - utilization

            this.cosUtilizationChartData.push({category: "Engaged", value: utilization})
            this.cosUtilizationChartData.push({category: "Outstanding", value: outstanding})

            this.setLoadingInitial(false)

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    };

    loadCosSummaryReportData = async () => {
        this.setLoadingInitial(true);
        try {
            this.cosSummaryByDepartmentChartData = []
            this.cosSummaryByAffiliateChartData = []

            const cosSummaryByDepartmentReportData = await agent.Ideas.getCosSummaryReportData(this.axiosParams);
            const departmentsAndAffiliates = await agent.PartyGroups.ListDepartmentsAndAffiliates();
            const departments = Array.from(
                new Set(departmentsAndAffiliates.map((p) => p.department))
            );
            const affiliates = Array.from(
                new Set(departmentsAndAffiliates.map((p) => p.affiliate))
            );

            for (const affiliatesKey in affiliates) {
                let oneAffiliateDataTotal: cosSummaryReportByAffiliate[] = cosSummaryByDepartmentReportData.data.filter(i =>
                    i.affiliate === affiliates[affiliatesKey])
                let reducer = oneAffiliateDataTotal.reduce((a, b) =>
                    ({
                        estimated: a.estimated + b.estimated,
                        actual: a.actual + b.actual,
                        affiliate: affiliates[affiliatesKey]
                    }));
                this.cosSummaryByAffiliateChartData.push({
                    estimated: Math.round(reducer.estimated * 10) / 10,
                    actual: Math.round(reducer.actual * 10) / 10, affiliate: reducer.affiliate
                })

                for (const deptKey in departments) {
                    let oneDepartmentDataTotal: cosSummaryReportByDepartment[] = cosSummaryByDepartmentReportData.data.filter(i =>
                        i.department === departments[deptKey] && i.affiliate === affiliates[affiliatesKey])
                    if (oneDepartmentDataTotal.length > 0) {
                        let reducer = oneDepartmentDataTotal.reduce((a, b) =>
                            ({
                                estimated: a.estimated + b.estimated,
                                actual: a.actual + b.actual,
                                department: departments[deptKey],
                                affiliate: affiliates[affiliatesKey]
                            }));
                        this.cosSummaryByDepartmentChartData.push({
                            estimated: Math.round(reducer.estimated * 10) / 10,
                            actual: Math.round(reducer.actual * 10) / 10,
                            department: reducer.department,
                            affiliate: reducer.affiliate
                        })

                    }
                }

            }
            const cosSummaryByDepartmentChartData = toJS(this.cosSummaryByDepartmentChartData)
            const cosSummaryByAffiliateChartData = toJS(this.cosSummaryByAffiliateChartData)
            this.setLoadingInitial(false)

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    };

    loadCosPotentialImpactReportData = async () => {
        this.setLoadingInitial(true);
        const ideaStatus = ['2', '3', '4', '5']
        let reducerCos2, reducerCos3, reducerCos4, reducerCos5
        try {
            this.cosPotentialImpactByDepartmentAndStatusChartData = []
            this.cosPotentialImpactByAffiliateAndStatusChartData = []

            const cosPotentialImpactReportData = await agent.Ideas.getCosPotentialImpactReportData(this.axiosParams);
            const departmentsAndAffiliates = await agent.PartyGroups.ListDepartmentsAndAffiliates();
            const departments = Array.from(
                new Set(departmentsAndAffiliates.map((p) => p.department))
            );
            const affiliates = Array.from(
                new Set(departmentsAndAffiliates.map((p) => p.affiliate))
            );

            for (const affiliatesKey in affiliates) {
                let oneAffiliateDataTotal: cosPotentialImpactReport[] = cosPotentialImpactReportData.data.filter(i =>
                    i.affiliate === affiliates[affiliatesKey])
                for (const ideaStatusKey in ideaStatus) {
                    const oneAffiliateOneStatusDataTotal: cosPotentialImpactReport[] =
                        oneAffiliateDataTotal.filter(i => i.ideaStatusId === ideaStatus[ideaStatusKey])

                    if (ideaStatus[ideaStatusKey] === '2') {

                        reducerCos2 = oneAffiliateOneStatusDataTotal.reduce((a, b) =>
                            ({
                                saving: a.saving + b.saving,
                                affiliate: affiliates[affiliatesKey],
                                ideaStatusId: '2',
                                department: ''
                            }));
                    }

                    if (ideaStatus[ideaStatusKey] === '3') {

                        reducerCos3 = oneAffiliateOneStatusDataTotal.reduce((a, b) =>
                            ({
                                saving: a.saving + b.saving,
                                affiliate: affiliates[affiliatesKey],
                                ideaStatusId: '3',
                                department: ''
                            }));
                    }

                    if (ideaStatus[ideaStatusKey] === '4') {

                        reducerCos4 = oneAffiliateOneStatusDataTotal.reduce((a, b) =>
                            ({
                                saving: a.saving + b.saving,
                                affiliate: affiliates[affiliatesKey],
                                ideaStatusId: '4',
                                department: ''
                            }));
                    }

                    if (ideaStatus[ideaStatusKey] === '5') {

                        reducerCos5 = oneAffiliateOneStatusDataTotal.reduce((a, b) =>
                            ({
                                saving: a.saving + b.saving,
                                affiliate: affiliates[affiliatesKey],
                                ideaStatusId: '5',
                                department: ''
                            }));
                    }

                }
                this.cosPotentialImpactByAffiliateAndStatusChartData.push({

                    cos2: (reducerCos2 != null ? Math.round(reducerCos2.saving * 10) / 10 : 0),
                    cos3: (reducerCos3 != null ? Math.round(reducerCos3.saving * 10) / 10 : 0),
                    cos4: (reducerCos4 != null ? Math.round(reducerCos4.saving * 10) / 10 : 0),
                    cos5: (reducerCos5 != null ? Math.round(reducerCos5.saving * 10) / 10 : 0),
                    affiliate: reducerCos2?.affiliate
                })

                for (const deptKey in departments) {
                    let oneDepartmentDataTotal: cosPotentialImpactReport[] = cosPotentialImpactReportData.data.filter(i =>
                        i.department === departments[deptKey] && i.affiliate === affiliates[affiliatesKey])
                    if (oneDepartmentDataTotal.length > 0) {

                        for (const ideaStatusKey in ideaStatus) {
                            const oneDepartmentOneStatusDataTotal: cosPotentialImpactReport[] =
                                oneDepartmentDataTotal.filter(i => i.ideaStatusId === ideaStatus[ideaStatusKey])

                            if (ideaStatus[ideaStatusKey] === '2') {

                                reducerCos2 = oneDepartmentOneStatusDataTotal.reduce((a, b) =>
                                    ({
                                        saving: a.saving + b.saving,
                                        affiliate: affiliates[affiliatesKey],
                                        ideaStatusId: '2',
                                        department: departments[deptKey]
                                    }));

                            }

                            if (ideaStatus[ideaStatusKey] === '3') {

                                reducerCos3 = oneDepartmentOneStatusDataTotal.reduce((a, b) =>
                                    ({
                                        saving: a.saving + b.saving,
                                        affiliate: affiliates[affiliatesKey],
                                        ideaStatusId: '3',
                                        department: departments[deptKey]
                                    }));
                            }

                            if (ideaStatus[ideaStatusKey] === '4') {

                                reducerCos4 = oneDepartmentOneStatusDataTotal.reduce((a, b) =>
                                    ({
                                        saving: a.saving + b.saving,
                                        affiliate: affiliates[affiliatesKey],
                                        ideaStatusId: '4',
                                        department: departments[deptKey]
                                    }));
                            }

                            if (ideaStatus[ideaStatusKey] === '5') {

                                reducerCos5 = oneDepartmentOneStatusDataTotal.reduce((a, b) =>
                                    ({
                                        saving: a.saving + b.saving,
                                        affiliate: affiliates[affiliatesKey],
                                        ideaStatusId: '5',
                                        department: departments[deptKey]
                                    }));

                            }

                        }

                        this.cosPotentialImpactByDepartmentAndStatusChartData.push({

                            cos2: (reducerCos2 != null ? Math.round(reducerCos2.saving * 10) / 10 : 0),
                            cos3: (reducerCos3 != null ? Math.round(reducerCos3.saving * 10) / 10 : 0),
                            cos4: (reducerCos4 != null ? Math.round(reducerCos4.saving * 10) / 10 : 0),
                            cos5: (reducerCos5 != null ? Math.round(reducerCos5.saving * 10) / 10 : 0),
                            affiliate: reducerCos2?.affiliate, department: reducerCos2?.department
                        })
                    }

                }

            }
            this.setLoadingInitial(false)

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    };

    loadVetterIdeas = async () => {
        console.log('loadVetterIdeas')
        this.setLoadingInitial(true);
        try {
            const result = await agent.Ideas.listVetterIdeas(this.axiosParams);
            this.vetterIdeaRegistry.clear()
            result.data.forEach(idea => {
                runInAction(() => {
                    idea.createdStamp = new Date(idea.createdStamp);
                    this.vetterIdeaRegistry.set(idea.ideaId, idea);
                });
            })
            console.log('this.vetterIdeaRegistry', this.vetterIdeaRegistry.size)
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
        this.resetDateParams()
    };

    loadActionOwnerIdeas = async () => {
        console.log('loadActionOwnerIdeas')
        this.setLoadingInitial(true);
        try {
            const result = await agent.Ideas.listActionOwnerIdeas(this.axiosParams);
            result.data.forEach(idea => {
                runInAction(() => {
                    idea.createdStamp = new Date(idea.createdStamp);
                    this.actionOwnerIdeaRegistry.set(idea.ideaId, idea);
                });
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);


        } catch (error) {
            runInAction(() => {
                this.setLoadingInitial(false);
            });
            console.log(error);
            toast.error('error');
        }
        this.resetDateParams()
    };

    loadActionControllerIdeas = async () => {
        console.log('loadActionControllerIdeas')
        this.setLoadingInitial(true);
        try {
            const result = await agent.Ideas.listActionControllerIdeas(this.axiosParams);
            result.data.forEach(idea => {
                runInAction(() => {
                    idea.createdStamp = new Date(idea.createdStamp);
                    this.actionControllerIdeaRegistry.set(idea.ideaId, idea);
                });
            })
            this.setPagination(result.pagination);
            this.setLoadingInitial(false);

        } catch (error) {
            this.setLoadingInitial(false);
            console.log(error);
            toast.error('error');
        }
    };

    updatePanes = (paneItem: any) => {
        this.panes.push(paneItem);
    }

    setPanes = (panes: any) => {
        this.panes = panes;
        console.log('setpanes', toJS(this.panes))
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    loadOriginatorIdea = async (ideaId: string) => {
        let idea = this.getOriginatorIdea(ideaId);
        if (idea) {
            runInAction(() => {
                this.selectedIdea = idea;
            });
            return idea;
        } else {
            this.setLoadingInitial(true);
            try {
                idea = await agent.Ideas.details(ideaId);
                runInAction(() => {
                    this.selectedIdea = idea;
                    this.originatorIdeaRegistry.set(idea!.ideaId, idea!);
                    this.setLoadingInitial(false);
                });

                return idea;
            } catch (error) {
                this.setLoadingInitial(false);
                console.log(error);
                toast.error('error');
            }
        }
        this.resetDateParams()
    };

    loadActionOwnerIdea = async (ideaId: string) => {
        let idea = this.getActionOwnerIdea(ideaId);
        if (idea) {
            runInAction(() => {
                this.selectedIdea = idea;
                this.loadFinancialImpact(idea!.financialImpacts)
                this.loadIdeaTracking(idea!.ideaTrackings)
                this.loadActionPlanTasks(idea!.actionPlanTasks)
                const partyGroupId = idea!.partyGroupId;
                this.loadActionControllers(partyGroupId)
                this.loadTaskOwners()
                this.ideaStatusAllowTasks = parseInt(idea!.ideaStatusId) > 1;
                this.ideaStatusAllowFormEditing = parseInt(idea!.ideaStatusId) < 2;
                this.ideaStatusAllowUpdatingTaskCompletedDate = parseInt(idea!.ideaStatusId) == 3;
                this.ideaStatusAllowUpdatingTaskFields = parseInt(idea!.ideaStatusId) < 4;
                this.createdStamp = new Date(idea!.createdStamp);
            });
            return idea;
        } else {
            this.setLoadingInitial(true);
            try {
                idea = await agent.Ideas.details(ideaId);
                runInAction(() => {
                    this.selectedIdea = idea;
                    this.actionOwnerIdeaRegistry.set(idea!.ideaId, idea!);
                    this.loadFinancialImpact(idea!.financialImpacts)
                    this.loadIdeaTracking(idea!.ideaTrackings)
                    this.loadActionPlanTasks(idea!.actionPlanTasks)
                    this.loadActionControllers(idea!.partyGroupId)
                    this.loadTaskOwners()
                    this.ideaStatusAllowTasks = parseInt(idea!.ideaStatusId) > 1;
                    this.ideaStatusAllowFormEditing = parseInt(idea!.ideaStatusId) < 2;
                    this.ideaStatusAllowUpdatingTaskCompletedDate = parseInt(idea!.ideaStatusId) == 3;
                    this.ideaStatusAllowUpdatingTaskFields = parseInt(idea!.ideaStatusId) < 4;
                    this.createdStamp = new Date(idea!.createdStamp);
                    this.setLoadingInitial(false);
                });

                return idea;
            } catch (error) {
                this.setLoadingInitial(false);
                console.log(error);
                toast.error('error');
            }
        }
    };

    refreshOtherRegisters = async (ideaId: string) => {
        const newIdea = await agent.Ideas.details(ideaId);
        newIdea.createdStamp = new Date(newIdea.createdStamp);
        let thisIdea = this.getOriginatorIdea(ideaId);
        if (thisIdea) {
            this.originatorIdeaRegistry.set(ideaId, newIdea);
        }

        thisIdea = this.getVetterIdea(ideaId);
        if (thisIdea) {
            this.vetterIdeaRegistry.set(ideaId, newIdea);
        }

        thisIdea = this.getActionOwnerIdea(ideaId);
        if (thisIdea) {
            this.actionOwnerIdeaRegistry.set(ideaId, newIdea);
        }

        thisIdea = this.getControllerIdea(ideaId);
        if (thisIdea) {
            this.actionControllerIdeaRegistry.set(ideaId, newIdea);
        }
    }

    loadActionControllerIdea = async (ideaId: string) => {
        let idea = this.getControllerIdea(ideaId);
        let anyTaskNotCompleted: boolean = false;
        let anyFinancialImpactNotRealised: boolean = false;
        if (idea) {
            runInAction(() => {
                this.selectedIdea = idea;
                this.loadFinancialImpact(idea!.financialImpacts)
                this.loadIdeaTracking(idea!.ideaTrackings)
                this.loadActionPlanTasks(idea!.actionPlanTasks)
                this.ideaStatusAllowFormEditing = parseInt(idea!.ideaStatusId) < 2;
                if (idea!.ideaStatusId === '1') {
                    this.ideaStatusNextStep = this.financialImpacts.length > 0 ? "Approve (Move to COS 2)" : "Approve";
                }

                if (idea!.ideaStatusId === '2') {

                    if (this.actionPlanTasksGrid.length > 0) {
                        this.ideaStatusNextStep = "Approve (Move to COS 3)";
                    } else {
                        this.ideaStatusNextStep = "Approve";
                    }
                }

                if (idea!.ideaStatusId === '3') {
                    this.actionPlanTasksGrid.forEach(item => {
                        if (item.completedDate == null) {
                            anyTaskNotCompleted = true;
                        }
                    })
                    if (!anyTaskNotCompleted && this.actionPlanTasksGrid.length > 0) {
                        this.ideaStatusNextStep = "Approve (Move to COS 4)";
                    } else {
                        this.ideaStatusNextStep = "Approve";
                    }
                }

                if (idea!.ideaStatusId === '4') {
                    this.financialImpacts.forEach(item => {
                        if (item.actual! < item.saving!) {
                            anyFinancialImpactNotRealised = true
                        }
                    })
                    if (!anyFinancialImpactNotRealised) {
                        this.ideaStatusNextStep = "Approve (Move to COS 5)";
                    } else {
                        this.ideaStatusNextStep = "Approve";
                    }
                }

            });
            return idea;
        } else {
            this.setLoadingInitial(true);
            try {
                idea = await agent.Ideas.details(ideaId);
                runInAction(() => {
                    this.selectedIdea = idea;
                    this.actionControllerIdeaRegistry.set(idea!.ideaId, idea!);
                    this.loadFinancialImpact(idea!.financialImpacts)
                    this.loadIdeaTracking(idea!.ideaTrackings)
                    this.loadActionPlanTasks(idea!.actionPlanTasks)
                    this.ideaStatusAllowFormEditing = parseInt(idea!.ideaStatusId) < 2;
                    this.ideaStatusNextStep = this.actionPlanTasksGrid.length > 0 ? "Approve (Move to COS3)" : "Approve";
                    this.setLoadingInitial(false);
                });

                return idea;
            } catch (error) {
                this.setLoadingInitial(false);
                console.log(error);
                toast.error('error');
            }
        }
    };

    resetDateParams = () => {
        let eDate: Date = new Date(new Date().setDate(new Date().getDate() + 1));
        let tempDate: Date = new Date();
        let sDate = new Date(tempDate.setMonth(tempDate.getMonth() - 1))
        this.setPredicate("startDate", sDate.toDateString());
        this.setPredicate("endDate", eDate.toDateString());

    }

    loadVetterIdea = async (ideaId: string) => {
        let idea = this.getVetterIdea(ideaId);
        if (idea) {
            runInAction(() => {
                this.selectedIdea = idea;
                const partyGroupId = idea!.partyGroupId;
                this.loadPossibleVetters(partyGroupId)
            });
            return idea;
        } else {
            this.setLoadingInitial(true);
            try {
                idea = await agent.Ideas.details(ideaId);
                runInAction(() => {
                    this.selectedIdea = idea;
                    this.vetterIdeaRegistry.set(idea!.ideaId, idea!);
                    this.loadPossibleVetters(idea!.partyGroupId)
                    this.setLoadingInitial(false);
                });

                return idea;
            } catch (error) {
                this.setLoadingInitial(false);
                console.log(error);
                toast.error('error');
            }
        }
    };

    updateIdeaAsOriginator = async (idea: CreateIdeaFormValues) => {
        try {
            this.setLoadingInitial(true);


            await agent.Ideas.updateIdeaAsOriginator(idea);

            runInAction(() => {
                this.refreshOtherRegisters(idea.ideaId!)
            })
            this.setLoadingInitial(false);
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }


    updateIdeaAsVetter = async (idea: CreateIdeaFormValues) => {
        try {
            await agent.Ideas.updateIdeaAsVetter(idea);
            runInAction(() => {
                this.refreshOtherRegisters(idea.ideaId!)
            })
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    updateIdeaAsActionOwner = async (idea: CreateIdeaFormValues) => {
        try {
            await agent.Ideas.updateIdeaAsActionOwner(idea);
            this.refreshOtherRegisters(idea.ideaId!)
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    updateIdeaAsActionController = async (idea: CreateIdeaFormValues) => {
        try {
            await agent.Ideas.updateIdeaAsActionController(idea);
            this.refreshOtherRegisters(idea.ideaId!)
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    adjustDate = (ideaId?: string) => {
        let ind = 1;
        this.financialImpacts.forEach(item => {
            const fImpact = new FinancialImpact()
            fImpact.ideaId = ideaId!

            var date = new Date(item.month!);
            var day = date.getDate();       // yields date
            var month = date.getMonth() + 1;    // yields month (add one as '.getMonth()' is zero indexed)
            var year = date.getFullYear();  // yields year
            var hour = date.getHours();     // yields hours 
            var minute = date.getMinutes(); // yields minutes
            var second = date.getSeconds(); // yields seconds

            // After this construct a string with the above results as below
            var time = month + "/" + day + "/" + year + " " + hour + ':' + minute + ':' + second;

            fImpact.impactMonth = new Date(time)
            fImpact.estimated = item.saving!
            fImpact.actual = item.actual!
            fImpact.id = ind;

            this.finImpacts.push(fImpact)
            ind += 1;
        })


        ind = 1;
    }

    sendForStatusUpdate = async (idea: CreateIdeaFormValues) => {
        this.finImpacts = [];
        let ind = 1;
        try {
            this.adjustDate(idea.ideaId)
            idea.financialImpacts = this.finImpacts;
            this.actionPlans = []
            this.actionPlanTasksGrid.forEach(item => {
                const task = new ActionPlanTask()
                task.id = ind
                task.ideaId = item.ideaId
                task.taskOwnerId = item.taskOwnerId
                task.contributors = item.contributors!
                task.description = item.description
                task.dueDate = item.dueDate
                task.completedDate = item.completedDate!

                this.actionPlans.push(task)
                ind += 1;
            })
            idea.actionPlanTasks = this.actionPlans

            await agent.Ideas.sendForStatusUpdate(idea);
            runInAction(() => {
                this.refreshOtherRegisters(idea.ideaId!)
            })
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    vetterRejectIdea = async (idea: CreateIdeaFormValues) => {
        try {
            await agent.Ideas.vetterRejectIdea(idea);
            runInAction(() => {
                this.refreshOtherRegisters(idea.ideaId!)
            })
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    vetterApproveIdea = async (idea: CreateIdeaFormValues) => {
        try {
            await agent.Ideas.vetterApproveIdea(idea);
            runInAction(() => {
                this.refreshOtherRegisters(idea.ideaId!)
            })
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    ideaAssignedToAnotherVetter = async (idea: CreateIdeaFormValues) => {
        try {
            await agent.Ideas.assignedIdeaToAnotherVetter(idea);
            this.refreshOtherRegisters(idea.ideaId!)
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');

        }
    }

    controllerRejectIdea = async (idea: CreateIdeaFormValues) => {
        try {
            this.adjustDate(idea.ideaId)
            await agent.Ideas.controllerRejectIdea(idea);
            this.refreshOtherRegisters(idea.ideaId!)
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    controllerApproveIdea = async (idea: CreateIdeaFormValues) => {
        try {
            this.finImpacts = [];
            this.adjustDate(idea.ideaId)
            idea.financialImpacts = this.finImpacts;
            await agent.Ideas.controllerApproveIdea(idea);
            runInAction(() => {
                this.refreshOtherRegisters(idea.ideaId!)
            })
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    controllerApproveIdeaToCos2 = async (idea: CreateIdeaFormValues) => {
        try {
            this.finImpacts = [];
            this.adjustDate(idea.ideaId)
            idea.financialImpacts = this.finImpacts;
            await agent.Ideas.controllerApproveIdeaToCos2(idea);
            runInAction(() => {
                this.refreshOtherRegisters(idea.ideaId!)
            })
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    controllerApproveIdeaToCos3 = async (idea: CreateIdeaFormValues) => {
        try {
            this.finImpacts = [];
            this.adjustDate(idea.ideaId)
            idea.financialImpacts = this.finImpacts;
            await agent.Ideas.controllerApproveIdeaToCos3(idea);
            runInAction(() => {
                this.refreshOtherRegisters(idea.ideaId!)
            })
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    controllerApproveIdeaToCos4 = async (idea: CreateIdeaFormValues) => {
        try {
            this.finImpacts = [];
            this.adjustDate(idea.ideaId)
            idea.financialImpacts = this.finImpacts;
            await agent.Ideas.controllerApproveIdeaToCos4(idea);
            runInAction(() => {
                this.refreshOtherRegisters(idea.ideaId!)
            })
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    controllerApproveIdeaToCos5 = async (idea: CreateIdeaFormValues) => {
        try {
            this.finImpacts = [];
            this.adjustDate(idea.ideaId)
            idea.financialImpacts = this.finImpacts;
            await agent.Ideas.controllerApproveIdeaToCos5(idea);
            runInAction(() => {
                this.refreshOtherRegisters(idea.ideaId!)
            })
            toast.success('Your modification has been saved successfully.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    getOriginatorIdea = (ideaId: string) => {
        return this.originatorIdeaRegistry.get(ideaId);
    };

    getVetterIdea = (ideaId: string) => {
        return this.vetterIdeaRegistry.get(ideaId);
    };

    getActionOwnerIdea = (ideaId: string) => {
        return this.actionOwnerIdeaRegistry.get(ideaId);
    };

    getControllerIdea = (ideaId: string) => {
        return this.actionControllerIdeaRegistry.get(ideaId);
    };

    createIdea = async (idea: CreateIdeaFormValues) => {
        try {
            await agent.Ideas.createIdea(idea);
            runInAction(() => {
                this.resetDateParams()
                this.loadOriginatorIdeas();
            })
            toast.success('Your idea has been submitted to Vetter for evaluation.')
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    };




    loadPossibleVetters = async (partyGroupId: string) => {
        try {
            const possibleVetters = await agent.Users.listPossibleVetters(partyGroupId);
            runInAction(() => {
                this.possibleVetters = possibleVetters;
            })
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    loadTaskOwners = async () => {
        try {
            const taskOwners = await agent.Users.listTaskOwners();
            runInAction(() => {
                this.taskOwners = taskOwners;
            })
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    loadActionControllers = async (partyGroupId: string) => {
        try {
            const actionControllers = await agent.Users.ListActionControllers(partyGroupId);
            runInAction(() => {
                this.actionControllers = actionControllers;
            })
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

}

/*
loadProgressionByValueReportData2 = async () => {
  this.setLoadingInitial(true);
  const today = new Date()
  const year = new Date().getFullYear()
  let ideas:any = [];

  const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const ideaStatus_names = ['COS 2', 'COS 3', 'COS 4', 'COS 5']
  try {
    this.progressionByValueChartData=[]
    this.progressionByValueChartDataWithTotals =[]
    this.progressionByValueActualsChartData = []
    const progressionByValueReportData = await agent.Ideas.getProgressionByValueReportData(this.axiosParams);
    const maxMonth = Math.max.apply(Math, progressionByValueReportData.data.map(function(o) { return o.month; }))
    for (let step = 1; step < maxMonth+1; step++) {

      const currentMonth = new Date().getMonth()

      if ( currentMonth >= step -1){
        const oneMonthActual = progressionByValueReportData.data.filter(i => i.month <= step && i.actual! > 0)
        let reducerActual = oneMonthActual.reduce((a, b) => ({actual: a.actual! + b.actual!, month: step, saving:0, d2EstimatedDate:today, d3EstimatedDate:today,
          d4EstimatedDate:today, d5EstimatedDate:today, createdStamp: today, ideaId: ''}));
        this.progressionByValueActualsChartData.push({actual : Math.round(reducerActual.actual! * 10) / 10, month : month_names_short[reducerActual.month - 1]})
      }

      const thisMonthData = progressionByValueReportData.data.filter(i => i.month <= step )

      const ideaIds = new Set();
      thisMonthData.filter(entry => {
        if (ideaIds.has(entry.ideaId)) {
          return false;
        }
        ideaIds.add(entry.ideaId);
        return true;
      });

      const thisMonthDate = new Date(year, step, 0);

      ideaIds.forEach((d:any) => {
        const thisMonthDataForOneIdea = thisMonthData.filter(i => i.ideaId === d)


        if (thisMonthDataForOneIdea[0].ideaStatus === '5') {

          let oneMonthDateConsecutive:progressionByValueReportData[] = progressionByValueReportData.data.filter(i => i.month <= step && i.ideaId === d)
          let reducer = oneMonthDateConsecutive.reduce((a, b) =>
              ({saving: a.saving + b.saving, month: step, d2EstimatedDate:today, d3EstimatedDate:today,
                d4EstimatedDate:today, d5EstimatedDate:today, createdStamp: today, ideaId: ''}));

          this.progressionByValueChartData.push({saving : Math.round(reducer.saving * 10) / 10, month : month_names_short[reducer.month - 1]
            , ideaStatusId : ideaStatus_names[3]})

          return;

        }

        if ( (new Date(thisMonthDataForOneIdea[0].d2EstimatedDate)) <= thisMonthDate && (new Date(thisMonthDataForOneIdea[0].d3EstimatedDate)) >= thisMonthDate) {
          let oneMonthDateConsecutive:progressionByValueReportData[] = progressionByValueReportData.data.filter(i => i.month <= step && i.ideaId === d)
          let reducer = oneMonthDateConsecutive.reduce((a, b) =>
              ({saving: a.saving + b.saving, month: step, d2EstimatedDate:today, d3EstimatedDate:today,
                d4EstimatedDate:today, d5EstimatedDate:today, createdStamp: today, ideaId: ''}));

          this.progressionByValueChartData.push({saving : Math.round(reducer.saving * 10) / 10, month : month_names_short[reducer.month - 1]
            , ideaStatusId : ideaStatus_names[0]})

          return;
        }

        if ( (new Date(thisMonthDataForOneIdea[0].d3EstimatedDate)) <= thisMonthDate && (new Date(thisMonthDataForOneIdea[0].d4EstimatedDate)) >= thisMonthDate) {
          let oneMonthDateConsecutive:progressionByValueReportData[] = progressionByValueReportData.data.filter(i => i.month <= step && i.ideaId === d)
          let reducer = oneMonthDateConsecutive.reduce((a, b) =>
              ({saving: a.saving + b.saving, month: step, d2EstimatedDate:today, d3EstimatedDate:today,
                d4EstimatedDate:today, d5EstimatedDate:today, createdStamp: today, ideaId: ''}));

          this.progressionByValueChartData.push({saving : Math.round(reducer.saving * 10) / 10, month : month_names_short[reducer.month - 1]
            , ideaStatusId : ideaStatus_names[1]})

          return;
        }

        if ( (new Date(thisMonthDataForOneIdea[0].d4EstimatedDate)) <= thisMonthDate && (new Date(thisMonthDataForOneIdea[0].d5EstimatedDate)) >= thisMonthDate) {
          let oneMonthDateConsecutive:progressionByValueReportData[] = progressionByValueReportData.data.filter(i => i.month <= step && i.ideaId === d)
          let reducer = oneMonthDateConsecutive.reduce((a, b) =>
              ({saving: a.saving + b.saving, month: step, d2EstimatedDate:today, d3EstimatedDate:today,
                d4EstimatedDate:today, d5EstimatedDate:today, createdStamp: today, ideaId: ''}));

          this.progressionByValueChartData.push({saving : Math.round(reducer.saving * 10) / 10, month : month_names_short[reducer.month - 1]
            , ideaStatusId : ideaStatus_names[2]})

          return;
        }

      })
    }


    await this.loadProgressionByNumberReportData() // for number of COI
    let reducerNumbers:any
    let coiNumber:string = ""
    let finalCoiNumber:string = ""
    month_names_short.forEach(month => {
      const oneMonth = this.progressionByValueChartData.filter(i => i.month === month)
      const oneMonthNumbers = this.progressionByNumberChartDataWithTotals.filter(i => i.month === month)
      const oneMonthNumbers1 = toJS(oneMonthNumbers)
      if (oneMonth.length > 0){
        if (oneMonthNumbers.length > 0){
          reducerNumbers = oneMonthNumbers.reduce((a, b) => ({count: a.count + b.count, month:'',ideaStatusId: ''}));
          coiNumber = '\n'+reducerNumbers.count.toString()
        }else {
          finalCoiNumber = coiNumber
        }

        let reducer = oneMonth.reduce((a, b) => ({saving: a.saving + b.saving, month:'',ideaStatusId: ''}));
        oneMonth.forEach(record => {
          this.progressionByValueChartDataWithTotals.push({saving: Math.round(record.saving * 10) / 10, month: record.month , ideaStatusId:record.ideaStatusId, total: Math.round(reducer.saving * 10) / 10 })
        })
      }


    })

    const currentMonth:number = new Date().getMonth()

    this.progressionByValueChartDataWithTotals.forEach(i => {
      const indexOfMonth = month_names_short.indexOf(i.month)
      if (indexOfMonth <= currentMonth) {
        i.month = i.month + finalCoiNumber
      }

    })

    const progressionByNumberChartDataWithTotals = toJS(this.progressionByNumberChartDataWithTotals)
    const progressionByValueChartDataWithTotals1 = toJS(this.progressionByValueActualsChartData)
    this.setLoadingInitial(false)

  } catch (error) {
    this.setLoadingInitial(false);
    console.log(error);toast.error('error');
  }
};*/
