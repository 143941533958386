import React, {useEffect, useMemo, useState} from 'react';
import {Button, Grid, Icon, Segment} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import FormInput from '../../../app/common/form/FormInput';
import {useStore} from '../../../app/stores/store';
import {useHistory} from 'react-router-dom';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {CreateAppUserFormValues} from "../../../app/models/appUser";
import {FormDropDownTree2} from "../../../app/common/form/FormDropDownTree2";
import {toJS} from "mobx";
import {MemoizedFormDropDownList} from "../../../app/common/form/MemoizedFormDropDownList";
import {Title} from "../../../app/models/titles";
import {CreatePartyGroupRoleFormValues} from "../../../app/models/partyGroupRole";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Grid as KendoGrid} from "@progress/kendo-react-grid/dist/npm/Grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {dateToValidator, emailValidator} from "../../ideas/form/Validators";
import {MemoizedFormComboBox} from "../../../app/common/form/FormComboBox";
import FormDatePicker from "../../../app/common/form/FormDatePicker";


const UsersForm = () => {
    const history = useHistory();
    const {ideaStore, adminStore} = useStore();
    const [sendForStatusChangeRoles, setSendForStatusChangeRoles] = React.useState(false);
    const [partyGroupRoleDialogVisible, setPartyGroupRoleDialogVisible] = React.useState(false);
    const [partyGroupRoleGridDialogVisible, setPartyGroupRoleGridDialogVisible] = React.useState(false);
    const [partyGroupRole, setPartyGroupRole] = useState<CreatePartyGroupRoleFormValues>(new CreatePartyGroupRoleFormValues());

    const toggleDialogPartyGroupRole = () => {
        if (partyGroupRoleDialogVisible) {
            setPartyGroupRole(new CreatePartyGroupRoleFormValues())
        }
        setPartyGroupRoleDialogVisible(!partyGroupRoleDialogVisible);
    };

    const toggleDialogPartyGroupRolesGrid = () => {
        setPartyGroupRoleGridDialogVisible(!partyGroupRoleGridDialogVisible);
    };

    const diRoleCell = (props: any) => {
        return (
            <td>
                <Button basic
                        onClick={() => loadRole(props.dataItem.diRoleId)}
                        color='blue'
                        size='mini'>
                    Edit
                </Button>
            </td>
        )
    }

    const loadRole = (diRoleId: string) => {
        const role = partyGroupRolesGrid.filter((item) => item.diRoleId == diRoleId)

        const selectedRole: CreatePartyGroupRoleFormValues = new CreatePartyGroupRoleFormValues(role[0].appUserId,
            role[0].diRoleId, role[0].partyGroupId, role[0].dateFrom, role[0].dateTo! , role[0].createdStamp, role[0].createdBy)

        setPartyGroupRole(selectedRole);
        toggleDialogPartyGroupRole()
        toggleDialogPartyGroupRolesGrid()
    }

    let titles: Title[] = [
        {"title": "Mr.", "titleDescription": "Mr."},
        {"title": "Mrs.", "titleDescription": "Mrs."},
        {"title": "Ms.", "titleDescription": "Ms."},
    ];

    const MyForm = React.useRef<any>()
    const RoleForm = React.useRef<any>()

    let {loadingInitial} = ideaStore;

    let {
        createAppUser,
        appUserLoaded,
        setAppUserLoaded,
        selectedAppUser,
        clearSelectedAppUser,
        editAppUser,diRoles,partyGroups,
        partyGroupRolesGrid, updateNewPartyGroupRole, updateExistingPartyGroupRole
    } = adminStore;

    let dropDownTreeData2 = toJS(partyGroups);
    let dropDownTreeData = useMemo(() => partyGroups ? toJS(partyGroups) : [], [partyGroups])
    diRoles = useMemo(() => diRoles ? toJS(diRoles) : [], [diRoles])

    const selectField = 'selected';
    const expandField = 'expanded';
    const dataItemKey = 'partyGroupId';
    const textField = 'groupName';
    const subItemsField = 'items';

    const [appUser, setAppUser] = useState<CreateAppUserFormValues>(new CreateAppUserFormValues());
    const [buttonFlag, setButtonFlag] = useState(false);

    useEffect(() => {
        setAppUser(new CreateAppUserFormValues(selectedAppUser));


    }, [selectedAppUser]);

    function searchTree(element: any, matchingPartyGroupId: any): any {
        if (element.partyGroupId === matchingPartyGroupId) {
            return element;
        } else if (element.items != null) {
            let i;
            let result = null;
            for (i = 0; result == null && i < element.items.length; i++) {
                result = searchTree(element.items[i], matchingPartyGroupId);
            }
            return result;
        }
        return null;
    }
    
    const handleRoleFormSubmit = (role: CreatePartyGroupRoleFormValues) => {
        if (role.appUserId === "") {
            const selectedRole  = diRoles.filter(i => i.diRoleId === role.diRoleId)
            role.diRoleDescription = selectedRole[0].diRoleDescription
            
            const selectedItem = searchTree(dropDownTreeData[0], role.partyGroupId)
            role.groupName = selectedItem.groupName
            role.appUserId = appUser.id!;
            updateNewPartyGroupRole(role);
        } else {
            updateExistingPartyGroupRole(role)
        }

        setPartyGroupRole(new CreatePartyGroupRoleFormValues())
        RoleForm.current.resetForm();
    }
    
    const cancelForm = () => {
        history.push(`/ideas/`);
    }
    const handleFormSubmit = (appUser: CreateAppUserFormValues) => {
        setButtonFlag(true)
        if (!appUserLoaded) {
            createAppUser(appUser);
        } else {
            setAppUserLoaded(false)
            editAppUser(appUser);
        }

    };

    const requiredValidator = (value: any, getter: any, fieldProps: any) =>
        value
            ? ''
            : fieldProps.name == 'title' ? "Error: This field Title is required."
                : fieldProps.name == 'displayName' ? "Error: This field Display Name is required."
                    : fieldProps.name == 'email' ? "Error: This field Email Address required."
                        : fieldProps.name == 'partyGroupId' ? "Error: This field Department required."
                            : "Error: This field is required.";

    if (loadingInitial) return <LoadingComponent content='Loading currency...'/>

    return (
        <>
            <Grid>
                <Grid.Column width={12}>
                    <Segment clearing>
                        <Form
                            initialValues={appUser}
                            ref={MyForm}
                            onSubmit={values => handleFormSubmit(values as CreateAppUserFormValues)}
                            key={appUser.email}
                            render={(formRenderProps) => (

                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Field
                                                        id={"title"}
                                                        name={"title"}
                                                        label={"Title *"}
                                                        component={MemoizedFormDropDownList}
                                                        dataItemKey={"title"}
                                                        textField={"titleDescription"}
                                                        data={titles}
                                                        validator={requiredValidator}
                                                        hint={'Hint: Select the title for this user.'}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={'displayName'}
                                                        name={'displayName'}
                                                        label={'Display Name *'}
                                                        component={FormInput}
                                                        autoComplete={"off"}
                                                        validator={requiredValidator}
                                                        hint={'Hint: Display Name.'}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={'email'}
                                                        name={'email'}
                                                        label={'Email Address *'}
                                                        component={FormInput}
                                                        autoComplete={"off"}
                                                        validator={emailValidator}
                                                        hint={'Hint: User Email Address.'}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={'partyGroupId'}
                                                        name={'partyGroupId'}
                                                        data={dropDownTreeData}
                                                        label={"User Department *"}
                                                        textField={textField}
                                                        dataItemKey={dataItemKey}
                                                        selectField={selectField}
                                                        expandField={expandField}
                                                        subItemsField={subItemsField}
                                                        component={FormDropDownTree2}
                                                        validator={requiredValidator}
                                                        hint={'Hint: Select location.'}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        
                                        {partyGroupRoleGridDialogVisible && <Dialog
                                            width={900} height={600}
                                            title={"User Roles"}
                                            onClose={() => {
                                                toggleDialogPartyGroupRolesGrid()
                                            }}>

                                            <KendoGrid data={partyGroupRolesGrid}>
                                                <Column field="appUserId" title="Edit" cell={diRoleCell} width="150px"/>
                                                <Column field="diRoleDescription" title="Role" width="200px"/>
                                                <Column field="groupName" title="Department" width="300px"/>
                                                <Column field="dateFrom" title="From" width="100px" format="{0: dd/MM/yyyy}"/>
                                                <Column field="dateTo" title="To" width="100px"
                                                        format={"{0:d}"}/>
                                            </KendoGrid>
                                            <DialogActionsBar>
                                                <Button
                                                    type='button' color='blue'
                                                    className="k-button"
                                                    icon labelPosition='left'
                                                    onClick={() => {
                                                        toggleDialogPartyGroupRolesGrid();
                                                        toggleDialogPartyGroupRole()
                                                    }}>Add Role
                                                    <Icon name='arrow left'/>
                                                </Button>
                                                <Button
                                                    color='blue'
                                                    className="k-button"
                                                    name={'SendForStatusChange'}
                                                    icon labelPosition='right'
                                                    onClick={(formRenderProps: any) => {
                                                        MyForm.current.onChange('hiddenField', {value: '1'});
                                                        //setSendForStatusChangeRoles(true);
                                                        formRenderProps.onSubmit = true;
                                                    }}
                                                >Save Changes
                                                    <Icon name='arrow right'/>
                                                </Button>

                                            </DialogActionsBar>
                                            {buttonFlag && <LoadingComponent content='Processing User...'/>}
                                        </Dialog>}

                                        <div className="k-form-buttons">
                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit || buttonFlag}
                                            >
                                                {appUserLoaded ? "Update User" : "Add New User"}
                                            </Button>
                                            <Button onClick={cancelForm}>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => {
                                                setAppUser(new CreateAppUserFormValues());
                                                clearSelectedAppUser();
                                                setAppUserLoaded(false);
                                            }
                                            }>
                                                Clear
                                            </Button>

                                            <Button type='button' icon labelPosition='left'
                                                    onClick={toggleDialogPartyGroupRolesGrid} color='green' floated={"right"}>
                                                <Icon name='clock outline'/>
                                                User Roles
                                            </Button>
                                        </div>

                                       

                                        {buttonFlag && loadingInitial &&
                                        <LoadingComponent content='Processing Rejection Reason...'/>}
                                    </fieldset>

                                </FormElement>

                            )}
                        />
                    </Segment>
                    {partyGroupRoleDialogVisible && <Dialog
                        width={500} height={500}
                        title={"Add Role"}
                        onClose={() => {
                            setPartyGroupRole(new CreatePartyGroupRoleFormValues());
                            toggleDialogPartyGroupRole()
                        }}
                    >

                        <Segment>
                            <Form
                                initialValues={partyGroupRole}
                                ref={RoleForm}
                                onSubmit={values => handleRoleFormSubmit(values as CreatePartyGroupRoleFormValues)}
                                key={partyGroupRole.appUserId}
                                render={(formRenderProps) => (
                                    <FormElement>
                                        <fieldset className={'k-form-fieldset'}>
                                            <Field
                                                id={'diRoleId'}
                                                name={'diRoleId'}
                                                data={diRoles}
                                                textField={"diRoleDescription"}
                                                label={'Role'}
                                                component={MemoizedFormComboBox}
                                                validator={requiredValidator}
                                                hint={'Hint: Role to be assigned to the user'}
                                            />

                                            <Field
                                                id={'partyGroupId'}
                                                name={'partyGroupId'}
                                                data={dropDownTreeData2}
                                                label={"Role Department *"}
                                                textField={textField}
                                                dataItemKey={dataItemKey}
                                                selectField={selectField}
                                                expandField={expandField}
                                                subItemsField={subItemsField}
                                                component={FormDropDownTree2}
                                                validator={requiredValidator}
                                                hint={'Hint: Select location.'}
                                            />
                                            <Field
                                                id={'dateFrom'}
                                                name={'dateFrom'}
                                                label={'From Date'}
                                                component={FormDatePicker}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                id={'dateTo'}
                                                name={'dateTo'}
                                                label={'To Date'}
                                                component={FormDatePicker}
                                                validator={dateToValidator}
                                            />

                                            <div className="k-form-buttons">
                                                <Button
                                                    primary={true}
                                                    type={'submit'}
                                                    disabled={!formRenderProps.allowSubmit || buttonFlag}
                                                >
                                                    Save
                                                </Button>
                                                <Button onClick={() => toggleDialogPartyGroupRole()}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            {buttonFlag && <LoadingComponent content='Processing Role...'/>}
                                        </fieldset>
                                    </FormElement>
                                )}
                            />
                        </Segment>
                        <DialogActionsBar>
                            <Button
                                type='button' color='blue'
                                className="k-button"
                                icon labelPosition='right'
                                onClick={() => {
                                    toggleDialogPartyGroupRole();
                                    toggleDialogPartyGroupRolesGrid()
                                }}>All Roles
                                <Icon name='arrow right'/>

                            </Button>

                        </DialogActionsBar>
                    </Dialog>}

                </Grid.Column>
            </Grid>

        </>


    );
}

export default observer(UsersForm);


