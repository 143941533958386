import {FinancialImpact} from './financialImpact'
import {IdeaTracking} from './ideaTracking'
import {ActionPlanTask} from "./actionPlanTask";


export interface Idea {
    ideaId: string;
    ideaNumber: number;
    savingCategoryId: number;
    rejectionReasonId: string;
    diTypeId: number;
    diTypeDescription: string,
    ideaStatusId: string;
    ideaStatusDescription: string;
    processStatusId: string;
    processStatusdescription: string;
    currentSituation: string;
    improvedSituation: string;
    estimatedAnnualSaving: number;
    currencyId: string;
    //currencyDescription: string,
    groupLiverId: number;
    groupLiverDescription: string;
    baseLineParameterId: number;
    baseLineParameterDescription: string;
    d2EstimatedDate: Date;
    d2ActualDate: Date;
    d3EstimatedDate: Date;
    d3ActualDate: Date;
    d4EstimatedDate: Date;
    d4ActualDate: Date;
    d5EstimatedDate: Date;
    d5ActualDate: Date;
    originatorName: string;
    actionOwnerId: string;
    actionControllerId: string;
    isRegisteredOnBehalfOnAnotherUser: boolean;
    originatorId: string;
    title: string;
    description: string;
    partyGroupId: string;
    groupName: string;
    lastUpdatedStamp: Date;
    createdStamp: Date;
    vetterRejectComment: string;
    vetterApproveComment: string;
    originatorShadowId: string;
    nonRegisteredOriginator: string;
    targetHierarchy: string;
    actionControllerComment: string;
    selected: boolean;
    hiddenField: string;
    financialImpacts?: FinancialImpact[];
    ideaTrackings?: IdeaTracking[];
    actionPlanTasks?: ActionPlanTask[];

}

export class Idea implements Idea {
    constructor(init?: CreateIdeaFormValues) {
        Object.assign(this, init);
    }
}

export class CreateIdeaFormValues {

    ideaId?: string = undefined;
    ideaNumber?: number = 0;
    diTypeId?: number = 0;
    isRegisteredOnBehalfOnAnotherUser?: boolean = false;
    originatorShadowId?: string | null = null;
    nonRegisteredOriginator?: string = '';
    diTypeDescription?: string = '';
    ideaStatusId?: string | null = null;
    rejectionReasonId?: string | null = null;
    processStatusId: string = '';
    currentSituation: string = '';
    improvedSituation: string = '';
    estimatedAnnualSaving: number = 0;
    currencyId: string = '';
    //currencyDescription: string = '';
    groupLiverId: number = 0;
    groupLiverDescription: string = '';
    savingCategoryId: number = 0;
    baseLineParameterId: number = 0;
    baseLineParameterDescription: string = '';
    d2EstimatedDate?: Date | null = null;
    d2ActualDate?: Date | null = null;
    d3EstimatedDate?: Date | null = null;
    d3ActualDate?: Date | null = null;
    d4EstimatedDate?: Date | null = null;
    d4ActualDate?: Date | null = null;
    d5EstimatedDate?: Date | null = null;
    d5ActualDate?: Date | null = null;
    originatorName: string = '';
    actionOwnerId?: string | null = null;
    actionControllerId?: string | null = null;
    vetterId?: string | null = null;
    originatorId?: string = '';
    title: string = '';
    description: string = '';
    partyGroupId: string = '';
    lastUpdatedStamp?: Date | null = null;
    createdStamp?: Date | null = null;
    sendEmailToOriginator?: boolean = true;
    emailTemplateToOriginator?: string = '';
    vetterRejectComment?: string = '';
    vetterApproveComment?: string = '';
    targetHierarchy: string | null = null;
    hiddenField: string | null = null;
    actionControllerComment?: string = '';

    financialImpacts?: FinancialImpact[];
    ideaTrackings?: IdeaTracking[];
    actionPlanTasks?: ActionPlanTask[];


    constructor(idea?: CreateIdeaFormValues) {
        if (idea) {
            this.ideaId = idea.ideaId;
            this.ideaNumber = idea.ideaNumber;
            this.diTypeId = idea.diTypeId;
            //this.diTypeDescription = idea.diTypeDescription;
            this.groupLiverId = idea.groupLiverId;
            //this.groupLiverDescription = idea.groupLiverDescription;
            this.baseLineParameterId = idea.baseLineParameterId;
            this.savingCategoryId = idea.savingCategoryId;
            this.ideaStatusId = idea.ideaStatusId;
            this.processStatusId = idea.processStatusId;
            this.currentSituation = idea.currentSituation;
            this.improvedSituation = idea.improvedSituation;
            this.estimatedAnnualSaving = idea.estimatedAnnualSaving;
            this.currencyId = idea.currencyId;
            //this.currencyDescription  = idea.currencyDescription;
            // @ts-ignore
            this.d2EstimatedDate = idea.d2EstimatedDate === null ? null : new Date(idea.d2EstimatedDate);
            // @ts-ignore
            this.d2ActualDate = idea.d2ActualDate === null ? null : new Date(idea.d2ActualDate);
            // @ts-ignore
            this.d3EstimatedDate = idea.d3EstimatedDate === null ? null : new Date(idea.d3EstimatedDate);
            // @ts-ignore
            this.d3ActualDate = idea.d3ActualDate === null ? null : new Date(idea.d3ActualDate);
            // @ts-ignore
            this.d4EstimatedDate = idea.d4EstimatedDate === null ? null : new Date(idea.d4EstimatedDate);
            // @ts-ignore
            this.d4ActualDate = idea.d4ActualDate === null ? null : new Date(idea.d4ActualDate);
            // @ts-ignore
            this.d5EstimatedDate = idea.d5EstimatedDate === null ? null : new Date(idea.d5EstimatedDate);
            // @ts-ignore
            this.d5ActualDate = idea.d5ActualDate === null ? null : new Date(idea.d5ActualDate);

            this.title = idea.title;
            this.description = idea.description;
            this.partyGroupId = idea.partyGroupId;
            this.rejectionReasonId = idea.rejectionReasonId;
            this.createdStamp = idea.createdStamp;
            this.lastUpdatedStamp = idea.lastUpdatedStamp;
            this.originatorId = idea.originatorId;
            this.vetterId = idea.vetterId;
            this.actionControllerId = idea.actionControllerId;
            this.actionOwnerId = idea.actionOwnerId;
            this.isRegisteredOnBehalfOnAnotherUser = idea.isRegisteredOnBehalfOnAnotherUser;
            this.vetterRejectComment = idea.vetterRejectComment;
            this.vetterApproveComment = idea.vetterApproveComment;
            this.isRegisteredOnBehalfOnAnotherUser = idea.isRegisteredOnBehalfOnAnotherUser;
            this.originatorShadowId = idea.originatorShadowId;
            this.nonRegisteredOriginator = idea.nonRegisteredOriginator;
            this.targetHierarchy = idea.targetHierarchy;
            this.actionControllerComment = idea.actionControllerComment;

        }
    }
}