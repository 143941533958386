import {observer} from 'mobx-react-lite';
import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Container, Header, Image, Segment} from 'semantic-ui-react';
import {useStore} from '../../app/stores/store';
import LoginForm from '../users/LoginForm';
import UserDetails from "../../app/layout/UserDetails";
import {Editor, ProseMirror} from "@progress/kendo-react-editor";
import content from "./content";
import mainLogo from './bklogo.png';
import {Checkbox} from "@progress/kendo-react-inputs";


export default observer(function HomePage() {
    const {userStore, modalStore, adminStore} = useStore();
    const {EditorState, EditorView, Plugin, PluginKey} = ProseMirror;
    const [editable, setEditable] = React.useState<boolean>(false);
    const [isActive, setIsActive] = React.useState<boolean>(false);
    const editableRef = React.useRef<boolean>(false);
    const view = React.useRef<any>(null);
    const MyHome = React.useRef<any>()
    console.log('Home Page')
    const {loadPartyGroups} = adminStore
    

    const onMount = (event: any) => {
        const state = event.viewProps.state;
        const plugins = [
            ...state.plugins,
            new Plugin({
                key: new PluginKey("readonly"),
                props: {editable: () => editableRef.current},
                filterTransaction: (tr, _st) => editableRef.current || !tr.docChanged,
            }),
        ];
        view.current = new EditorView(
            {mount: event.dom},
            {
                ...event.viewProps,
                state: EditorState.create({doc: state.doc, plugins}),
            }
        );

        return view.current;
    };

    React.useEffect(() => {

        if (view.current && editable) {
            view.current.updateState(view.current.state);
        }
    }, [editable]);

    const handleCheck = (event: any) => {
        setIsActive(event.value);
    }

    return (
        <Segment inverted textAlign='center' vertical className='masthead'>
            <Container text>
                <Header as='h1' inverted>
                    <Image size='massive' src={mainLogo} alt='logo' style={{marginBottom: 12}}/>
                    Cost Optimization System
                </Header>
                {userStore.isLoggedIn ? (
                    <>
                        <UserDetails/>
                        <Button as={Link} to='/ideas' size='huge' inverted>
                            Start!
                        </Button>
                    </>

                ) : (
                    <>
                        <Editor
                            contentStyle={{height: 450}}
                            onMount={onMount}
                            defaultContent={content}
                        />
                        <br/>
                        <Checkbox
                            id={"terms"}
                            name={"terms"}
                            label={"I agree with terms and conditions"}
                            onChange={(event) => handleCheck(event)}
                        />
                        <br/>
                        {isActive && <Button onClick={() => modalStore.openModal(<LoginForm/>)} size='huge' inverted>
                            Login!
                        </Button>}
                    </>

                )}
            </Container>
        </Segment>
    )
})