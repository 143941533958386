import {Redirect, Route, RouteComponentProps, RouteProps} from "react-router-dom";
import {useStore} from "../stores/store";

interface Props extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export default function PrivateRoute({component: Component, ...rest}: Props) {
    const {userStore: {isLoggedIn, setEmailUrl}} = useStore();
    console.log('Component', Component)
    console.log('rest', rest)

    return (
        
    <Route {...rest} render={props => {
        if (!isLoggedIn) {
            console.log('props.match.url', props.match.url)
            setEmailUrl(props.match.url)
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }

        // authorized so return component
        return <Component {...props} />
    }} />
        
    )
}

/*<Route
    {...rest}
    render={(props) => isLoggedIn ? <Component {...props} /> : <Redirect to='/'/>}
/>*/


