import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid, GridColumn as Column, GridPageChangeEvent, GridSortChangeEvent,} from "@progress/kendo-react-grid";

import {useStore} from '../../../app/stores/store';
import {Button} from "semantic-ui-react";
import {orderBy, SortDescriptor, State} from "@progress/kendo-data-query";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default observer(function CurrenciesList() {
    const {ideaStore, adminStore} = useStore();
    let {
        loadingInitial
    } = ideaStore;

    let {
        loadCurrencies, currencies, currenciesRegistry, loadCurrency,
        setCurrencyLoaded
    } = adminStore;


    const initialDataState: State = {skip: 0, take: 4};

    const [page, setPage] = React.useState<any>(initialDataState);

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };
    const initialSort: Array<SortDescriptor> = [
        {field: "createdStamp", dir: "desc"},
    ];
    const [sort, setSort] = React.useState(initialSort);


    useEffect(() => {
        if (currenciesRegistry.size < 1) loadCurrencies();
    }, [loadCurrencies])

    const setCurrency = (currencyId: number) => {
        loadCurrency(currencyId)
        setCurrencyLoaded(true)
    }

    const currencyIdCell = (props: any) => {
        return (
            <td

            ><Button basic as={Button}
                     onClick={() => setCurrency(props.dataItem.currencyId)}
                     color='blue'
                     size='mini'>
                {props.dataItem.currencyId}
            </Button>


            </td>
        )
    }

    if (loadingInitial) return <LoadingComponent content='Loading currencies...'/>
    return (

        <Fragment>

            <Grid className="main-grid"  style={{height: "400px"}}
                  data={orderBy(currencies, sort).slice(page.skip, page.take + page.skip)}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e: GridSortChangeEvent) => {
                      setSort(e.sort);
                  }}
                  skip={page.skip}
                  take={page.take}
                  total={currencies.length}
                  pageable={true}
                  onPageChange={pageChange}

            >

                <Column field="currencyId" title="Currency Id" cell={currencyIdCell} width={150}/>
                <Column field="currencyDescription" title="Currency Description" width={300}/>
                <Column field="exchangeRate" title="Exchange Rate" width={300}/>

            </Grid>

        </Fragment>
    )
})
