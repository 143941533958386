import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartTooltip,
    exportVisual
} from '@progress/kendo-react-charts';

import {useStore} from '../../../app/stores/store';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {Button, Header} from "semantic-ui-react";
import {exportSVG} from "@progress/kendo-drawing";
import {saveAs} from "@progress/kendo-file-saver";


export default observer(function SavingPerBusiness() {
    const {ideaStore} = useStore();
    let {
        loadSavingPerBusinessReportData, savePerBusinessChartData,
        loadingInitial
    } = ideaStore;

    const refContainer: any = React.useRef();

    const onExportVisual = () => {
        const chartVisual = exportVisual(refContainer.current);
        if (chartVisual) {
            exportSVG(chartVisual).then(dataURI => saveAs(dataURI, 'SavingPerBusiness.svg'));
        }
    };

    let todaysDate: Date = new Date();

    useEffect(() => {
        loadSavingPerBusinessReportData();
    }, [])

    const series = [
        {
            field: 'saving',
            categoryField: "partyGroup",
            savingType: 'Actual',
            stack: true,
            note: "difference",
            color: "green"
        },
        {
            field: 'saving',
            categoryField: "partyGroup",
            savingType: 'Estimate',
            stack: true,
            note: "difference",
            color: "blue"
        },

    ]
    const notesGreen: any = {
        label: {
            position: 'inside',
            color: 'white',
            background: 'green',
        },
        line: {
            length: 30
        },
        icon: {
            type: 'square'
        },
        position: 'top',
    };

    const notesRed: any = {
        label: {
            position: 'inside',
            color: 'white',
            background: 'red',
        },
        line: {
            length: 30
        },
        icon: {
            type: 'square'
        },
        position: 'top',
    };


    if (loadingInitial) return <LoadingComponent content='Generating report...'/>
    return (

        <Fragment>
            <Chart ref={refContainer}>
                <ChartTitle
                    text={todaysDate.toLocaleString()}
                    color="blue"
                    font="10pt sans-serif"
                />
                <ChartTooltip/>
                <ChartLegend position="bottom" orientation="horizontal"/>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem>
                        <ChartCategoryAxisTitle text="Affiliates"/>
                    </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartLegend position="top" orientation="horizontal"/>
                <ChartSeries>
                    {series.map((serie, index, series) => {
                        let seriesData = savePerBusinessChartData.filter(item => item.savingType === serie.savingType);
                        if (serie.savingType === 'Estimate') {

                            return <ChartSeriesItem type="column"
                                                    data={seriesData}
                                                    field={serie.field}
                                                    categoryField={serie.categoryField}
                                                    name={serie.savingType}
                                                    noteTextField={serie.note}
                                                    notes={notesRed}
                                                    color={serie.color}
                                                    labels={{visible: true, position: 'center'}}/>
                        } else {


                            return <ChartSeriesItem type="column"
                                                    data={seriesData}
                                                    field={serie.field}
                                                    categoryField={serie.categoryField}
                                                    name={serie.savingType}
                                                    noteTextField={serie.note}
                                                    notes={notesGreen}
                                                    color={serie.color}
                                                    labels={{visible: true, position: 'center'}}/>
                        }

                    })}
                </ChartSeries>
            </Chart>
            <Button onClick={() => onExportVisual()}>Export as visual element</Button>
            <Header as='h4' color='orange'>
                Report values are in Thousands Saudi Riyals
            </Header>
        </Fragment>
    );
})







