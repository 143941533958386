import React from 'react'
import {Button, Icon, Menu} from 'semantic-ui-react'
import {observer} from "mobx-react-lite";
import {NavLink} from "react-router-dom";

export default observer(function CreateIdeaMenu() {

    return (
        <Menu attached='top' tabular>
            <Button as={NavLink} to='/createIdea' color='twitter'
                    icon labelPosition='right'> Create COI
                <Icon name='idea'/>
            </Button>
            <Button as={NavLink} to='/reports' color='twitter'
                    icon labelPosition='right'> Reports
                <Icon name='chart line'/>
            </Button>
            {/*<Button as={NavLink} to='/admin' color='twitter'
                    icon labelPosition='right'> Admin
                <Icon name='chart line' />
            </Button>*/}
        </Menu>
    )
})