import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid, GridColumn as Column, GridPageChangeEvent, GridSortChangeEvent,} from "@progress/kendo-react-grid";

import {useStore} from '../../../app/stores/store';
import {Button} from "semantic-ui-react";
import {orderBy, SortDescriptor, State} from "@progress/kendo-data-query";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default observer(function CoiTypesList() {
    const {ideaStore, adminStore} = useStore();
    let {
        loadingInitial
    } = ideaStore;

    let {
        loadDiTypes, diTypes, diTypesRegistry, loadDiType,
        setDiTypeLoaded
    } = adminStore;


    const initialDataState: State = {skip: 0, take: 4};

    const [page, setPage] = React.useState<any>(initialDataState);

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };
    const initialSort: Array<SortDescriptor> = [
        {field: "diTypeId", dir: "asc"},
    ];
    const [sort, setSort] = React.useState(initialSort);


    useEffect(() => {
        if (diTypesRegistry.size < 1) loadDiTypes();
    }, [loadDiTypes])

    const setDiType = (diTypeId: number) => {
        loadDiType(diTypeId)
        setDiTypeLoaded(true)
    }

    const diTypeIdCell = (props: any) => {
        return (
            <td

            ><Button basic as={Button}
                     onClick={() => setDiType(props.dataItem.diTypeId)}
                     color='blue'
                     size='mini'>
                {props.dataItem.diTypeId}
            </Button>


            </td>
        )
    }

    if (loadingInitial) return <LoadingComponent content='Loading coi types...'/>
    return (

        <Fragment>

            <Grid className="main-grid"  style={{height: "400px"}}
                  data={orderBy(diTypes, sort).slice(page.skip, page.take + page.skip)}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e: GridSortChangeEvent) => {
                      setSort(e.sort);
                  }}
                  skip={page.skip}
                  take={page.take}
                  total={diTypes.length}
                  pageable={true}
                  onPageChange={pageChange}

            >

                <Column field="diTypeId" title="COI Type Id" cell={diTypeIdCell} width={200}/>
                <Column field="diTypeDescription" title="COI Type Description" width={300}/>

            </Grid>

        </Fragment>
    )
})
