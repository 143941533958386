export interface RejectionReason {
    rejectionReasonId: number;
    rejectionReasonDescription: string;
    createdStamp: string
    createdBy: string
}

export class CreateRejectionReasonFormValues {

    rejectionReasonId: number = 0;
    rejectionReasonDescription: string = '';
    createdStamp: string = '';
    createdBy: string = ''


    constructor(rejectionReason?: CreateRejectionReasonFormValues) {
        if (rejectionReason) {
            this.rejectionReasonId = rejectionReason.rejectionReasonId;
            this.rejectionReasonDescription = rejectionReason.rejectionReasonDescription
            this.createdStamp = rejectionReason.createdStamp
            this.createdBy = rejectionReason.createdBy
        }
    }
}
