export interface GroupLiver {
    groupLiverId: number;
    groupLiverDescription: string;
    DiTypeDescription: string;
    diTypeId: number;
    createdStamp: string
    createdBy: string
}

export class CreateGroupLiverFormValues {

    groupLiverId: number = 0;
    diTypeId: number = 0;
    groupLiverDescription: string = '';
    createdStamp: string = '';
    createdBy: string = ''

    constructor(groupLiver?: CreateGroupLiverFormValues) {
        if (groupLiver) {
            this.groupLiverId = groupLiver.groupLiverId
            this.groupLiverDescription = groupLiver.groupLiverDescription
            this.diTypeId = groupLiver.diTypeId
            this.createdStamp = groupLiver.createdStamp
            this.createdBy = groupLiver.createdBy
        }
    }
}

