import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid, GridColumn as Column, GridPageChangeEvent, GridSortChangeEvent,} from "@progress/kendo-react-grid";

import {useStore} from '../../../app/stores/store';
import {Button} from "semantic-ui-react";
import {orderBy, SortDescriptor, State} from "@progress/kendo-data-query";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default observer(function RejectionReasonsList() {
    const {ideaStore, adminStore} = useStore();
    let {
        loadingInitial
    } = ideaStore;

    let {
        loadRejectionReasons, rejectionReasons, rejectionReasonsRegistry, loadRejectionReason,
        setRejectionReasonLoaded
    } = adminStore;


    const initialDataState: State = {skip: 0, take: 4};

    const [page, setPage] = React.useState<any>(initialDataState);

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };
    const initialSort: Array<SortDescriptor> = [
        {field: "rejectionReasonId", dir: "asc"},
    ];
    const [sort, setSort] = React.useState(initialSort);


    useEffect(() => {
        if (rejectionReasonsRegistry.size < 1) loadRejectionReasons();
    }, [loadRejectionReasons])

    const setRejectionReason = (rejectionReasonId: number) => {
        loadRejectionReason(rejectionReasonId)
        setRejectionReasonLoaded(true)
    }

    const rejectionReasonIdCell = (props: any) => {
        return (
            <td

            ><Button basic as={Button}
                     onClick={() => setRejectionReason(props.dataItem.rejectionReasonId)}
                     color='blue'
                     size='mini'>
                {props.dataItem.rejectionReasonId}
            </Button>


            </td>
        )
    }

    if (loadingInitial) return <LoadingComponent content='Loading Rejection Reasons...'/>
    return (

        <Fragment>

            <Grid className="main-grid"  style={{height: "400px"}}
                  data={orderBy(rejectionReasons, sort).slice(page.skip, page.take + page.skip)}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e: GridSortChangeEvent) => {
                      setSort(e.sort);
                  }}
                  skip={page.skip}
                  take={page.take}
                  total={rejectionReasons.length}
                  pageable={true}
                  onPageChange={pageChange}

            >

                <Column field="rejectionReasonId" title="Rejection Reason Id" cell={rejectionReasonIdCell} width={150}/>
                <Column field="rejectionReasonDescription" title="Rejection Reason Description" width={300}/>

            </Grid>

        </Fragment>
    )
})
