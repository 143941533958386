import React, {useEffect, useMemo, useState} from 'react';
import {Button, Grid, Segment} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import FormInput from '../../../app/common/form/FormInput';
import {useStore} from '../../../app/stores/store';
import {useHistory} from 'react-router-dom';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {toJS} from "mobx";
import {MemoizedFormDropDownList} from "../../../app/common/form/MemoizedFormDropDownList";
import {CreateGroupLiverFormValues} from "../../../app/models/groupLiver";


const GroupLiversForm = () => {
    const history = useHistory();
    const { adminStore } = useStore();


    const MyForm = React.useRef<any>()
    

    let {
        createGroupLiver,
        groupLiverLoaded,
        setGroupLiverLoaded,
        selectedGroupLiver,
        clearSelectedGroupLiver,
        editGroupLiver, loadingInitial, diTypes
    } = adminStore;
    let diTypeData = useMemo(() => diTypes ? toJS(diTypes) : [], [diTypes])


    const [groupLiver, setGroupLiver] = useState<CreateGroupLiverFormValues>(new CreateGroupLiverFormValues());
    const [buttonFlag, setButtonFlag] = useState(false);

    useEffect(() => {
        setGroupLiver(new CreateGroupLiverFormValues(selectedGroupLiver));


    }, [selectedGroupLiver]);


    const cancelForm = () => {
        history.push(`/ideas/`);
    }
    
    const clearForm = () => {
        setGroupLiver(new CreateGroupLiverFormValues());
        clearSelectedGroupLiver();
        setGroupLiverLoaded(false);
    }
    
    const handleFormSubmit = (groupLiver: CreateGroupLiverFormValues) => {
        setButtonFlag(true)
        if (!groupLiverLoaded) {
            createGroupLiver(groupLiver);
        } else {
            setGroupLiverLoaded(false)
            editGroupLiver(groupLiver);
        }
        clearForm()
    };

    const requiredValidator = (value: any, getter: any, fieldProps: any) =>
        value
            ? ''
            : fieldProps.name == 'groupLiverId' ? "Error: This field Group Liver Id is required."
                : fieldProps.name == 'groupLiverDescription' ? "Error: This field Rejection Reason Description is required."
                    : fieldProps.name == 'diTypeId' ? "Error: This field COI Type is required."
                        : "Error: This field is required.";

    if (loadingInitial) return <LoadingComponent content='Loading Group Liver...'/>

    return (
        <>
            <Grid>
                <Grid.Column width={12}>
                    <Segment clearing>
                        <Form
                            initialValues={groupLiver}
                            ref={MyForm}
                            onSubmit={values => handleFormSubmit(values as CreateGroupLiverFormValues)}
                            key={groupLiver.groupLiverId}
                            render={(formRenderProps) => (

                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                {/*<Grid.Column>
                                                    <Field
                                                        id={'groupLiverId'}
                                                        name={'groupLiverId'}
                                                        label={'Group Liver Id*'}
                                                        component={FormInput}
                                                        autoComplete={"off"}
                                                        validator={requiredValidator}
                                                        disabled={groupLiverLoaded}
                                                        hint={'Hint: Group Liver.'}
                                                    />
                                                </Grid.Column>*/}
                                                <Grid.Column>
                                                    <Field
                                                        id={'groupLiverDescription'}
                                                        name={'groupLiverDescription'}
                                                        label={'Group Liver Description *'}
                                                        component={FormInput}
                                                        autoComplete={"off"}
                                                        validator={requiredValidator}
                                                        hint={'Hint: Group Liver Description.'}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={'diTypeId'}
                                                        name={'diTypeId'}
                                                        textField={"diTypeDescription"}
                                                        label={'COI Type *'}
                                                        data={diTypeData}
                                                        component={MemoizedFormDropDownList}
                                                        validator={requiredValidator}
                                                        disabled={groupLiverLoaded}
                                                        hint={'Hint: Select your Cost Optimization Initiative Type.'}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>


                                        <div className="k-form-buttons">
                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {groupLiverLoaded ? "Update Group Liver" : "Add New Group Liver"}
                                            </Button>
                                            <Button onClick={cancelForm}>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => {
                                                clearForm()
                                            }
                                            }>
                                                Clear
                                            </Button>
                                        </div>

                                        {buttonFlag && loadingInitial &&
                                        <LoadingComponent content='Processing Group Liver...'/>}
                                    </fieldset>

                                </FormElement>

                            )}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>

        </>


    );
}

export default observer(GroupLiversForm);


