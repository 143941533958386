import React, {useEffect} from "react";
import {Container} from "semantic-ui-react";
import NavBar from "./NavBar";
import {observer} from "mobx-react-lite";
import {Route, Switch, useLocation} from 'react-router-dom';
import HomePage from "../../features/home/HomePage";
import TestErrors from '../../features/errors/TestError';
import NotFound from '../../features/errors/NotFound';
import {ToastContainer} from "react-toastify";
import LoadingComponent from "./LoadingComponent";
import ModalContainer from "../common/modals/ModalContainer";
import CreateIdeaForm from "../../features/ideas/form/CreateIdeaForm";
import ServerError from '../../features/errors/ServerError';
import LoginForm from '../../features/users/LoginForm';
import {useStore} from '../stores/store';
import IdeaDashboard from "../../features/ideas/dashboard/IdeaDashboard";
import VetIdeaForm from "../../features/ideas/form/VetIdeaForm";
import ActionOwnerForm from "../../features/ideas/form/ActionOwnerForm";
import ActionControllerForm from "../../features/ideas/form/ActionControllerForm";
import ReportsDashboard from "../../features/ideas/dashboard/ReportsDashboard";
import PrivateRoute from "./PrivateRoute";
import AdminsDashboard from "../../features/admin/dashboard/AdminsDashboard";
import {store} from "../stores/store";


function App() {
    const location = useLocation();
    const {commonStore, userStore, adminStore} = useStore();
    console.log('App Component')
    useEffect(() => {
        if (commonStore.token) {
            userStore.getUser().finally(() => commonStore.setAppLoaded());
            store.adminStore.loadPartyGroups()
        } else {
            commonStore.setAppLoaded();
        }
    }, [commonStore, userStore])

    if (!commonStore.appLoaded) return <LoadingComponent content='Loading app...'/>


    return (
        <>
            <ModalContainer/>
            <ToastContainer position="bottom-right"/>
            <Route exact path="/" component={HomePage}/>
            <Route
                path={'/(.+)'}
                render={() => (
                    <>
                        <NavBar/>
                        <Container style={{marginTop: '6em'}}
                        >
                            <Switch>
                                <PrivateRoute exact path="/ideas" component={IdeaDashboard}/>
                                <PrivateRoute exact path="/reports" component={ReportsDashboard}/>
                                <PrivateRoute exact path="/vetter/:ideaId" component={VetIdeaForm}/>
                                <PrivateRoute exact path="/admin" component={AdminsDashboard}/>
                                <PrivateRoute exact path="/actionOwner/:ideaId" component={ActionOwnerForm}/>
                                <PrivateRoute exact path="/actionController/:ideaId" component={ActionControllerForm}/>
                                <PrivateRoute key={location.key} path={['/createidea', '/manage/:ideaId']}
                                              component={CreateIdeaForm}/>

                                <PrivateRoute path='/errors' component={TestErrors}/>
                                <Route path='/server-error' component={ServerError}/>
                                <Route path='/login' component={LoginForm}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </Container>
                    </>
                )}
            />
        </>
    );
}

export default observer(App);

