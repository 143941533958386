export interface SavingCategory {
    savingCategoryId: number;
    savingCategoryDescription: string;
    groupLiverDescription: string;
    DiTypeDescription: string;
    baseLineParameterId: number;
    groupLiverId: number;
    diTypeId: number;
    createdStamp: string
    createdBy: string
}

export class CreateSavingCategoryFormValues {

    savingCategoryId: number = 0;
    savingCategoryDescription: string = '';
    baseLineParameterId: number = 0;
    groupLiverId: number = 0;
    diTypeId: number = 0;
    createdStamp: string = '';
    createdBy: string = ''

    constructor(savingCategory?: CreateSavingCategoryFormValues) {
        if (savingCategory) {
            this.savingCategoryId = savingCategory.savingCategoryId;
            this.savingCategoryDescription = savingCategory.savingCategoryDescription
            this.baseLineParameterId = savingCategory.baseLineParameterId
            this.groupLiverId = savingCategory.groupLiverId
            this.diTypeId = savingCategory.diTypeId
            this.createdStamp = savingCategory.createdStamp
            this.createdBy = savingCategory.createdBy
        }
    }
}
