import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid, GridColumn as Column, GridPageChangeEvent, GridSortChangeEvent,} from "@progress/kendo-react-grid";

import {useStore} from '../../../app/stores/store';
import {Button} from "semantic-ui-react";
import {orderBy, SortDescriptor, State} from "@progress/kendo-data-query";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default observer(function UsersList() {
    const {ideaStore, adminStore} = useStore();
    let {
        loadingInitial
    } = ideaStore;

    let {
        loadAppUsers, appUsers, appUsersRegistry, loadAppUser,
        setAppUserLoaded
    } = adminStore;


    const initialDataState: State = {skip: 0, take: 4};

    const [page, setPage] = React.useState<any>(initialDataState);

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };
    const initialSort: Array<SortDescriptor> = [
        {field: "createdStamp", dir: "desc"},
    ];
    const [sort, setSort] = React.useState(initialSort);


    useEffect(() => {
        if (appUsersRegistry.size < 1) loadAppUsers();
    }, [loadAppUsers])

    const setAppUser = (appUserId: string) => {
        loadAppUser(appUserId)
        setAppUserLoaded(true)
    }

    const appUserEmailCell = (props: any) => {
        return (
            <td

            ><Button basic as={Button}
                     onClick={() => setAppUser(props.dataItem.id)}
                     color='blue'
                     size='mini'>
                {props.dataItem.email}
            </Button>


            </td>
        )
    }

    if (loadingInitial) return <LoadingComponent content='Loading users...'/>
    return (

        <Fragment>

            <Grid className="main-grid" style={{height: "400px"}}
                  data={orderBy(appUsers, sort).slice(page.skip, page.take + page.skip)}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e: GridSortChangeEvent) => {
                      setSort(e.sort);
                  }}
                  skip={page.skip}
                  take={page.take}
                  total={appUsers.length}
                  pageable={true}
                  onPageChange={pageChange}

            >

                <Column field="email" title="Email" cell={appUserEmailCell} width={200}/>
                <Column field="displayName" title="Display Name" width={300}/>
                <Column field="partyGroupDescription" title="Department" width={300}/>
                <Column field="title" title="Title" width={300}/>
                <Column field="id" title="id" width={0}/>

            </Grid>

        </Fragment>
    )
})
