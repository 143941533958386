export interface PartyGroupRole {
    appUserId: string;
    diRoleId: string;
    diRoleDescription: string;
    partyGroupId: string;
    groupName: string;
    dateFrom: Date;
    dateTo: Date;
    createdStamp: string;
    createdBy: string;
}


export class PartyGroupRole implements PartyGroupRole {
    constructor(init?: CreatePartyGroupRoleFormValues) {
        Object.assign(this, init);
    }
}

export class CreatePartyGroupRoleFormValues {
    appUserId: string | undefined = '';
    diRoleId: string | undefined = '';
    partyGroupId: string | undefined = '';
    dateFrom: Date = new Date();
    dateTo: Date | null = null;
    createdStamp: string | undefined = '';
    createdBy:  string | undefined = '';
    diRoleDescription: string | undefined = '';
    groupName: string | undefined = '';

    constructor(appUserId?: string, diRoleId?: string, partyGroupId?: string,
                dateFrom?: Date, dateTo?: Date, createdStamp?: string, createdBy?: string) {
        if (appUserId) {
            this.appUserId = appUserId;
            this.diRoleId = diRoleId;
            this.partyGroupId = partyGroupId;
            // @ts-ignore
            this.dateFrom = dateFrom;
            // @ts-ignore
            this.dateTo = dateTo;
            this.createdStamp = createdStamp!;
            this.createdBy = createdBy;
        }
    }
}


export class PartyGroupRoleGrid {
    appUserId: string;
    diRoleId: string;
    partyGroupId: string;
    dateFrom: Date ;
    dateTo: Date| null;
    diRoleDescription: string;
    groupName: string;
    createdStamp: string;
    createdBy: string;


    constructor(appUserId: string, diRoleId: string | undefined, partyGroupId: string | undefined,
                dateFrom: Date | null, dateTo: Date | null, diRoleDescription: string | undefined, groupName: string | undefined,  createdStamp?: string, createdBy?: string) {
        this.appUserId = appUserId;
        this.diRoleId = diRoleId!;
        this.partyGroupId = partyGroupId!;
        this.dateFrom = dateFrom!;
        this.dateTo = dateTo!;
        this.diRoleDescription = diRoleDescription!;
        this.groupName = groupName!;
        this.createdStamp = createdStamp!;
        this.createdBy = createdBy!;
    }
}