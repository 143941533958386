import {useStore} from "../stores/store";
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Header} from "semantic-ui-react";

function UserDetails() {
    const {userStore, adminStore} = useStore();

    let {loadUserDepartment, userDepartment, user} = userStore;
    console.log('From UserDetails');

    useEffect(() => {

        loadUserDepartment()
    }, [loadUserDepartment])

    return (
        <>
            <Header as='h2' inverted content={`Welcome ${user?.displayName} ,${userDepartment} `}/>
        </>
    );
}

export default observer(UserDetails);
