import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesItemTooltip,
    ChartTitle,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
    exportVisual
} from '@progress/kendo-react-charts';

import {useStore} from '../../../app/stores/store';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {Button, Header} from "semantic-ui-react";
import {exportSVG} from "@progress/kendo-drawing";
import {saveAs} from "@progress/kendo-file-saver";


export default observer(function CosTop10Chart() {
    const {ideaStore} = useStore();
    let {
        loadCosTop10ReportData, top10ChartData,
        loadingInitial
    } = ideaStore;

    const refContainer: any = React.useRef();
    let categories: any = [];
    let saving: any = [];
    let titles: any = [];
    const onExportVisual = () => {
        const chartVisual = exportVisual(refContainer.current);
        if (chartVisual) {
            exportSVG(chartVisual).then(dataURI => saveAs(dataURI, 'top10ChartData.svg'));
        }
    };

    let todaysDate: Date = new Date();

    const series = [
        {field: 'saving', categoryField: 'ideaNumber', key: 'ideaId', stack: true, note: "coi", color: "gray"},
    ]

    useEffect(() => {
        loadCosTop10ReportData();


    }, [])

    const seriesLabelsBars: any = {
        visible: true, // Note that visible defaults to false
        padding: 3,
        font: "bold 16px Arial, sans-serif",
        color: 'blue',
        position: 'outsideEnd'
    };

    top10ChartData.map((serie, index, series) => {
        saving.push(serie.saving);
        categories.push(serie.ideaNumber)
        titles.push(serie.ideaTitle)
    })

    const notes: any = {
        label: {
            position: 'inside',
            color: 'black',
            background: 'yellow',
        },
        line: {
            length: 30
        },
        position: 'top',
    };

    const seriesLabels = {
        visible: true, // Note that visible defaults to false
        padding: 3,
        font: "bold 16px Arial, sans-serif",
    };
    const tooltipRender = (description: string, ideaNumber: number, props: any) => (
        <span>
    <b>
      Idea Number: {ideaNumber} <br/> description: {description}
    </b>
  </span>
    );


    if (loadingInitial) return <LoadingComponent content='Generating report...'/>
    return (

        <Fragment>
            <Chart ref={refContainer}>
                <ChartTitle
                    text={todaysDate.toLocaleString()}
                    color="blue"
                    font="10pt sans-serif"
                />
                <ChartTooltip/>
                <ChartLegend position="bottom" orientation="horizontal"/>
                <ChartValueAxis>
                    <ChartValueAxisItem title={{text: "Saving"}}/>
                </ChartValueAxis>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem>
                        <ChartCategoryAxisTitle text="Top 10 COIs"/>
                    </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartSeries>
                    {top10ChartData.map((serie, index, series) => {
                        return <ChartSeriesItem type="column"
                                                key={serie.ideaId}
                                                data={serie.saving}
                                                color={'aqua'}
                                                labels={seriesLabelsBars}
                        >

                            <ChartSeriesItemTooltip
                                render={tooltipRender.bind(undefined, serie.ideaTitle, serie.ideaNumber)}
                                background={"white"}
                            />
                        </ChartSeriesItem>

                    })}

                </ChartSeries>
            </Chart>
            <Button onClick={() => onExportVisual()}>Export as visual element</Button>
            <Header as='h4' color='orange'>
                Report values are in Thousands Saudi Riyals
            </Header>
        </Fragment>
    );
})