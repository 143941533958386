import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid, GridColumn as Column, GridPageChangeEvent, GridSortChangeEvent,} from "@progress/kendo-react-grid";

import {useStore} from '../../../app/stores/store';
import {Button} from "semantic-ui-react";
import {orderBy, SortDescriptor, State} from "@progress/kendo-data-query";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default observer(function BaseLineParametersList() {
    const {ideaStore, adminStore} = useStore();
    let {
        loadingInitial
    } = ideaStore;

    let {
        loadBaseLineParameters, baseLineParameters, baseLineParametersRegistry, loadBaseLineParameter,
        setBaseLineParameterLoaded
    } = adminStore;


    const initialDataState: State = {skip: 0, take: 4};

    const [page, setPage] = React.useState<any>(initialDataState);

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };
    const initialSort: Array<SortDescriptor> = [
        {field: "baseLineParameterId", dir: "asc"},
    ];
    const [sort, setSort] = React.useState(initialSort);


    useEffect(() => {
        if (baseLineParametersRegistry.size < 1) loadBaseLineParameters();
    }, [loadBaseLineParameters])

    const setBaseLineParameter = (baseLineParameterId: number) => {
        loadBaseLineParameter(baseLineParameterId)
        setBaseLineParameterLoaded(true)
    }

    const baseLineParameterIdCell = (props: any) => {
        return (
            <td

            ><Button basic as={Button}
                     onClick={() => setBaseLineParameter(props.dataItem.baseLineParameterId)}
                     color='blue'
                     size='mini'>
                {props.dataItem.baseLineParameterId}
            </Button>


            </td>
        )
    }

    if (loadingInitial) return <LoadingComponent content='Loading baseline parameters...'/>
    return (

        <Fragment>

            <Grid className="main-grid"  style={{height: "400px"}}
                  data={orderBy(baseLineParameters, sort).slice(page.skip, page.take + page.skip)}
                  sortable={true}
                  sort={sort}
                  onSortChange={(e: GridSortChangeEvent) => {
                      setSort(e.sort);
                  }}
                  skip={page.skip}
                  take={page.take}
                  total={baseLineParameters.length}
                  pageable={true}
                  onPageChange={pageChange}

            >

                <Column field="baseLineParameterId" title="BaseLine Parameter Id" cell={baseLineParameterIdCell}
                        width={200}/>
                <Column field="baseLineParameterDescription" title="BaseLine Parameter Description" width={300}/>
                <Column field="groupLiverDescription" title="Group Liver" width={300}/>
                <Column field="diTypeDescription" title="COI Type" width={300}/>
            </Grid>

        </Fragment>
    )
})
