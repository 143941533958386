export interface IdeaTracking {
    id: number;
    ideaId: string;
    ideaStatusId: string;
    ideaStatusDesc: string;
    processStatusId: string;
    processStatusDesc: string;
    comments: string;
    statusDate: string;
    //changedByUser: string;
}

export class IdeaTracking implements IdeaTracking {
    constructor() {

    }
}

export class IdeaTrackingGrid {
    ind: number;
    ideaId: string;
    ideaStatusId: string;
    ideaStatusDesc: string;
    processStatusDesc: string;
    processStatusId: string;
    comments: string;
    statusDate: Date;

    //changedByUser: string;

    constructor(ind: number, ideaId: string, ideaStatusId: string,
                ideaStatusDesc: string, processStatusDesc: string,
                processStatusId: string, comments: string,
                statusDate: Date, changedByUser: string) {
        this.ind = ind;
        this.ideaId = ideaId;
        this.ideaStatusId = ideaStatusId;
        this.ideaStatusDesc = ideaStatusDesc;
        this.processStatusId = processStatusId;
        this.processStatusDesc = processStatusDesc;
        this.comments = comments;
        this.statusDate = new Date(statusDate);
        //this.changedByUser = changedByUser;
    }
}
