import React, {useEffect, useState} from 'react';
import {Button, Grid, Segment} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import FormInput from '../../../app/common/form/FormInput';
import {useStore} from '../../../app/stores/store';
import {useHistory} from 'react-router-dom';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {CreateDiTypeFormValues} from "../../../app/models/diType";


const DiTypesForm = () => {
    const history = useHistory();
    const {ideaStore, adminStore} = useStore();


    const MyForm = React.useRef<any>()

    let {loadingInitial} = ideaStore;

    let {createDiType, diTypeLoaded, setDiTypeLoaded, selectedDiType, clearSelectedDiType, editDiType} = adminStore;


    const [diType, setDiType] = useState<CreateDiTypeFormValues>(new CreateDiTypeFormValues());
    const [buttonFlag, setButtonFlag] = useState(false);

    useEffect(() => {
        setDiType(new CreateDiTypeFormValues(selectedDiType));


    }, [selectedDiType]);


    const cancelForm = () => {
        history.push(`/ideas/`);
    }
    
    const clearForm = () => {
        setDiType(new CreateDiTypeFormValues());
        clearSelectedDiType();
        setDiTypeLoaded(false);
    }
    
    const handleFormSubmit = (diType: CreateDiTypeFormValues) => {
        setButtonFlag(true)
        if (!diTypeLoaded) {
            createDiType(diType);
        } else {
            setDiTypeLoaded(false)
            editDiType(diType);
        }
        clearForm()
    };


    const requiredValidator = (value: any, getter: any, fieldProps: any) =>
        value
            ? ''
            : fieldProps.name == 'diTypeId' ? "Error: This field COI Type Id is required."
                : fieldProps.name == 'diTypeDescription' ? "Error: This field COI Description is required."
                    : "Error: This field is required.";

    if (loadingInitial) return <LoadingComponent content='Loading coi type...'/>

    return (
        <>
            <Grid>
                <Grid.Column width={12}>
                    <Segment clearing>
                        <Form
                            initialValues={diType}
                            ref={MyForm}
                            onSubmit={values => handleFormSubmit(values as CreateDiTypeFormValues)}
                            key={diType.diTypeId}
                            render={(formRenderProps) => (

                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>
                                       {/* <Field
                                            id={'diTypeId'}
                                            name={'diTypeId'}
                                            label={'COI Type *'}
                                            component={FormInput}
                                            autoComplete={"off"}
                                            validator={requiredValidator}
                                            disabled={diTypeLoaded}
                                            hint={'Hint: COI Type.'}
                                        />*/}

                                        <Field
                                            id={'diTypeDescription'}
                                            name={'diTypeDescription'}
                                            label={'COI Description *'}
                                            component={FormInput}
                                            autoComplete={"off"}
                                            validator={requiredValidator}
                                            hint={'Hint: COI Description.'}
                                        />

                                        <div className="k-form-buttons">
                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit }
                                            >
                                                {diTypeLoaded ? "Update COI Type" : "Add New COI Type"}
                                            </Button>
                                            <Button onClick={cancelForm}>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => {
                                                clearForm()
                                            }
                                            }>
                                                Clear
                                            </Button>
                                        </div>

                                        {buttonFlag && loadingInitial &&
                                        <LoadingComponent content='Processing COI Type...'/>}
                                    </fieldset>

                                </FormElement>

                            )}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>

        </>


    );
}

export default observer(DiTypesForm);


