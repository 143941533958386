import {createContext, useContext} from "react";
import UserStore from "./userStore";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import IdeaStore from "./ideaStore";
import AdminStore from "./adminStore";


interface Store {
    userStore: UserStore;
    adminStore: AdminStore;
    commonStore: CommonStore;
    modalStore: ModalStore;
    ideaStore: IdeaStore;
}

export const store: Store = {
    userStore: new UserStore(),
    adminStore: new AdminStore(),
    commonStore: new CommonStore(),
    modalStore: new ModalStore(),
    ideaStore: new IdeaStore(),
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}