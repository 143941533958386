import React, {useEffect, useMemo, useState} from 'react';
import {Button, Grid, Segment} from 'semantic-ui-react';
import {v4 as uuid} from 'uuid';
import {CreateIdeaFormValues} from '../../../app/models/idea';
import {observer} from 'mobx-react-lite';
import FormTextArea from '../../../app/common/form/FormTextArea';
import FormInput from '../../../app/common/form/FormInput';
import FormNumericTextBox from '../../../app/common/form/FormNumericTextBox';
import {useStore} from '../../../app/stores/store';
import {useHistory, useParams} from 'react-router-dom';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import {savingValidator} from './Validators';
import {toJS} from 'mobx'
import {MemoizedFormDropDownList} from "../../../app/common/form/MemoizedFormDropDownList";
import {FormDropDownTree2} from "../../../app/common/form/FormDropDownTree2";
import LoadingComponent from "../../../app/layout/LoadingComponent";


const CreateIdeaForm = () => {
    const history = useHistory();
    const {ideaStore, adminStore} = useStore();
    const {ideaId} = useParams<{ ideaId: string }>();

    const MyForm = React.useRef<any>()

    let {loadGroupLivers,
        loadAffiliates,
        loadBaseLineParameters,
        loadSavingCategories,
        loadDiTypes,
        loadDiRoles,
        loadRejectionReasons,
        loadUsers,
        loadCurrencies,
        users, currencies, diTypes, partyGroups
    } = adminStore;
    

    useEffect(() => {
        loadGroupLivers();
        loadAffiliates();
        loadBaseLineParameters();
        loadSavingCategories();
        loadDiTypes();
        loadDiRoles();
        loadRejectionReasons();
        loadUsers();
        loadCurrencies()
        

    }, []);

   
    let {
        createIdea, updateIdeaAsOriginator, loadOriginatorIdea, loadingInitial,
        clearCascadedListSelections, filteredGroupLivers
        , setSelectedDiType, setSelectedGroupLiver
        , setBaseLineParameter, setActiveIndex
        , filteredBaseLineParameters, filteredSavingCategories

    } = ideaStore;

    setActiveIndex(0);

    const [idea, setIdea] = useState<CreateIdeaFormValues>(new CreateIdeaFormValues());
    const [buttonFlag, setButtonFlag] = useState(false);
    

    useEffect(() => {
        if (ideaId) {
            loadOriginatorIdea(ideaId).then(idea => setIdea(new CreateIdeaFormValues(idea)));
        }


    }, [ideaId, loadOriginatorIdea]);

    
    
    let currencyData = useMemo(() => currencies ? toJS(currencies) : [], [currencies])
    console.log('currencyData', currencyData);

    let diTypeData = useMemo(() => diTypes ? toJS(diTypes) : [], [diTypes])
    filteredGroupLivers = useMemo(() => filteredGroupLivers ? toJS(filteredGroupLivers) : [], [filteredGroupLivers])
    filteredBaseLineParameters = useMemo(() => filteredBaseLineParameters ? filteredBaseLineParameters : [], [filteredBaseLineParameters])
    filteredSavingCategories = useMemo(() => filteredSavingCategories ? filteredSavingCategories : [], [filteredSavingCategories])
    users = useMemo(() => users ? toJS(users) : [], [users])



    
    
    const selectField = 'selected';
    const expandField = 'expanded';
    const dataItemKey = 'partyGroupId';
    const textField = 'groupName';
    const subItemsField = 'items';

    const cancelForm = () => {
        clearCascadedListSelections();
        setActiveIndex(0);
        history.push(`/ideas/`);
    }
    const handleFormSubmit = (idea: CreateIdeaFormValues) => {
        setButtonFlag(true)
        setActiveIndex(0);
        clearCascadedListSelections();

        if (!idea.ideaId) {
            let newIdea = {
                ...idea,
                ideaId: uuid()
            };
            createIdea(newIdea).then(() => history.push(`/ideas/`));

        } else {

            updateIdeaAsOriginator(idea);
            history.push(`/ideas/`)
        }

    };


    const onDiTypeChange = React.useCallback(
        (event) => {
            const diType = event.value;
            setSelectedDiType(diType);

            MyForm.current.onChange('groupLiverId', {value: null});
            MyForm.current.onChange('baseLineParameterId', {value: null});
            MyForm.current.onChange('savingCategoryId', {value: null});
        },
        [setSelectedDiType]
    );

    const onGroupLiverChange = React.useCallback(
        (event) => {
            const groupLiver = event.value;
            setSelectedGroupLiver(groupLiver);
            MyForm.current.onChange('baseLineParameterId', {value: null});
            MyForm.current.onChange('savingCategoryId', {value: null});
        },
        [setSelectedGroupLiver]
    );

    const onBaseLineParameterChange = React.useCallback(
        (event) => {
            const baseLineParameter = event.value;
            setBaseLineParameter(baseLineParameter);
            MyForm.current.onChange('savingCategoryId', {value: null});

        },
        [setBaseLineParameter]
    );

    const requiredValidator = (value: any, getter: any, fieldProps: any) =>
        value
            ? ''
            : fieldProps.name == 'title' ? "Error: This field COI Title is required."
                : fieldProps.name == 'description' ? "Error: This field COI Description is required."
                    : fieldProps.name == 'partyGroupId' ? "Error: This field Saving Location is required."
                        : fieldProps.name == 'currentSituation' ? "Error: This field Current Situation is required."
                            : fieldProps.name == 'improvedSituation' ? "Error: This field Improved Situation is required."
                                : fieldProps.name == 'currencyId' ? "Error: This field Currency is required."
                                    : fieldProps.name == 'diTypeId' ? "Error: This field COI Type is required."
                                        : fieldProps.name == 'groupLiverId' ? "Error: This field Group Lever is required."
                                            : fieldProps.name == 'baseLineParameterId' ? "Error: This field Baseline Parameter is required."
                                                : fieldProps.name == 'savingCategoryId' ? "Error: This field Saving Category is required."
                                                    : "Error: This field is required.";

    if (loadingInitial) return <LoadingComponent content='Loading Initiative...'/>

    return (
        <>
            <Grid>
                <Grid.Column width={12}>
                    <Segment clearing>
                        <Form
                            initialValues={idea}
                            ref={MyForm}
                            onSubmit={values => handleFormSubmit(values as CreateIdeaFormValues)}
                            key={idea.ideaId}
                            render={(formRenderProps) => (

                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>
                                        <Field
                                            id={'title'}
                                            name={'title'}
                                            label={'COI Title *'}
                                            component={FormInput}
                                            autoComplete={"off"}
                                            validator={requiredValidator}
                                            hint={'Hint: Brief description about your Cost Optimization Initiative.'}
                                        />

                                        <Field
                                            id={'description'}
                                            name={'description'}
                                            label={'COI Description *'}
                                            autoComplete={"off"}
                                            rows={3}
                                            component={FormTextArea}
                                            validator={requiredValidator}
                                            hint={'Hint: Full description about your Cost Optimization Initiative.'}
                                        />
                                        <Field
                                            id={'partyGroupId'}
                                            name={'partyGroupId'}
                                            data={partyGroups}
                                            label={"Saving in Department *"}
                                            textField={textField}
                                            dataItemKey={dataItemKey}
                                            selectField={selectField}
                                            expandField={expandField}
                                            subItemsField={subItemsField}
                                            component={FormDropDownTree2}
                                            validator={requiredValidator}
                                            hint={'Hint: Select location.'}
                                        />
                                        <Field
                                            id={'currentSituation'}
                                            name={'currentSituation'}
                                            label={'Current Situation *'}
                                            autoComplete={"off"}
                                            validator={requiredValidator}
                                            component={FormInput}
                                            hint={'Hint: Describe the current situation before implementing your Cost Optimization Initiative'}
                                        />

                                        <Field
                                            id={'improvedSituation'}
                                            name={'improvedSituation'}
                                            label={'Improved Situation *'}
                                            autoComplete={"off"}
                                            validator={requiredValidator}
                                            component={FormInput}
                                            hint={'Hint: Describe the future situation after implementing your Cost Optimization Initiative'}
                                        />

                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Field
                                                        id={'estimatedAnnualSaving'}
                                                        format="n2"
                                                        name={'estimatedAnnualSaving'}
                                                        label={'Estimated Annual Saving *,   Enter Value in 000s'}
                                                        component={FormNumericTextBox}
                                                        validator={savingValidator}
                                                        hint={'Hint: Estimated Annual Saving from your Cost Optimization Initiative.'}
                                                    />

                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={"currencyId"}
                                                        name={"currencyId"}
                                                        label={"Currency *"}
                                                        component={MemoizedFormDropDownList}
                                                        dataItemKey={"currencyId"}
                                                        textField={"currencyDescription"}
                                                        data={currencyData}
                                                        validator={requiredValidator}
                                                        hint={'Hint: Select the currency used in your calculations.'}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>


                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Field
                                                        id={'diTypeId'}
                                                        name={'diTypeId'}
                                                        textField={"diTypeDescription"}
                                                        label={'COI Type *'}
                                                        data={diTypeData}
                                                        onChange={onDiTypeChange}
                                                        component={MemoizedFormDropDownList}
                                                        validator={requiredValidator}
                                                        hint={'Hint: Select your Cost Optimization Initiative Type.'}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={'groupLiverId'}
                                                        name={'groupLiverId'}
                                                        textField={"groupLiverDescription"}
                                                        label={'Group Lever *'}
                                                        data={filteredGroupLivers}
                                                        onChange={onGroupLiverChange}
                                                        component={MemoizedFormDropDownList}
                                                        validator={requiredValidator}
                                                        disabled={!formRenderProps.valueGetter('diTypeId')}
                                                        hint={'Hint: Select group lever.'}
                                                    />
                                                </Grid.Column>

                                            </Grid.Row>
                                        </Grid>

                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Field
                                                        id={'baseLineParameterId'}
                                                        name={'baseLineParameterId'}
                                                        textField={"baseLineParameterDescription"}
                                                        label={'Baseline Parameter *'}
                                                        data={filteredBaseLineParameters}
                                                        onChange={onBaseLineParameterChange}
                                                        component={MemoizedFormDropDownList}
                                                        validator={requiredValidator}
                                                        disabled={!formRenderProps.valueGetter('groupLiverId')}
                                                        hint={'Hint: Select baseline parameter.'}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={'savingCategoryId'}
                                                        name={'savingCategoryId'}
                                                        textField={"savingCategoryDescription"}
                                                        label={'Saving Category *'}
                                                        data={filteredSavingCategories}
                                                        component={MemoizedFormDropDownList}
                                                        validator={requiredValidator}
                                                        disabled={!formRenderProps.valueGetter('baseLineParameterId')}
                                                        hint={'Hint: Select saving category.'}
                                                    />
                                                </Grid.Column>

                                            </Grid.Row>
                                        </Grid>

                                        <div className="k-form-buttons">
                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit || buttonFlag}
                                            >
                                                Submit COI
                                            </Button>
                                            <Button onClick={cancelForm}>
                                                Cancel
                                            </Button>
                                        </div>

                                        {buttonFlag && <LoadingComponent content='Processing Initiative...'/>}
                                    </fieldset>

                                </FormElement>

                            )}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>

        </>


    );
}

export default observer(CreateIdeaForm);


