import {PartyGroupRole} from "./partyGroupRole";

export interface AppUser {
    id: string;
    username: string;
    email: string;
    displayName: string;
    partyGroupId: string;
    partyGroupDescription: string;
    title: string;
    image?: string;
    createdStamp: string
    createdBy: string
    hiddenField: string
    partyGroupRoleKey?: PartyGroupRole[];

}

export class CreateAppUserFormValues {
    id?: string;
    email: string = '';
    partyGroupId: string = '';
    title: string = '';
    displayName?: string = '';
    username?: string = '';
    image?: string;
    createdStamp: string = '';
    createdBy: string = ''
    hiddenField: string | null = null;
    partyGroupRoleKey?: PartyGroupRole[];


    constructor(appUser?: CreateAppUserFormValues) {
        if (appUser) {
            this.id = appUser.id;
            this.email = appUser.email;
            this.partyGroupId = appUser.partyGroupId
            this.title = appUser.title;
            this.displayName = appUser.displayName;
            this.username = appUser.username;
            this.createdStamp = appUser.createdStamp
            this.createdBy = appUser.createdBy
        }
    }
}