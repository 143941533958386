import React, {useEffect, useMemo, useState} from 'react';
import {Button, Grid, Icon, Segment} from 'semantic-ui-react';
import {CreateIdeaFormValues} from '../../../app/models/idea';
import {observer} from 'mobx-react-lite';
import FormTextArea from '../../../app/common/form/FormTextArea';
import FormInput from '../../../app/common/form/FormInput';
import FormNumericTextBox from '../../../app/common/form/FormNumericTextBox';
import {useStore} from '../../../app/stores/store';
import {useHistory, useParams} from 'react-router-dom';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import {MemoizedFormComboBox} from "../../../app/common/form/FormComboBox";
import {completedDateValidator, savingValidator, titleValidator} from './Validators';
import {toJS} from 'mobx'
import {MemoizedFormDropDownList} from "../../../app/common/form/MemoizedFormDropDownList";
import {FormDropDownTree2} from "../../../app/common/form/FormDropDownTree2";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import './styles-dialog.css';
import FormDatePicker from "../../../app/common/form/FormDatePicker";

import {Grid as KendoGrid, GridColumn as Column, GridSortChangeEvent,} from "@progress/kendo-react-grid";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {Error} from "@progress/kendo-react-labels";
import {toast} from "react-toastify";
import {CreateTaskFormValues} from "../../../app/models/actionPlanTask";
import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {FormMultiColumnComboBox} from "../../../app/common/form/FormMultiColumnComboBox";

const MyContext = React.createContext({
    enterEdit: (dataItem: any, field: any) => null,
    itemChange: (event: any) => null
});

const minMaxValidation = (value: any) => {
    return value >= 0;
}

const ValidationCellActual = (props: any) => {

    return (
        <MyContext.Consumer>
            {(context: any) => {
                const impactMonth = props.dataItem.month
                let lastMonth: Date = new Date(props.dataItem.month)
                lastMonth = new Date(lastMonth.setMonth(lastMonth.getMonth() - 1))
                const minDate = new Date(lastMonth.setDate(23))
                let thisMonth = new Date(impactMonth)
                const maxDate = new Date(thisMonth.setDate(15))

                const allowMonth = new Date() >= minDate && new Date() <= maxDate;
                return props.dataItem.inEdit === props.field ? (
                    <td>
                        <NumericTextBox
                            required
                            value={
                                props.dataItem[props.field] === null
                                    ? ""
                                    : props.dataItem[props.field]
                            }
                            onChange={(e) => {
                                props.onChange({
                                    dataIndex: 0,
                                    dataItem: props.dataItem,
                                    field: props.field,
                                    syntheticEvent: e.syntheticEvent,
                                    value: e.value,
                                });
                            }}
                            name={props.field}
                            disabled={!allowMonth}
                        />
                        {!minMaxValidation(props.dataItem[props.field]) && <Error>This is not valid</Error>}
                    </td>
                ) : (
                    <td
                        className={props.className}
                        style={props.style}
                        onClick={() => context.enterEdit(props.dataItem, props.field)}
                    >
                        {props.dataItem[props.field]}
                        {!minMaxValidation(props.dataItem[props.field]) && <Error>This is not valid</Error>}
                    </td>
                );
            }}
        </MyContext.Consumer>

    );
};


const ValidationCellEstimated = (props: any) => {

    return (
        <MyContext.Consumer>
            {(context: any) => {
                console.log('context.enterEdit', context.enterEdit)
                console.log('context', context)
                return props.dataItem.inEdit === props.field ? (
                    <td>
                        <NumericTextBox
                            required
                            value={
                                props.dataItem[props.field] === null
                                    ? ""
                                    : props.dataItem[props.field]
                            }
                            onChange={(e) => {
                                props.onChange({
                                    dataIndex: 0,
                                    dataItem: props.dataItem,
                                    field: props.field,
                                    syntheticEvent: e.syntheticEvent,
                                    value: e.value,
                                });
                            }}
                            name={props.field}
                        />
                        {!minMaxValidation(props.dataItem[props.field]) && <Error>This is not valid</Error>}
                    </td>
                ) : (
                    <td
                        className={props.className}
                        style={props.style}
                        onClick={() => context.enterEdit(props.dataItem, props.field)}
                    >
                        {props.dataItem[props.field]}
                        {!minMaxValidation(props.dataItem[props.field]) && <Error>This is not valid</Error>}
                    </td>
                );
            }}
        </MyContext.Consumer>

    );
};

const ActionOwnerForm = () => {
    const history = useHistory();
    const {ideaStore, userStore, adminStore} = useStore();
    const {ideaId} = useParams<{ ideaId: string }>();
    const MyDateEstimate2 = React.useRef<any>()
    const MyForm = React.useRef<any>()
    const TasksForm = React.useRef<any>()

    let {loadGroupLivers,
        loadAffiliates,
        loadBaseLineParameters,
        loadSavingCategories,
        loadDiTypes,
        loadDiRoles,
        loadRejectionReasons,
        loadUsers,
        loadCurrencies,
        loadPartyGroups
    } = adminStore;

    useEffect(() => {
        loadGroupLivers();
        loadAffiliates();
        loadBaseLineParameters();
        loadSavingCategories();
        loadDiTypes();
        loadDiRoles();
        loadRejectionReasons();
        loadUsers();
        loadCurrencies()
        loadPartyGroups();

    }, []);
    
    let {user} = userStore;
    let {
        updateIdeaAsActionOwner, loadActionOwnerIdea, loadingInitial,
        clearCascadedListSelections, filteredGroupLivers, taskOwners,
         setSelectedDiType, setSelectedGroupLiver
        , setBaseLineParameter, setFinancialImpact, actionControllers
        , filteredBaseLineParameters, filteredSavingCategories, updateFinancialImpact,
        sendForStatusUpdate, setActiveIndex, financialImpacts, ideaTrackings,
        updateNewActionPlanTasks, updateExistingActionPlanTasks, actionPlanTasksGrid,
        ideaStatusAllowTasks, ideaStatusAllowFormEditing, createdStamp,
        ideaStatusAllowUpdatingTaskCompletedDate, ideaStatusAllowUpdatingTaskFields
    } = ideaStore;
    let {adminStore: {users, currencies, diTypes, partyGroups, rejectionReasons}} = useStore();


    const initialSort: Array<SortDescriptor> = [
        {field: "statusDate", dir: "desc"},
    ];
    const [sort, setSort] = React.useState(initialSort);


    const [idea, setIdea] = useState<CreateIdeaFormValues>(new CreateIdeaFormValues());
    const [task, setTask] = useState<CreateTaskFormValues>(new CreateTaskFormValues());
    const [timesheetDialogVisible, setTimesheetDialogVisible] = React.useState(false);
    const [financialImpactDialogVisible, setFinancialImpactDialogVisible] = React.useState(false);
    const [ideaTrackingsDialogVisible, setIdeaTrackingsDialogVisible] = React.useState(false);
    const [actionPlanDialogVisible, setActionPlanDialogVisible] = React.useState(false);
    const [tasksGridDialogVisible, setTasksGridDialogVisible] = React.useState(false);
    const [confirmationDialogVisible, setConfirmationDialogVisible] = React.useState(false);
    const [sendForStatusChange, setSendForStatusChange] = React.useState(false);
    const [sendForStatusChangeTasks, setSendForStatusChangeTasks] = React.useState(false);
    const [buttonFlag, setButtonFlag] = useState(false);
    const [dateFieldsValidationFlag, setDateFieldsValidationFlag] = useState(true);

    const [gridData, setGridData] = useState(financialImpacts);

    const toggleDialogTimesheet = () => {
        setTimesheetDialogVisible(!timesheetDialogVisible);
    };

    const toggleDialogConfirmation = () => {
        setConfirmationDialogVisible(!confirmationDialogVisible);
    };

    const toggleDialogActionPlan = () => {
        if (actionPlanDialogVisible) {
            setTask(new CreateTaskFormValues())
        }
        setActionPlanDialogVisible(!actionPlanDialogVisible);
    };

    const toggleDialogTasksGrid = () => {
        setTasksGridDialogVisible(!tasksGridDialogVisible);
    };

    const toggleDialogIdeaTrackings = () => {
        setIdeaTrackingsDialogVisible(!ideaTrackingsDialogVisible);
    };

    const toggleDialogFinancialImpact = () => {
        setFinancialImpactDialogVisible(!financialImpactDialogVisible);
    };

    const checkDateStageValidation = (): boolean => {
        let returnValue: boolean = false

        if (MyForm.current?.errors?.d2EstimatedDate === undefined
            && MyForm?.current?.errors?.d3EstimatedDate === undefined
            && MyForm?.current?.errors?.d4EstimatedDate === undefined
            && MyForm?.current?.errors?.d5EstimatedDate === undefined) {
            return false
        }
        returnValue = MyForm.current?.errors?.d2EstimatedDate === ""
            && MyForm?.current?.errors?.d3EstimatedDate === ""
            && MyForm?.current?.errors?.d4EstimatedDate === ""
            && MyForm?.current?.errors?.d5EstimatedDate === ""

        if (returnValue) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        loadActionOwnerIdea(ideaId).then(idea => setIdea(new CreateIdeaFormValues(idea)))
        setGridData(financialImpacts);

    }, [ideaId, loadActionOwnerIdea]);


    actionControllers = useMemo(() => actionControllers ? toJS(actionControllers) : [], [actionControllers])
    let dropDownTreeData = useMemo(() => partyGroups ? toJS(partyGroups) : [], [partyGroups])
    let currencyData = useMemo(() => currencies ? toJS(currencies) : [], [currencies])
    let diTypeData = useMemo(() => diTypes ? toJS(diTypes) : [], [diTypes])
    filteredGroupLivers = useMemo(() => filteredGroupLivers ? toJS(filteredGroupLivers) : [], [filteredGroupLivers])
    filteredBaseLineParameters = useMemo(() => filteredBaseLineParameters ? filteredBaseLineParameters : [], [filteredBaseLineParameters])
    filteredSavingCategories = useMemo(() => filteredSavingCategories ? filteredSavingCategories : [], [filteredSavingCategories])
    rejectionReasons = useMemo(() => rejectionReasons ? toJS(rejectionReasons) : [], [rejectionReasons])
    taskOwners = useMemo(() => taskOwners ? toJS(taskOwners) : [], [taskOwners])
    users = useMemo(() => users ? toJS(users) : [], [users])

    users = users.filter((x: any) => x.id !== user!.displayName)

    console.log('taskOwners', taskOwners);
    const selectField = 'selected';
    const expandField = 'expanded';
    const dataItemKey = 'partyGroupId';
    const textField = 'groupName';
    const subItemsField = 'items';

    const cancelForm = () => {
        clearCascadedListSelections();
        setActiveIndex(2);
        history.push(`/ideas/`);
    }

    const loadTask = (taskNumber: number) => {
        const task = actionPlanTasksGrid.filter((item) => item.id == taskNumber)

        const selectedTask: CreateTaskFormValues = new CreateTaskFormValues(task[0].id,
            task[0].ideaId, task[0].description, task[0].contributors!, task[0].completedDate!,
            task[0].dueDate, task[0].taskOwnerId)

        setTask(selectedTask);
        toggleDialogActionPlan()
        toggleDialogTasksGrid()
    }

    const taskDescription = (props: any) => {
        return (
            <td>
                <Button basic
                        onClick={() => loadTask(props.dataItem.id)}
                        color='blue'
                        size='mini'>
                    Edit
                </Button>
            </td>
        )
    }
    const handleFormSubmit = (idea: CreateIdeaFormValues) => {

        if (!dateFieldsValidationFlag) {
            toggleDialogConfirmation();
            return false;
        }
        setButtonFlag(true)
        let gridReadyForSubmit: boolean = false;
        setActiveIndex(2);
        clearCascadedListSelections();

        if (sendForStatusChange) {
            financialImpacts.forEach(item => {
                if (item.saving !== 0) {
                    gridReadyForSubmit = true;
                }
            })
            if (!gridReadyForSubmit) {
                toast.error('Financial information is not complete.')
                return false;
            }

            sendForStatusUpdate(idea).then(() => history.push(`/ideas/`));

        } else if (sendForStatusChangeTasks) {
            sendForStatusUpdate(idea).then(() => history.push(`/ideas/`));
        } else {
            updateIdeaAsActionOwner(idea).then(() => history.push(`/ideas/`));
        }
    };


    const handleTaskFormSubmit = (task: CreateTaskFormValues) => {
        if (task.ideaId === "") {
            const selectedUser  = taskOwners.filter((i:any) => i.taskOwnerId === task.taskOwnerId)
            task.taskOwnerName = selectedUser[0].displayName
            task.ideaId = idea.ideaId!;
            updateNewActionPlanTasks(task);
        } else {
            updateExistingActionPlanTasks(task)
        }

        setTask(new CreateTaskFormValues())
        TasksForm.current.resetForm();
    }


    const getParents = (arr1: any, elem: any) => {
        var parents = [];
        arr1.map((item: any) => {
            if (item.parent && item.parent.partyGroupTypeId != 'Company' && item.partyGroupId === elem) {

            }
        })
        while (elem.parent && elem.parent.partyGroupTypeId != 'Company') {
            elem = elem.parent;
            parents.push(elem);
        }
        return parents;
    }

    const fillFinImpactMonths = () => {
        if ((MyForm.current.valueGetter('d2EstimatedDate') !== null)
            && (MyForm.current.valueGetter('d5EstimatedDate') !== null)) {

            const d1: Date | undefined = new Date(MyForm.current.valueGetter('d2EstimatedDate'));
            const d2: Date | null = new Date(MyForm.current.valueGetter('d5EstimatedDate'));
            var months;
            months = (d2!.getFullYear() - d1!.getFullYear()) * 12;
            months -= d1!.getMonth();
            months += d2!.getMonth();
            const monthBetween = months <= 0 ? 0 : months;

            const monthsBetweenCalculated = monthBetween >= 12 ? monthBetween : 12
            console.log('fillFinImpactMonths', d1, monthsBetweenCalculated)

            setFinancialImpact(d1!, monthsBetweenCalculated);


        }
    }

    const autoPopulateDates = () => {
        let currentDate: Date = new Date();


        let d2: Date = new Date(currentDate.setMonth(currentDate.getMonth() + 2));
        d2 = new Date(currentDate.setDate(15))

        let tempDate: Date = new Date(d2);

        let d3: Date;
        d3 = new Date(tempDate.setDate(tempDate.getDate() + 30))

        tempDate = new Date(d3);
        let d4: Date
        d4 = new Date(tempDate.setDate(tempDate.getDate() + 120))

        tempDate = new Date(d4);
        let d5: Date
        d5 = new Date(tempDate.setDate(tempDate.getDate() + 240))

        MyForm.current.onChange("d2EstimatedDate", {value: d2});
        MyForm.current.onChange("d3EstimatedDate", {value: d3});
        MyForm.current.onChange("d4EstimatedDate", {value: d4});
        MyForm.current.onChange("d5EstimatedDate", {value: d5});

        fillFinImpactMonths();

        const gridDataJS = toJS(gridData)
        console.log('gridDataJS', gridDataJS)
        console.log('financialImpacts', toJS(financialImpacts))
    }


    const itemChange = (event: any) => {
        console.log(event)
        let list = Object.assign([], gridData);
        let updatedState = list.map((item: any) => {
            if (item.ind === event.dataItem.ind) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                item[event.field] = event.value
            }
            return item;
        })

        setGridData(updatedState);
        updateFinancialImpact(updatedState);
        return null;
    };

    const enterEdit = (dataItem: any, field: any) => {
        let list = Object.assign([], gridData);

        const data = list.map((item: any) => ({
            ...item,
            inEdit: item.identifier === dataItem.identifier ? field : undefined
        }));

        setGridData(data);
        return null;
    };


    const onD2EstimatedDateChange = React.useCallback(
        (event) => {
            fillFinImpactMonths();
        },
        [fillFinImpactMonths]
    );

    const onD5EstimatedDateChange = React.useCallback(
        (event) => {
            fillFinImpactMonths();
        },
        [fillFinImpactMonths]
    );

    const onDiTypeChange = React.useCallback(
        (event) => {
            const diType = event.value;
            setSelectedDiType(diType);

            MyForm.current.onChange('groupLiverId', {value: null});
            MyForm.current.onChange('baseLineParameterId', {value: null});
            MyForm.current.onChange('savingCategoryId', {value: null});
        },
        [setSelectedDiType]
    );

    const onPartyGroupChange = React.useCallback(
        (event) => {
            const partyGroupId = event.value;
            const parents = getParents(dropDownTreeData, partyGroupId)
        },
        []
    );

    const onGroupLiverChange = React.useCallback(
        (event) => {
            const groupLiver = event.value;
            setSelectedGroupLiver(groupLiver);
            MyForm.current.onChange('baseLineParameterId', {value: null});
            MyForm.current.onChange('savingCategoryId', {value: null});
        },
        [setSelectedGroupLiver]
    );

    const onBaseLineParameterChange = React.useCallback(
        (event) => {
            const baseLineParameter = event.value;
            setBaseLineParameter(baseLineParameter);
            MyForm.current.onChange('savingCategoryId', {value: null});

        },
        [setBaseLineParameter]
    );
    const requiredValidator = (value: any, getter: any, fieldProps: any) =>
        value
            ? ''
            : fieldProps.name == 'title' ? "Error: This field COI Title is required."
                : fieldProps.name == 'description' ? "Error: This field COI Description is required."
                    : fieldProps.name == 'partyGroupId' ? "Error: This field Saving Location is required."
                        : fieldProps.name == 'currentSituation' ? "Error: This field Current Situation is required."
                            : fieldProps.name == 'improvedSituation' ? "Error: This field Improved Situation is required."
                                : fieldProps.name == 'currencyId' ? "Error: This field Currency is required."
                                    : fieldProps.name == 'diTypeId' ? "Error: This field COI Type is required."
                                        : fieldProps.name == 'groupLiverId' ? "Error: This field Group Lever is required."
                                            : fieldProps.name == 'baseLineParameterId' ? "Error: This field Baseline Parameter is required."
                                                : fieldProps.name == 'savingCategoryId' ? "Error: This field Saving Category is required."
                                                    : fieldProps.name == 'd2EstimatedDate' ? "Error: This field COS-2 Estimate Date is required."
                                                        : fieldProps.name == 'd3EstimatedDate' ? "Error: This field COS-3 Estimate Date is required."
                                                            : fieldProps.name == 'd4EstimatedDate' ? "Error: This field COS-4 Estimate Date is required."
                                                                : fieldProps.name == 'd5EstimatedDate' ? "Error: This field COS-5 Estimate Date is required."
                                                                    : "Error: This field is required.";


    const d2EstimatedDateValidator = (value: Date) => {

        if (value && (value >= createdStamp)) {
            setDateFieldsValidationFlag(true)
            return "";
        } else {
            setDateFieldsValidationFlag(false)
            return "Past date isn't valid for COS 2"
        }

    };

    const d3EstimatedDateValidator = (value: Date) => {
        let d2EstimatedDateGetter: Date = new Date(MyForm.current.valueGetter('d2EstimatedDate'));
        let acceptedDate = new Date(d2EstimatedDateGetter.setDate(d2EstimatedDateGetter.getDate() + 1));

        if (value && (value >= acceptedDate)) {
            setDateFieldsValidationFlag(true)
            return "";
        } else {
            setDateFieldsValidationFlag(false)
            return "COS 3 date must be greater than COS 2 date"
        }

    };

    const d4EstimatedDateValidator = (value: Date) => {
        let d3EstimatedDateGetter: Date = new Date(MyForm.current.valueGetter('d3EstimatedDate'));
        let acceptedDate = new Date(d3EstimatedDateGetter.setDate(d3EstimatedDateGetter.getDate() + 1));

        if (value && (value >= acceptedDate)) {
            setDateFieldsValidationFlag(true)
            return "";
        } else {
            setDateFieldsValidationFlag(false)
            return "COS 4 date must be greater than COS 3 date"
        }

    };

    const d5EstimatedDateValidator = (value: Date) => {
        let d4EstimatedDateGetter: Date = new Date(MyForm.current.valueGetter('d4EstimatedDate'));
        let acceptedDate = new Date(d4EstimatedDateGetter.setDate(d4EstimatedDateGetter.getDate() + 1));

        if (value && (value >= acceptedDate)) {
            setDateFieldsValidationFlag(true)
            return "";
        } else {
            setDateFieldsValidationFlag(false)
            return "COS 5 date must be greater than COS 4 date"
        }

    };

    if (loadingInitial) return <LoadingComponent content='Loading Initiative...'/>

    // @ts-ignore
    return (
        <Segment>
            <Form
                initialValues={idea}
                ref={MyForm}
                onSubmit={values => handleFormSubmit(values as CreateIdeaFormValues)}
                key={idea.ideaId}
                render={(formRenderProps) => (

                    <FormElement>
                        <fieldset className={'k-form-fieldset'}>
                            <Field
                                id={'title'}
                                name={'title'}
                                label={'COI Title *'}
                                component={FormInput}
                                autoComplete={"off"}
                                validator={requiredValidator}
                                hint={'Hint: Brief description about your Cost Optimization Initiative.'}
                                disabled={!ideaStatusAllowFormEditing}
                            />

                            <Field
                                id={'description'}
                                name={'description'}
                                label={'COI Description *'}
                                autoComplete={"off"}
                                rows={3}
                                component={FormTextArea}
                                validator={requiredValidator}
                                hint={'Hint: Full description about your Cost Optimization Initiative.'}
                                disabled={!ideaStatusAllowFormEditing}
                            />
                            <Field
                                id={'partyGroupId'}
                                name={'partyGroupId'}
                                data={dropDownTreeData}
                                label={"Saving in Department *"}
                                textField={textField}
                                dataItemKey={dataItemKey}
                                selectField={selectField}
                                expandField={expandField}
                                subItemsField={subItemsField}
                                component={FormDropDownTree2}
                                validator={requiredValidator}
                                hint={'Hint: Select location.'}
                                disabled={!ideaStatusAllowFormEditing}
                            />
                            <Field
                                id={'currentSituation'}
                                name={'currentSituation'}
                                label={'Current Situation *'}
                                autoComplete={"off"}
                                validator={requiredValidator}
                                component={FormInput}
                                hint={'Hint: Describe the current situation before implementing your Cost Optimization Initiative'}
                                disabled={!ideaStatusAllowFormEditing}
                            />

                            <Field
                                id={'improvedSituation'}
                                name={'improvedSituation'}
                                label={'Improved Situation *'}
                                autoComplete={"off"}
                                validator={requiredValidator}
                                component={FormInput}
                                hint={'Hint: Describe the future situation after implementing your Cost Optimization Initiative'}
                                disabled={!ideaStatusAllowFormEditing}
                            />

                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <Field
                                            id={'estimatedAnnualSaving'}
                                            format="n2"
                                            name={'estimatedAnnualSaving'}
                                            label={'Estimated Annual Saving *,   Enter Value in 000s'}
                                            component={FormNumericTextBox}
                                            validator={savingValidator}
                                            hint={'Hint: Estimated Annual Saving from your Cost Optimization Initiative.'}
                                            disabled={!ideaStatusAllowFormEditing}
                                        />

                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field
                                            id={"currencyId"}
                                            name={"currencyId"}
                                            label={"Currency *"}
                                            component={MemoizedFormDropDownList}
                                            dataItemKey={"currencyId"}
                                            textField={"currencyDescription"}
                                            data={currencyData}
                                            validator={requiredValidator}
                                            hint={'Hint: Select the currency used in your calculations.'}
                                            disabled={!ideaStatusAllowFormEditing}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field
                                            id={'actionControllerId'}
                                            name={'actionControllerId'}
                                            textField={"displayName"}
                                            label={'Financial Controller'}
                                            data={actionControllers}
                                            component={FormMultiColumnComboBox}
                                            hint={'Hint: Select financial controller for this Cost Optimization Initiative.'}
                                            disabled={!ideaStatusAllowFormEditing}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>


                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Field
                                            id={'diTypeId'}
                                            name={'diTypeId'}
                                            textField={"diTypeDescription"}
                                            label={'COI Type *'}
                                            data={diTypeData}
                                            onChange={onDiTypeChange}
                                            component={MemoizedFormDropDownList}
                                            validator={requiredValidator}
                                            hint={'Hint: Select your Cost Optimization Initiative Type.'}
                                            disabled={!ideaStatusAllowFormEditing}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field
                                            id={'groupLiverId'}
                                            name={'groupLiverId'}
                                            textField={"groupLiverDescription"}
                                            label={'Group Lever *'}
                                            data={filteredGroupLivers}
                                            onChange={onGroupLiverChange}
                                            component={MemoizedFormDropDownList}
                                            validator={requiredValidator}
                                            disabled={!formRenderProps.valueGetter('diTypeId') || !ideaStatusAllowFormEditing}
                                            hint={'Hint: Select group lever.'}
                                        />
                                    </Grid.Column>

                                </Grid.Row>
                            </Grid>

                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Field
                                            id={'baseLineParameterId'}
                                            name={'baseLineParameterId'}
                                            textField={"baseLineParameterDescription"}
                                            label={'Baseline Parameter *'}
                                            data={filteredBaseLineParameters}
                                            onChange={onBaseLineParameterChange}
                                            component={MemoizedFormDropDownList}
                                            validator={requiredValidator}
                                            disabled={!formRenderProps.valueGetter('groupLiverId') || !ideaStatusAllowFormEditing}
                                            hint={'Hint: Select baseline parameter.'}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Field
                                            id={'savingCategoryId'}
                                            name={'savingCategoryId'}
                                            textField={"savingCategoryDescription"}
                                            label={'Saving Category *'}
                                            data={filteredSavingCategories}
                                            component={MemoizedFormDropDownList}
                                            validator={requiredValidator}
                                            disabled={!formRenderProps.valueGetter('baseLineParameterId') || !ideaStatusAllowFormEditing}
                                            hint={'Hint: Select saving category.'}
                                        />
                                    </Grid.Column>

                                </Grid.Row>
                                <input type="hidden" name="hiddenField" value="login"/>
                            </Grid>
                            {ideaTrackingsDialogVisible && <Dialog
                                width={600}
                                title={"COI Status History"}
                                onClose={() => {
                                    toggleDialogIdeaTrackings()
                                }}>
                                <Grid style={{height: "500px", width: "600px"}}>
                                    <Grid.Row>
                                        <Grid.Column width={14}>
                                            <KendoGrid data={orderBy(ideaTrackings, sort)}
                                                       sortable={true}
                                                       sort={sort}
                                                       onSortChange={(e: GridSortChangeEvent) => {
                                                           setSort(e.sort);
                                                       }}
                                            >
                                                <Column field="ind" title="ind" width="0"/>
                                                <Column field="ideaId" title="ideaId" width="0"/>
                                                <Column field="processStatusDesc" title="Process Status" width="100px"/>
                                                <Column field="ideaStatusDesc" title="COI Status" width="100px"/>
                                                <Column field="comments" title="Comments" width="150px"/>
                                                <Column field="statusDate" title="Status Date" width="120px"
                                                        format="{0: dd/MM/yyyy hh:mm}"/>
                                            </KendoGrid>
                                        </Grid.Column>
                                    </Grid.Row>

                                </Grid>
                                <DialogActionsBar>
                                    <Button
                                        type='button' color='blue'
                                        className="k-button"
                                        icon labelPosition='right'
                                        onClick={() => {
                                            toggleDialogTimesheet();
                                            toggleDialogIdeaTrackings()
                                        }}>Next
                                        <Icon name='arrow right'/>
                                    </Button>

                                </DialogActionsBar>
                            </Dialog>}
                            {timesheetDialogVisible && <Dialog
                                width={700}
                                title={"Enter Project Stage Timeline"}
                                closeIcon={false}
                                onClose={() => {
                                    toggleDialogTimesheet()
                                }}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <Field
                                                id={'d2EstimatedDate'}
                                                name={'d2EstimatedDate'}
                                                label={'COS-2 Estimate Date'}
                                                component={FormDatePicker}
                                                validator={d2EstimatedDateValidator}
                                                onChange={onD2EstimatedDateChange}
                                                disabled={!ideaStatusAllowFormEditing}
                                                ref={MyDateEstimate2}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <Field
                                                id={'d2ActualDate'}
                                                name={'d2ActualDate'}
                                                label={'COS-2 Actual Date'}
                                                component={FormDatePicker}
                                                disabled={true}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <div className="k-form-field"><p>Capture COI and Estimate</p></div>
                                            <Button type='button' onClick={autoPopulateDates} color={"green"}
                                                    disabled={!ideaStatusAllowFormEditing}>
                                                Auto Populate Dates
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <Field
                                                id={'d3EstimatedDate'}
                                                name={'d3EstimatedDate'}
                                                label={'COS-3 Estimate Date'}
                                                component={FormDatePicker}
                                                validator={d3EstimatedDateValidator}
                                                disabled={!ideaStatusAllowFormEditing}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <Field
                                                id={'d3ActualDate'}
                                                name={'d3ActualDate'}
                                                label={'COS-3 Actual Date'}
                                                component={FormDatePicker}
                                                disabled={true}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <div className="k-form-field"><p>Create & implement action plan, may begin
                                                to realize impact</p></div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <Field
                                                id={'d4EstimatedDate'}
                                                name={'d4EstimatedDate'}
                                                label={'COS-4 Estimate Date'}
                                                component={FormDatePicker}
                                                validator={d4EstimatedDateValidator}
                                                disabled={!ideaStatusAllowFormEditing}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <Field
                                                id={'d4ActualDate'}
                                                name={'d4ActualDate'}
                                                label={'COS-4 Actual Date'}
                                                component={FormDatePicker}
                                                disabled={true}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <div className="k-form-field"><p>Action plan now complete, realize remainder
                                                of impact</p></div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={5}>
                                            <Field
                                                id={'d5EstimatedDate'}
                                                name={'d5EstimatedDate'}
                                                label={'COS-5 Estimate Date'}
                                                component={FormDatePicker}
                                                onChange={onD5EstimatedDateChange}
                                                validator={d5EstimatedDateValidator}
                                                disabled={!ideaStatusAllowFormEditing}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <Field
                                                id={'d5ActualDate'}
                                                name={'d5ActualDate'}
                                                label={'COS-5 Actual Date'}
                                                component={FormDatePicker}
                                                disabled={true}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <div className="k-form-field"><p>Impact now achieved, no further incremental
                                                impact</p></div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <DialogActionsBar>
                                    <Button
                                        type='button' color='blue'
                                        className="k-button"
                                        icon labelPosition='left'
                                        onClick={() => {
                                            toggleDialogTimesheet();
                                            toggleDialogIdeaTrackings()
                                        }}>Previous
                                        <Icon name='arrow left'/>

                                    </Button>
                                    <Button
                                        type='button' color='blue'
                                        className="k-button"
                                        disabled={!(MyForm.current.valueGetter('d2EstimatedDate')
                                            && MyForm.current.valueGetter('d3EstimatedDate')
                                            && MyForm.current.valueGetter('d4EstimatedDate')
                                            && MyForm.current.valueGetter('d5EstimatedDate'))
                                        && !checkDateStageValidation()
                                        }
                                        icon labelPosition='right'
                                        onClick={() => {
                                            setGridData(financialImpacts);
                                            toggleDialogTimesheet();
                                            toggleDialogFinancialImpact()
                                        }}>Next
                                        <Icon name='arrow right'/>
                                    </Button>

                                </DialogActionsBar>
                            </Dialog>}
                            {financialImpactDialogVisible && <Dialog
                                width={600}
                                title={"Add Financial Impact"}
                                onClose={() => {
                                    toggleDialogFinancialImpact()
                                }}>
                                <Grid style={{height: "500px", width: "500px"}}>
                                    <Grid.Row>
                                        <Grid.Column width={14}>

                                            <MyContext.Provider value={{enterEdit: enterEdit, itemChange: itemChange}}>
                                                <KendoGrid data={gridData} editField="inEdit" onItemChange={itemChange}
                                                           className="financial-impact">
                                                    <Column field="ind" title="ind" width="0" editable={false}/>
                                                    <Column field="month" title="Date" width="100px"
                                                            format="{0: MMM/yyyy}" editable={false}/>
                                                    <Column field="saving" title="Estimated Saving"
                                                            cell={ValidationCellEstimated}/>
                                                    <Column field="actual" title="Actual Saving"
                                                            width={ideaStatusAllowTasks ? "120px" : "0"}
                                                            cell={ValidationCellActual}/>

                                                </KendoGrid>

                                            </MyContext.Provider>
                                        </Grid.Column>
                                    </Grid.Row>

                                </Grid>
                                <DialogActionsBar>
                                    <Button
                                        type='button' color='blue'
                                        className="k-button"
                                        icon labelPosition='left'
                                        onClick={() => {
                                            toggleDialogTimesheet();
                                            toggleDialogFinancialImpact()
                                        }}>Previous
                                        <Icon name='arrow left'/>
                                    </Button>
                                    {ideaStatusAllowTasks ?
                                        <Button
                                            color='blue'
                                            className="k-button"
                                            icon labelPosition='right'
                                            onClick={() => {
                                                toggleDialogTasksGrid();
                                                toggleDialogFinancialImpact()
                                            }}>Next
                                            <Icon name='arrow right'/>
                                        </Button>
                                        :
                                        <Button
                                            color='blue'
                                            className="k-button"
                                            icon labelPosition='right'
                                            onClick={(formRenderProps: any) => {
                                                MyForm.current.onChange('hiddenField', {value: '1'});
                                                setSendForStatusChange(true);
                                                formRenderProps.onSubmit = true;
                                            }}
                                        >Submit for Status Change
                                            <Icon name='arrow right'/>
                                        </Button>}
                                </DialogActionsBar>
                                {buttonFlag && <LoadingComponent content='Processing Initiative...'/>}
                            </Dialog>}

                            {tasksGridDialogVisible && <Dialog
                                width={900} height={600}
                                title={"Action Plan Tasks"}
                                onClose={() => {
                                    toggleDialogTasksGrid()
                                }}>

                                <KendoGrid data={actionPlanTasksGrid}>
                                    <Column field="ideaId" title="ideaId" width="0"/>
                                    <Column field="title" title="Edit" cell={taskDescription} width="100px"/>
                                    <Column field="description" title="Description" width="250px"/>
                                    <Column field="contributors" title="Contributors" width="150px"/>
                                    <Column field="dueDate" title="Due Date" width="100px" format="{0: dd/MM/yyyy}"/>
                                    <Column field="completedDate" title="Completed Date" width="100px"
                                            format="{0: dd/MM/yyyy}"/>
                                    <Column field="taskOwnerName" title="Task Owner" width="120px"/>
                                </KendoGrid>
                                <DialogActionsBar>
                                    <Button
                                        type='button' color='blue'
                                        className="k-button"
                                        icon labelPosition='left'
                                        onClick={() => {
                                            toggleDialogActionPlan();
                                            toggleDialogTasksGrid()
                                        }}>Add Task
                                        <Icon name='arrow left'/>

                                    </Button>
                                    <Button
                                        color='blue'
                                        className="k-button"
                                        name={'SendForStatusChange'}
                                        icon labelPosition='right'
                                        onClick={(formRenderProps: any) => {
                                            MyForm.current.onChange('hiddenField', {value: '1'});
                                            setSendForStatusChangeTasks(true);
                                            formRenderProps.onSubmit = true;
                                        }}
                                    >Submit for Status Change
                                        <Icon name='arrow right'/>
                                    </Button>

                                </DialogActionsBar>
                                {buttonFlag && <LoadingComponent content='Processing Initiative...'/>}
                            </Dialog>}

                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <div className="k-form-buttons">
                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit || buttonFlag}
                                            >
                                                Save
                                            </Button>
                                            <Button onClick={cancelForm}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column floated={"right"}>
                                        <div className="k-form-buttons">
                                            <Button type='button' icon labelPosition='left'
                                                    onClick={toggleDialogIdeaTrackings} color='green'>
                                                <Icon name='clock outline'/>
                                                History
                                            </Button>
                                            <Button type='button' icon labelPosition='left'
                                                    onClick={toggleDialogTimesheet} color='green'>
                                                <Icon name='history'/>
                                                Stage Timeline
                                            </Button>
                                            <Button type='button' icon labelPosition='right'
                                                    onClick={() => {
                                                        setGridData(financialImpacts)
                                                        toggleDialogFinancialImpact();
                                                    }} color='blue'
                                                    disabled={checkDateStageValidation()}
                                            >
                                                <Icon name='usd'/>
                                                Financial Impact
                                            </Button>

                                            {ideaStatusAllowTasks && <Button type='button' icon labelPosition='right'
                                                                             onClick={toggleDialogTasksGrid}
                                                                             color='blue'>
                                                <Icon name='tasks'/>
                                                Action Plan
                                            </Button>}
                                        </div>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </fieldset>
                    </FormElement>

                )}
            />
            {actionPlanDialogVisible && <Dialog
                width={500} height={600}
                title={"Add Action Plan Task"}
                onClose={() => {
                    setTask(new CreateTaskFormValues());
                    toggleDialogActionPlan()
                }}
            >

                <Segment>
                    <Form
                        initialValues={task}
                        ref={TasksForm}
                        onSubmit={values => handleTaskFormSubmit(values as CreateTaskFormValues)}
                        key={task.id}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset className={'k-form-fieldset'}>
                                    <Field
                                        id={'description'}
                                        name={'description'}
                                        label={'Task Description'}
                                        autoComplete={"off"}
                                        component={FormInput}
                                        validator={titleValidator}
                                        hint={'Hint: Step to be performed by individual as part of realizing savings for this Cost Optimization Initiative.'}
                                        disabled={!ideaStatusAllowUpdatingTaskFields}
                                    />
                                    <Field
                                        id={'contributors'}
                                        name={'contributors'}
                                        label={'Task Contributors'}
                                        rows={3}
                                        component={FormTextArea}
                                        disabled={!ideaStatusAllowUpdatingTaskFields}
                                    />
                                    <Field
                                        id={'taskOwnerId'}
                                        name={'taskOwnerId'}
                                        data={taskOwners}
                                        textField={"displayName"}
                                        label={'Task Owner'}
                                        component={MemoizedFormComboBox}
                                        validator={requiredValidator}
                                        hint={'Hint: Name of person responsible for completing this task. This must be someone with COMPANY email address'}
                                        disabled={!ideaStatusAllowUpdatingTaskFields}
                                    />
                                    <Field
                                        id={'dueDate'}
                                        name={'dueDate'}
                                        label={'Due Date'}
                                        component={FormDatePicker}
                                        validator={requiredValidator}
                                        disabled={!ideaStatusAllowUpdatingTaskFields}
                                    />
                                    <Field
                                        id={'completedDate'}
                                        name={'completedDate'}
                                        label={'Completed Date'}
                                        component={FormDatePicker}
                                        validator={completedDateValidator}
                                        disabled={!ideaStatusAllowUpdatingTaskCompletedDate}
                                    />

                                    <div className="k-form-buttons">
                                        <Button
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit || buttonFlag}
                                        >
                                            Save
                                        </Button>
                                        <Button onClick={() => toggleDialogActionPlan()}>
                                            Cancel
                                        </Button>
                                    </div>
                                    {buttonFlag && <LoadingComponent content='Processing Initiative...'/>}
                                </fieldset>
                            </FormElement>
                        )}
                    />
                </Segment>
                <DialogActionsBar>
                    <Button
                        type='button' color='blue'
                        className="k-button"
                        icon labelPosition='right'
                        onClick={() => {
                            toggleDialogActionPlan();
                            toggleDialogTasksGrid()
                        }}>All Tasks
                        <Icon name='arrow right'/>

                    </Button>

                </DialogActionsBar>
            </Dialog>}

            {confirmationDialogVisible && (
                <Dialog title={"Problem with entered date"} onClose={toggleDialogConfirmation}>
                    <p style={{margin: "25px", textAlign: "center"}}>
                        Please fix stage dates?
                    </p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => {
                            toggleDialogConfirmation();
                            toggleDialogTimesheet();
                        }
                        }>
                            Ok
                        </button>
                    </DialogActionsBar>
                </Dialog>
            )}
        </Segment>
    );
}

export default observer(ActionOwnerForm);