import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartTooltip,
    exportVisual,
} from '@progress/kendo-react-charts';

import {useStore} from '../../../app/stores/store';
import LoadingComponent from "../../../app/layout/LoadingComponent";

import {exportSVG} from '@progress/kendo-drawing';
import {saveAs} from '@progress/kendo-file-saver';
import {Button} from "semantic-ui-react";

export default observer(function ProgressionByNumber() {
    const {ideaStore} = useStore();
    let {
        loadProgressionByNumberReportData, progressionByNumberChartDataWithTotals,
        loadingInitial
    } = ideaStore;

    const refContainer: any = React.useRef();

    const onExportVisual = () => {
        const chartVisual = exportVisual(refContainer.current);
        if (chartVisual) {
            exportSVG(chartVisual).then(dataURI => saveAs(dataURI, 'ProgressionByNumberChart.svg'));
        }
    };

    let todaysDate: Date = new Date();

    useEffect(() => {
        loadProgressionByNumberReportData();
    }, [])


    const series = [
        {field: 'count', categoryField: "month", ideaStatusId: 'Draft', stack: true, note: "total", color: "gray"},
        {field: 'count', categoryField: "month", ideaStatusId: 'COS 2', stack: false, note: "total", color: "green"},
        {field: 'count', categoryField: "month", ideaStatusId: 'COS 3', stack: false, note: "total", color: "yellow"},
        {field: 'count', categoryField: "month", ideaStatusId: 'COS 4', stack: false, note: "total", color: "blue"},
        {field: 'count', categoryField: "month", ideaStatusId: 'COS 5', stack: false, note: "total", color: "red"},
    ]
    const notes: any = {
        label: {
            position: 'inside',
            color: 'black',
            background: 'yellow',
        },
        line: {
            length: 30
        },
        position: 'top',
    };


    if (loadingInitial) return <LoadingComponent content='Generating report...'/>
    return (

        <Fragment>
            <Chart ref={refContainer}>
                <ChartTitle
                    text={todaysDate.toLocaleString()}
                    color="blue"
                    font="10pt sans-serif"
                />
                <ChartTooltip/>
                <ChartLegend position="bottom" orientation="horizontal"/>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem>
                        <ChartCategoryAxisTitle text="Months"/>
                    </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartLegend position="top" orientation="horizontal"/>
                <ChartSeries>
                    {series.map((serie, index, series) => {
                        let seriesData = progressionByNumberChartDataWithTotals.filter(item => item.ideaStatusId === serie.ideaStatusId);
                        if (series.length - 2 === index) {
                            return <ChartSeriesItem type="bar"
                                                    key={serie.ideaStatusId}
                                                    stack={serie.stack}
                                                    data={seriesData}
                                                    field={serie.field}
                                                    categoryField={serie.categoryField}
                                                    name={serie.ideaStatusId}
                                                    noteTextField="total"
                                                    notes={notes}
                                                    color={serie.color}
                                                    labels={{visible: true, position: 'center'}}/>
                        } else {
                            return <ChartSeriesItem type="bar"
                                                    key={serie.ideaStatusId}
                                                    stack={serie.stack}
                                                    data={seriesData}
                                                    field={serie.field}
                                                    categoryField={serie.categoryField}
                                                    name={serie.ideaStatusId}
                                                    color={serie.color}
                                                    labels={{visible: true, position: 'center'}}/>
                        }

                    })}

                </ChartSeries>
            </Chart>
            <Button onClick={() => onExportVisual()}>Export as visual element</Button>
        </Fragment>
    );
})



