import React, {useMemo} from "react";
import {useStore} from '../../../app/stores/store';
import {observer} from "mobx-react-lite";
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import {Button} from '@progress/kendo-react-buttons';
import {Grid, Header} from "semantic-ui-react";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import {MemoizedFormDropDownList} from "../../../app/common/form/MemoizedFormDropDownList";
import {toJS} from "mobx";
import {FormDropDownTree2} from "../../../app/common/form/FormDropDownTree2";
import {FormDropDownTree} from "../../../app/common/form/FormDropDownTree";

export default observer(function ProgressByValueFilters() {
    const MyForm = React.useRef<any>()
    let {
        ideaStore: {
            setPredicate, loadProgressionByValueReportData3,
            filteredBaseLineParameters, filteredSavingCategories,
            filteredGroupLivers, setSelectedDiType, setSelectedGroupLiver
            , setBaseLineParameter
        }
    } = useStore();
    let {adminStore: {diTypes, partyGroups}} = useStore();

    const selectField = 'selected';
    const expandField = 'expanded';
    const dataItemKey = 'partyGroupId';
    const textField = 'groupName';
    const subItemsField = 'items';

    let diTypeData = useMemo(() => diTypes ? toJS(diTypes) : [], [diTypes])
    filteredGroupLivers = useMemo(() => filteredGroupLivers ? toJS(filteredGroupLivers) : [], [filteredGroupLivers])
    filteredBaseLineParameters = useMemo(() => filteredBaseLineParameters ? filteredBaseLineParameters : [], [filteredBaseLineParameters])
    filteredSavingCategories = useMemo(() => filteredSavingCategories ? filteredSavingCategories : [], [filteredSavingCategories])
    let dropDownTreeData = useMemo(() => partyGroups ? toJS(partyGroups) : [], [partyGroups])

    const onDiTypeChange = React.useCallback(
        (event) => {
            const diType = event.value;
            setSelectedDiType(diType);

            MyForm.current.onChange('groupLiverId', {value: null});
            MyForm.current.onChange('baseLineParameterId', {value: null});
            MyForm.current.onChange('savingCategoryId', {value: null});
        },
        [setSelectedDiType]
    );

    const onGroupLiverChange = React.useCallback(
        (event) => {
            const groupLiver = event.value;
            setSelectedGroupLiver(groupLiver);
            MyForm.current.onChange('baseLineParameterId', {value: null});
            MyForm.current.onChange('savingCategoryId', {value: null});
        },
        [setSelectedGroupLiver]
    );

    const onBaseLineParameterChange = React.useCallback(
        (event) => {
            const baseLineParameter = event.value;
            setBaseLineParameter(baseLineParameter);
            MyForm.current.onChange('savingCategoryId', {value: null});

        },
        [setBaseLineParameter]
    );
    const handleSubmit = (dataItem: any) => {

        if (Object.keys(dataItem.values).length > 0) {
            if (dataItem.values.diTypeId !== null && dataItem.values.diTypeId !== undefined) {
                setPredicate("diTypeId", dataItem.values.diTypeId);
            } else {
                setPredicate("diTypeId", "empty");
            }
            if (dataItem.values.groupLiverId !== null && dataItem.values.groupLiverId != undefined) {
                setPredicate("groupLiverId", dataItem.values.groupLiverId);
            } else {
                setPredicate("groupLiverId", "empty");
            }
            if (dataItem.values.baseLineParameterId !== null && dataItem.values.baseLineParameterId !== undefined) {
                setPredicate("baseLineParameterId", dataItem.values.baseLineParameterId);
            } else {
                setPredicate("baseLineParameterId", "empty");
            }
            if (dataItem.values.savingCategoryId !== null && dataItem.values.savingCategoryId !== undefined) {
                setPredicate("savingCategoryId", dataItem.values.savingCategoryId);
            } else {
                setPredicate("savingCategoryId", "empty");
            }
            if (dataItem.values.partyGroupId !== null && dataItem.values.partyGroupId !== undefined) {
                setPredicate("partyGroupId", dataItem.values.partyGroupId);
            } else {
                setPredicate("partyGroupId", "empty");
            }

            loadProgressionByValueReportData3();
        } else {
            setPredicate("diTypeId", "empty");
            setPredicate("groupLiverId", "empty");
            setPredicate("baseLineParameterId", "empty");
            setPredicate("savingCategoryId", "empty");
            setPredicate("partyGroupId", "empty");

            loadProgressionByValueReportData3();
        }


    };

    return (
        <>

            <Form
                onSubmitClick={handleSubmit}
                ref={MyForm}
                render={(formRenderProps) => (
                    <Grid>
                        <Grid.Column floated='left' width={16}>
                            <FormElement>
                                <Grid columns={14}>
                                    <Header floated='left' icon='search' content='Filter Initiatives'/>
                                    <Grid.Row columns={5}>
                                        <Grid.Column width={3}>
                                            <Field
                                                id={'diTypeId'}
                                                name={'diTypeId'}
                                                textField={"diTypeDescription"}
                                                label={'COI Type'}
                                                data={diTypeData}
                                                onChange={onDiTypeChange}
                                                component={MemoizedFormDropDownList}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <Field
                                                id={'groupLiverId'}
                                                name={'groupLiverId'}
                                                textField={"groupLiverDescription"}
                                                label={'Group Lever'}
                                                data={filteredGroupLivers}
                                                onChange={onGroupLiverChange}
                                                component={MemoizedFormDropDownList}
                                                disabled={!formRenderProps.valueGetter('diTypeId')}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <Field
                                                id={'baseLineParameterId'}
                                                name={'baseLineParameterId'}
                                                textField={"baseLineParameterDescription"}
                                                label={'Baseline Parameter'}
                                                data={filteredBaseLineParameters}
                                                onChange={onBaseLineParameterChange}
                                                component={MemoizedFormDropDownList}
                                                disabled={!formRenderProps.valueGetter('groupLiverId')}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <Field
                                                id={'savingCategoryId'}
                                                name={'savingCategoryId'}
                                                textField={"savingCategoryDescription"}
                                                label={'Saving Category'}
                                                data={filteredSavingCategories}
                                                component={MemoizedFormDropDownList}
                                                disabled={!formRenderProps.valueGetter('baseLineParameterId')}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Field
                                                id={'partyGroupId'}
                                                name={'partyGroupId'}
                                                data={dropDownTreeData}
                                                label={"Saving in Department"}
                                                textField={textField}
                                                dataItemKey={dataItemKey}
                                                selectField={selectField}
                                                expandField={expandField}
                                                subItemsField={subItemsField}
                                                component={FormDropDownTree}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>


                                </Grid>

                                <div className="k-form-buttons">
                                    <Button
                                        primary={true}
                                        type={'submit'}

                                    >
                                        Search
                                    </Button>
                                    <Button onClick={formRenderProps.onFormReset}>
                                        Clear
                                    </Button>
                                </div>
                            </FormElement>
                        </Grid.Column>

                    </Grid>

                )}
            />
        </>

    )
})