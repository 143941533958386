export interface ActionPlanTask {
    id: number;
    ideaId: string;
    description: string;
    contributors: string;
    completedDate: Date;
    createdDate: Date;
    dueDate: Date;
    taskOwnerId: string;
    taskOwnerName: string;
}


export class ActionPlanTask implements ActionPlanTask {
    constructor(init?: CreateTaskFormValues) {
        Object.assign(this, init);
    }
}

export class CreateTaskFormValues {
    id: number = 0;
    ideaId: string | undefined = '';
    description: string | undefined = '';
    contributors?: string | undefined = '';
    completedDate: Date | null = null;
    dueDate: Date = new Date();
    createdDate: Date = new Date();
    taskOwnerId: string | undefined = '';
    taskOwnerName: string | undefined = '';

    constructor(id?: number, ideaId?: string, description?: string, contributors?: string,
                completedDate?: Date, dueDate?: Date, taskOwnerId?: string, taskOwnerName?: string) {
        if (id) {
            this.id = id;
            this.ideaId = ideaId;
            this.description = description;
            // @ts-ignore
            this.completedDate = completedDate;
            // @ts-ignore
            this.dueDate = dueDate;
            this.taskOwnerId = taskOwnerId;
            this.taskOwnerName = taskOwnerName;
            this.contributors = contributors;
            this.createdDate = new Date();
        }
    }
}


export class ActionPlanTaskGrid {
    id: number;
    ideaId: string;
    description: string;
    contributors?: string | undefined;
    completedDate: Date | null;
    dueDate: Date;
    taskOwnerId: string;
    taskOwnerName: string;

    constructor(id: number, ideaId: string | undefined, description: string | undefined, contributors: string | undefined,
                completedDate: Date | null, dueDate: Date, taskOwnerId: string | undefined, taskOwnerName: string | undefined) {
        this.id = id;
        this.ideaId = ideaId!;
        this.description = description!;
        this.contributors = contributors;
        this.completedDate = completedDate;
        this.dueDate = dueDate;
        this.taskOwnerId = taskOwnerId!;
        this.taskOwnerName = taskOwnerName!;
    }
}