import {CreateIdeaFormValues, Idea,} from "../models/idea";
import {progressionByNumber} from "../models/progressionByNumber";
import {history} from "../..";
import {toast} from "react-toastify";
import axios, {AxiosError, AxiosResponse} from 'axios';
import {PaginatedResult} from '../models/pagination';
import {User, UserFormValues} from '../models/user';
import {UserRole} from '../models/userRole';
import {store} from '../stores/store';
import {cosSummaryReportByDepartment} from "../models/cosSummaryReportByDepartment";
import {cosPotentialImpactReport} from "../models/cosPotentialImpactReport";
import {departmentAndAffiliate} from "../models/departmentAndAffiliate";
import {CreateCurrencyFormValues, Currency} from "../models/currency";
import {cosUtilizationReportData} from "../models/cosUtilizationReportData";
import {cosTop10ReportData} from "../models/cosTop10ReportData";
import {savePerBusinessReportData} from "../models/savePerBusinessReportData";
import {savePerDepartmentReportData} from "../models/savePerDepartmentReportData";
import {savePerTypeReportData} from "../models/savePerTypeReportData";
import {CreateDiTypeFormValues, DiType} from "../models/diType";
import {savePerGroupLeverReportData} from "../models/savePerGroupLeverReportData";
import {CreateGroupLiverFormValues, GroupLiver} from "../models/groupLiver";
import {savePerSavingCategoryReportData} from "../models/savePerSavingCategoryReportData";
import {CreateSavingCategoryFormValues, SavingCategory} from "../models/savingCategory";
import {progressionByValueReportData} from "../models/progressionByValueReportData";
import {CreateRejectionReasonFormValues, RejectionReason} from "../models/rejectionReason";
import {AppUser, CreateAppUserFormValues} from "../models/appUser";
import {BaseLineParameter, CreateBaseLineParameterFormValues} from "../models/baseLineParameter";
import {CreatePartyGroupFormValues, PartyGroup} from "../models/partyGroup";
import {DiRole} from "../models/diRole";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config;
})

axios.interceptors.response.use(async response => {
    const pagination = response.headers['pagination'];
    if (pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }
    return response;
}, (error: AxiosError) => {
    const {data, status, config, headers} = error.response!;
    switch (status) {
        case 400:
            if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                history.push('/not-found');
            }
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key])
                    }
                }
                throw modalStateErrors.flat();
            } else {
                toast.error(data);
            }
            break;
        case 401:
            if (status === 401 && headers['www-authenticate']?.startsWith('Bearer error="invalid_token"')) {
                store.userStore.logout();
                toast.error('Session expired - please login again');
            }
            break;
        case 404:
            history.push('/not-found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            history.push('/server-error');
            break;
    }
    return Promise.reject(error);
})

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}


const Ideas = {
    listOriginatorIdeas: (params: URLSearchParams) => axios.get<PaginatedResult<Idea[]>>('/ideas', {params})
        .then(responseBody),
    listVetterIdeas: (params: URLSearchParams) => axios.get<PaginatedResult<Idea[]>>('/ideas/vetter', {params})
        .then(responseBody),
    listActionOwnerIdeas: (params: URLSearchParams) => axios.get<PaginatedResult<Idea[]>>('/ideas/actionOwner', {params})
        .then(responseBody),
    listActionControllerIdeas: (params: URLSearchParams) => axios.get<PaginatedResult<Idea[]>>('/ideas/actionController', {params})
        .then(responseBody),
    getProgressionByNumberReportData: (params: URLSearchParams) => axios.get<PaginatedResult<progressionByNumber[]>>('/ideas/progressionByNumber', {params})
        .then(responseBody),
    getProgressionByValueReportData: (params: URLSearchParams) => axios.get<progressionByValueReportData[]>('/ideas/progressionByValue', {params})
        .then(responseBody),
    getSavingPerBusinessReportData: (params: URLSearchParams) => axios.get<PaginatedResult<savePerBusinessReportData[]>>('/ideas/savingPerBusiness', {params})
        .then(responseBody),
    getSavingPerDepartmentReportData: (params: URLSearchParams) => axios.get<PaginatedResult<savePerDepartmentReportData[]>>('/ideas/savingPerDepartment', {params})
        .then(responseBody),
    getSavingPerTypeReportData: (params: URLSearchParams) => axios.get<PaginatedResult<savePerTypeReportData[]>>('/ideas/savingPerType', {params})
        .then(responseBody),
    getSavingPerGroupLeverReportData: (params: URLSearchParams) => axios.get<PaginatedResult<savePerGroupLeverReportData[]>>('/ideas/savingPerGroupLever', {params})
        .then(responseBody),
    getSavingPerSavingCategoryReportData: (params: URLSearchParams) => axios.get<PaginatedResult<savePerSavingCategoryReportData[]>>('/ideas/savingPerSavingCategory', {params})
        .then(responseBody),
    getCosSummaryReportData: (params: URLSearchParams) => axios.get<PaginatedResult<cosSummaryReportByDepartment[]>>('/ideas/cosSummaryReport', {params})
        .then(responseBody),
    getCosPotentialImpactReportData: (params: URLSearchParams) => axios.get<PaginatedResult<cosPotentialImpactReport[]>>('/ideas/cosPotentialImpactReport', {params})
        .then(responseBody),
    getCosTop10ReportData: (params: URLSearchParams) => axios.get<PaginatedResult<cosTop10ReportData[]>>('/ideas/cosTop10Report', {params})
        .then(responseBody),
    getCosUtilizationReportData: (params: URLSearchParams) => axios.get<cosUtilizationReportData>('/ideas/cosUtilizationReport', {params})
        .then(responseBody),
    createIdea: (idea: CreateIdeaFormValues) => requests.post("/ideas", idea),
    updateIdeaAsActionOwner: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/${idea.ideaId}/editActionOwner`, idea),
    updateIdeaAsActionController: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/${idea.ideaId}/editActionController`, idea),
    sendForStatusUpdate: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/${idea.ideaId}/sendForStatusUpdate`, idea),
    updateIdeaAsVetter: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/${idea.ideaId}/editVetter`, idea),
    updateIdeaAsOriginator: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/${idea.ideaId}/editOriginator`, idea),
    vetterRejectIdea: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/${idea.ideaId}/vetterReject`, idea),
    vetterApproveIdea: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/${idea.ideaId}/vetterApprove`, idea),
    assignedIdeaToAnotherVetter: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/${idea.ideaId}/assignVetter`, idea),
    controllerRejectIdea: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/${idea.ideaId}/controllerReject`, idea),
    controllerApproveIdea: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/controllerApprove`, idea),
    controllerApproveIdeaToCos2: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/controllerApproveIdeaToCos2`, idea),
    controllerApproveIdeaToCos3: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/controllerApproveIdeaToCos3`, idea),
    controllerApproveIdeaToCos4: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/controllerApproveIdeaToCos4`, idea),
    controllerApproveIdeaToCos5: (idea: CreateIdeaFormValues) => requests.put<void>(`/ideas/controllerApproveIdeaToCos5`, idea),
    details: (ideaId: string) => requests.get<Idea>(`/ideas/${ideaId}/vetter`),

};


const Users = {
    listUsers: () => requests.get<AppUser[]>("/users/users"),
    listAllUsers: () => requests.get<AppUser[]>("/users/allUsers"),
    listTaskOwners: () => requests.get("/users/taskOwners"),
    listPossibleVetters: (partyGroupId: string) => requests.get(`/users/${partyGroupId}/possibleVetters`),
    ListActionControllers: (partyGroupId: string) => requests.get(`/users/${partyGroupId}/actionControllers`),
    GetUserDepartment: () => requests.get("/users/userDepartment"),
    createAppUser: (appUser: CreateAppUserFormValues) => requests.post("/users", appUser),
    editAppUser: (appUser: CreateAppUserFormValues) => requests.put<void>(`users/editAppUser`, appUser),
};

const DiTypes = {
    list: () => requests.get<DiType[]>("/diTypes"),
    createDiType: (diType: CreateDiTypeFormValues) => requests.post("/diTypes", diType),
    editDiType: (diType: CreateDiTypeFormValues) => requests.put<void>(`diTypes/editDiType`, diType),

};

const DiRoles = {
    list: () => requests.get<DiRole[]>("/diRoles"),
};

const RejectionReasons = {
    list: () => requests.get<RejectionReason[]>("/rejectionReasons"),
    createRejectionReason: (rejectionReason: CreateRejectionReasonFormValues) => requests.post("/rejectionReasons", rejectionReason),
    editRejectionReason: (rejectionReason: CreateRejectionReasonFormValues) => requests.put<void>(`rejectionReasons/editRejectionReason`, rejectionReason),

};

const PartyGroups = {
    list: () => requests.get<PartyGroup[]>("/partyGroups"),
    listPartyGroups: () => requests.get<PartyGroup[]>("/listPartyGroups"),
    ListAffiliates: () => requests.get("/partyGroups/listAffiliates"),
    ListDepartmentsAndAffiliates: () => requests.get<departmentAndAffiliate[]>("/partyGroups/departmentsAndAffiliates"),
    createPartyGroup: (partyGroup: CreatePartyGroupFormValues) => requests.post("/partyGroups", partyGroup),
    editPartyGroup: (partyGroup: CreatePartyGroupFormValues) => requests.put<void>(`partyGroups/editPartyGroup`, partyGroup),

};

const GroupLivers = {
    list: () => requests.get<GroupLiver[]>("/groupLivers"),
    createGroupLiver: (groupLiver: CreateGroupLiverFormValues) => requests.post("/groupLivers", groupLiver),
    editGroupLiver: (groupLiver: CreateGroupLiverFormValues) => requests.put<void>(`groupLivers/editGroupLiver`, groupLiver),
};

const BaseLineParameters = {
    list: () => requests.get<BaseLineParameter[]>("/baseLineParameters"),
    createBaseLineParameter: (baseLineParameter: CreateBaseLineParameterFormValues) => requests.post("/baseLineParameters", baseLineParameter),
    editBaseLineParameter: (baseLineParameter: CreateBaseLineParameterFormValues) => requests.put<void>(`baseLineParameters/editBaseLineParameter`, baseLineParameter),

};

const SavingCategories = {
    list: () => requests.get<SavingCategory[]>("/savingCategories"),
    createSavingCategory: (savingCategory: CreateSavingCategoryFormValues) => requests.post("/savingCategories", savingCategory),
    editSavingCategory: (savingCategory: CreateSavingCategoryFormValues) => requests.put<void>(`savingCategories/editSavingCategory`, savingCategory),
};

const Currencies = {
    createCurrency: (currency: CreateCurrencyFormValues) => requests.post("/currencies", currency),
    editCurrency: (currency: CreateCurrencyFormValues) => requests.put<void>(`currencies/editCurrency`, currency),
    list: () => requests.get<Currency[]>("/currencies"),
};

const PartyGroupRoles = {
    //list: () => axios.get<UserRole[]>('/partyGroupRoles').then(responseBody),
    list: () => requests.get<UserRole[]>("/partyGroupRoles"),

};

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/account/register', user)
}

export default {
    Account,
    Ideas,
    PartyGroups,
    DiTypes,
    Currencies,
    GroupLivers,
    BaseLineParameters,
    SavingCategories,
    PartyGroupRoles,
    RejectionReasons,
    Users,
    DiRoles
};
