import React, {useEffect, useMemo, useState} from 'react';
import {Button, Grid, Segment} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import FormInput from '../../../app/common/form/FormInput';
import {useStore} from '../../../app/stores/store';
import {useHistory} from 'react-router-dom';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {toJS} from "mobx";
import {MemoizedFormDropDownList} from "../../../app/common/form/MemoizedFormDropDownList";
import {CreateSavingCategoryFormValues} from "../../../app/models/savingCategory";


const SavingCategoriesForm = () => {
    const history = useHistory();
    const {ideaStore, adminStore} = useStore();


    const MyForm = React.useRef<any>()

    let {loadingInitial, filteredGroupLivers, filteredBaseLineParameters} = ideaStore;

    let {
        createSavingCategory, savingCategoryLoaded,
        setSavingCategoryLoaded, selectedSavingCategory, clearSelectedSavingCategory, editSavingCategory, diTypes
    } = adminStore;

    let diTypeData = useMemo(() => diTypes ? toJS(diTypes) : [], [diTypes])
    filteredGroupLivers = useMemo(() => filteredGroupLivers ? toJS(filteredGroupLivers) : [], [filteredGroupLivers])
    filteredBaseLineParameters = useMemo(() => filteredBaseLineParameters ? filteredBaseLineParameters : [], [filteredBaseLineParameters])


    const [savingCategory, setSavingCategory] = useState<CreateSavingCategoryFormValues>(new CreateSavingCategoryFormValues());
    const [buttonFlag, setButtonFlag] = useState(false);

    useEffect(() => {
        setSavingCategory(new CreateSavingCategoryFormValues(selectedSavingCategory));
    }, [selectedSavingCategory]);


    const cancelForm = () => {
        history.push(`/ideas/`);
    }
    
    const clearForm = () => {
        setSavingCategory(new CreateSavingCategoryFormValues());
        clearSelectedSavingCategory();
        setSavingCategoryLoaded(false);
    }
    const handleFormSubmit = (savingCategory: CreateSavingCategoryFormValues) => {
        setButtonFlag(true)
        if (!savingCategoryLoaded) {
            createSavingCategory(savingCategory);
        } else {
            setSavingCategoryLoaded(false)
            editSavingCategory(savingCategory);
        }
        clearForm()
    };

    const requiredValidator = (value: any, getter: any, fieldProps: any) =>
        value
            ? ''
            : fieldProps.name == 'baseLineParameterId' ? "Error: This field BaseLine Parameter Id is required."
                : fieldProps.name == 'baseLineParameterDescription' ? "Error: This field BaseLine Parameter Description is required."
                    : fieldProps.name == 'diTypeId' ? "Error: This field COI Type is required."
                        : fieldProps.name == 'groupLiverId' ? "Error: This field Group Lever is required."
                            : "Error: This field is required.";

    if (loadingInitial) return <LoadingComponent content='Loading currency...'/>

    return (
        <>
            <Grid>
                <Grid.Column width={12}>
                    <Segment clearing>
                        <Form
                            initialValues={savingCategory}
                            ref={MyForm}
                            onSubmit={values => handleFormSubmit(values as CreateSavingCategoryFormValues)}
                            key={savingCategory.savingCategoryId}
                            render={(formRenderProps) => (

                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                {/*<Grid.Column>
                                                    <Field
                                                        id={'savingCategoryId'}
                                                        name={'savingCategoryId'}
                                                        label={'Saving Category Id*'}
                                                        component={FormInput}
                                                        autoComplete={"off"}
                                                        validator={requiredValidator}
                                                        disabled={savingCategoryLoaded}
                                                        hint={'Hint: Saving Category Id.'}
                                                    />
                                                </Grid.Column>*/}
                                                <Grid.Column>
                                                    <Field
                                                        id={'savingCategoryDescription'}
                                                        name={'savingCategoryDescription'}
                                                        label={'Saving Category Description *'}
                                                        component={FormInput}
                                                        autoComplete={"off"}
                                                        validator={requiredValidator}
                                                        hint={'Hint: Saving Category Description.'}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={'diTypeId'}
                                                        name={'diTypeId'}
                                                        textField={"diTypeDescription"}
                                                        label={'COI Type *'}
                                                        data={diTypeData}
                                                        component={MemoizedFormDropDownList}
                                                        validator={requiredValidator}
                                                        disabled={savingCategoryLoaded}
                                                        hint={'Hint: Select COI Type.'}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={'groupLiverId'}
                                                        name={'groupLiverId'}
                                                        textField={"groupLiverDescription"}
                                                        label={'Group Lever *'}
                                                        data={filteredGroupLivers}
                                                        component={MemoizedFormDropDownList}
                                                        validator={requiredValidator}
                                                        disabled={savingCategoryLoaded}
                                                        hint={'Hint: Select group lever.'}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Field
                                                        id={'baseLineParameterId'}
                                                        name={'baseLineParameterId'}
                                                        textField={"baseLineParameterDescription"}
                                                        label={'Baseline Parameter *'}
                                                        data={filteredBaseLineParameters}
                                                        component={MemoizedFormDropDownList}
                                                        validator={requiredValidator}
                                                        disabled={savingCategoryLoaded}
                                                        hint={'Hint: Select baseline parameter.'}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>


                                        <div className="k-form-buttons">
                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {savingCategoryLoaded ? "Update Saving Category" : "Add New Saving Category"}
                                            </Button>
                                            <Button onClick={cancelForm}>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => {
                                                clearForm()
                                            }
                                            }>
                                                Clear
                                            </Button>
                                        </div>

                                        {buttonFlag && loadingInitial &&
                                        <LoadingComponent content='Processing Saving Category...'/>}
                                    </fieldset>

                                </FormElement>

                            )}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>

        </>


    );
}

export default observer(SavingCategoriesForm);


