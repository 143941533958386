import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from '../../../app/stores/store';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
    Grid,
    GridColumn as Column,
    GridDetailRowProps,
    GridExpandChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-grid";
import {cosSummaryReportByDepartment} from "../../../app/models/cosSummaryReportByDepartment";
import {Header} from "semantic-ui-react";
import {ExcelExport, KendoOoxml} from "@progress/kendo-react-excel-export";
import {saveAs} from "@progress/kendo-file-saver";

export default observer(function CosSummary() {
    const {ideaStore} = useStore();
    const [departments, setDepartments] = React.useState<cosSummaryReportByDepartment[]>([]);

    let {
        loadCosSummaryReportData, cosSummaryByAffiliateChartData,
        loadingInitial, cosSummaryByDepartmentChartData
    } = ideaStore;
    let todaysDate: Date = new Date();
    const expandChange = (event: GridExpandChangeEvent) => {
        event.dataItem.expanded = event.value;
        let affiliate = event.dataItem.affiliate;
        setDepartments([...cosSummaryByDepartmentChartData.filter(item => item.affiliate === affiliate)]);

        if (!event.value) {
            return;
        }

    };

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {

            // @ts-ignore
            let workbook = _export.current!.workbookOptions();
            let rows = workbook.sheets[0].rows;
            let headerOptions = rows[0].cells[0];

            for (let idx = cosSummaryByAffiliateChartData.length - 1; idx >= 0; idx--) {
                let departments = cosSummaryByDepartmentChartData.filter(
                    (element) => element.affiliate === cosSummaryByDepartmentChartData[idx].affiliate
                );
                for (
                    let deptIdx = departments.length - 1;
                    deptIdx >= 0;
                    deptIdx--
                ) {
                    const department = departments[deptIdx];
                    rows.splice(idx + 2, 0, {
                        cells: [
                            {},
                            {value: department.department},
                            {value: department.estimated},
                            {value: department.actual},
                        ],
                    });
                }
                // Add the header for every details Grid
                rows.splice(idx + 2, 0, {
                    cells: [
                        {},
                        Object.assign({}, headerOptions, {value: "Department"}),
                        Object.assign({}, headerOptions, {value: "Estimated"}),
                        Object.assign({}, headerOptions, {value: "Actual"}),
                    ],
                });

            }

            new KendoOoxml.Workbook(workbook).toDataURL().then((dataUrl) => {
                saveAs(dataUrl, "CosSummaryByDepartment.xlsx");

            })
        }
    }

    useEffect(() => {
        loadCosSummaryReportData();
        setDepartments(cosSummaryByDepartmentChartData)
    }, [])

    const DetailComponent = (props: GridDetailRowProps) => {
        return (
            <Grid
                data={departments}
                style={{height: "550px"}}>
                <Column field="department" title="Department" width="120px"/>
                <Column field="estimated" title="Estimated"/>
                <Column field="actual" title="Actual"/>
            </Grid>
        );
    };

    if (loadingInitial) return <LoadingComponent content='Generating report...'/>
    return (

        <Fragment>
            <ExcelExport data={cosSummaryByAffiliateChartData}
                         ref={_export}>
                <Grid
                    style={{height: "400px"}}
                    data={cosSummaryByAffiliateChartData}
                    detail={DetailComponent}
                    expandField="expanded"
                    onExpandChange={expandChange}
                >
                    <GridToolbar>
                        <button title="Export Excel" className="k-button k-primary" onClick={excelExport}>
                            Export to Excel
                        </button>
                    </GridToolbar>
                    <Column field="affiliate" title="Affiliate" width="120px"/>
                    <Column field="estimated" title="Estimated"/>
                    <Column field="actual" title="Actual"/>
                </Grid>
            </ExcelExport>
            <Header as='h4' color='orange'>
                Report values are in Thousands Saudi Riyals
            </Header>
            <Header as='h5' color='blue'>
                {todaysDate.toLocaleString()}
            </Header>
        </Fragment>
    );
})







