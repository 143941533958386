import React from "react";
import {Segment, Tab} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import ProgressionByNumber from "./ProgressionByNumber";
import ProgressionByValue from "./ProgressionByValue";
import ProgressByNumberFilters from "./ProgressByNumberFilters";
import ProgressByValueFilters from "./ProgressByValueFilters";
import CosSummaryFilters from "./CosSummaryFilters";
import CosSummary from "./CosSummary";
import CosPotentialImpactFilters from "./CosPotentialImpactFilters";
import CosPotentialImpact from "./CosPotentialImpact";
import Top10Filters from "./Top10Filters";
import CosUtilization from "./CosUtilization";
import CosTop10List from "./CosTop10List";
import CosTop10Chart from "./CosTop10Chart";
import SavingPerBusinessFilters from "./SavingPerBusinessFilters";
import SavingPerBusiness from "./SavingPerBusiness";
import SavingPerDepartmentFilters from "./SavingPerDepartmentFilters";
import SavingPerDepartment from "./SavingPerDepartment";
import SavingPerTypeFilters from "./SavingPerTypeFilters";
import SavingPerType from "./SavingPerType";
import SavingPerGroupLever from "./SavingPerGroupLever";
import SavingPerGroupLeverFilters from "./SavingPerGroupLeverFilters";
import SavingPerSavingCategoriesFilters from "./SavingPerSavingCategoriesFilters";
import SavingPerSavingCategories from "./SavingPerSavingCategories";
import {Logger} from "./console-logger";

export default observer(function ReportsDashboard() {
    const [events, setEvents] = React.useState([]);

    const onEvent = (eventName: never) => {
        setEvents([...events, eventName]);
    };

    const panes =
        [
            {
                menuItem: {key: 'progressionByNumber', content: 'COS Progression by Number'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <ProgressByNumberFilters/>
                    </Segment>
                    <Segment basic vertical>
                        <ProgressionByNumber/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'progressionByValue', content: 'COS Progression by Value'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <ProgressByValueFilters/>
                    </Segment>
                    <Segment basic vertical>
                        <ProgressionByValue onEvent={onEvent}/>
                        <Logger title="Event log" events={events}/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'cosSummary', content: 'COS Summary by Company'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <CosSummaryFilters/>
                    </Segment>
                    <Segment basic vertical>
                        <CosSummary/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'cosPotentialImpact', content: 'COS Potential Impact'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <CosPotentialImpactFilters/>
                    </Segment>
                    <Segment basic vertical>
                        <CosPotentialImpact/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'cosUtilization', content: 'COS Utilization'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <CosUtilization/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'cosTop10List', content: 'Top 10 COS List'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <Top10Filters/>
                    </Segment>
                    <Segment basic vertical>
                        <CosTop10List/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'cosTop10Chart', content: 'Top 10 COS Chart'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <Top10Filters/>
                    </Segment>
                    <Segment basic vertical>
                        <CosTop10Chart/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'savingPerBusinessChart', content: 'Saving Per Busniess'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <SavingPerBusinessFilters/>
                    </Segment>
                    <Segment basic vertical>
                        <SavingPerBusiness/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'savingPerDepartmentChart', content: 'Saving Per Department'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <SavingPerDepartmentFilters/>
                    </Segment>
                    <Segment basic vertical>
                        <SavingPerDepartment/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'savingPerTypeChart', content: 'Saving Per COI Type'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <SavingPerTypeFilters/>
                    </Segment>
                    <Segment basic vertical>
                        <SavingPerType/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'savingPerGroupLeverChart', content: 'Saving Per Group Lever'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <SavingPerGroupLeverFilters/>
                    </Segment>
                    <Segment basic vertical>
                        <SavingPerGroupLever/>
                    </Segment>
                </Tab.Pane>,
            },
            {
                menuItem: {key: 'savingPerSavingCategoryChart', content: 'Saving Per Saving Category'},
                render: () => <Tab.Pane>
                    <Segment basic vertical>
                        <SavingPerSavingCategoriesFilters/>
                    </Segment>
                    <Segment basic vertical>
                        <SavingPerSavingCategories/>
                    </Segment>
                </Tab.Pane>,
            },
        ]


    return (
        <Tab menu={{fluid: true, vertical: true}} menuPosition='right' panes={panes}/>

    );
})

//<Header as='h3' image={reportsLogo} content='COS Reports' />

/*<Grid>
<Grid.Column  width={16}>
    <Tab  menu={{ fluid: true, vertical: true  }} menuPosition='right' panes={panes} />
</Grid.Column>
</Grid>*/
