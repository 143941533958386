import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Chart,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
    exportVisual,
    RenderEvent
} from '@progress/kendo-react-charts';

import {useStore} from '../../../app/stores/store';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {Button, Header} from "semantic-ui-react";
import {exportSVG, Group, Path, Text} from "@progress/kendo-drawing";
import {saveAs} from "@progress/kendo-file-saver";


export default observer(function SavingPerSavingCategories() {
    const {ideaStore} = useStore();
    let {
        loadSavingPerSavingCategoryReportData, savePerSavingCategoryChartData,
        loadingInitial
    } = ideaStore;

    const refContainer: any = React.useRef();
    let savingCategories: any = [];
    let percentages: any = [];
    const onExportVisual = () => {
        const chartVisual = exportVisual(refContainer.current);
        if (chartVisual) {
            exportSVG(chartVisual).then(dataURI => saveAs(dataURI, 'SavingPerSavingCategories.svg'));
        }
    };

    let todaysDate: Date = new Date();

    useEffect(() => {
        loadSavingPerSavingCategoryReportData();
    }, [])

    savePerSavingCategoryChartData.map((i, index, series) => {
        savingCategories.push(i.savingCategory)
        percentages.push(i.percent)
    })

    percentages.sort((a: any, b: any) => a - b);

    const notes: any = {
        label: {
            position: 'inside',
            color: 'black',
            background: 'yellow',
        },
        line: {
            length: 30
        },
        position: 'top',
    };

    const plotValue = 80;

    const seriesLabelsBars: any = {
        visible: true, // Note that visible defaults to false
        padding: 3,
        font: "bold 16px Arial, sans-serif",
        format: '{0:n2}%',
        color: 'blue',
        position: 'outsideEnd'
    };

    const seriesLabelsLine: any = {
        visible: true, // Note that visible defaults to false
        padding: 3,
        font: "bold 16px Arial, sans-serif",
        format: '{0:n2}%',
        color: 'red',
        position: 'left', content: (e: any) => {
            let index = savingCategories.indexOf(e.category);
            if (index % 2 === 0) {
                return e.value
            }
            return ''
        }
    };

    const onRender = (args: RenderEvent) => {
        const chart = args.target.chartInstance;

        if (!chart) {
            return;
        }

        // get the axes
        const valueAxis = chart.findAxisByName("valueAxis");
        const categoryAxis = chart.findAxisByName("categoryAxis");

        // get the coordinates of the value at which the plot band will be rendered
        const valueSlot = valueAxis.slot(plotValue);

        // get the coordinates of the entire category axis range
        const range = categoryAxis.range();
        const categorySlot = categoryAxis.slot(range.min, range.max);

        // draw the plot band based on the found coordinates
        const line = new Path({
            stroke: {
                color: "red",
                width: 3
            }
        })
            .moveTo(valueSlot.origin)
            .lineTo(categorySlot.topRight().x, valueSlot.origin.y);

        const label = new Text('80% Cut Off', [0, 0], {
            fill: {
                color: "black"
            },
            font: "14px sans"
        });
        const bbox = label.bbox();
        label.position([categorySlot.topRight().x - bbox.size.width, valueSlot.origin.y - bbox.size.height]);

        const group = new Group();
        group.append(line, label);

        // draw on the surface
        chart.surface.draw(group);
    }

    if (loadingInitial) return <LoadingComponent content='Generating report...'/>
    return (

        <Fragment>
            <Chart ref={refContainer} onRender={onRender}>
                <ChartTitle
                    text={todaysDate.toLocaleString()}
                    color="blue"
                    font="10pt sans-serif"
                />
                <ChartTooltip/>
                <ChartLegend position="bottom" orientation="horizontal"/>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem categories={savingCategories} labels={{rotation: -70}}
                                           name="categoryAxis">
                        <ChartCategoryAxisTitle text="Saving Categories"/>
                    </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartLegend position="top" orientation="horizontal"/>
                <ChartAxisDefaults labels={{format: '{0:n2}%'}}/>
                <ChartValueAxis>
                    <ChartValueAxisItem name="valueAxis" majorGridLines={{visible: false}}/>
                </ChartValueAxis>
                <ChartSeries>
                    <ChartSeriesItem type="column" data={savePerSavingCategoryChartData} field="saving"
                                     labels={seriesLabelsBars}/>
                    <ChartSeriesItem type="line" data={percentages} labels={seriesLabelsLine} color="black"/>


                </ChartSeries>
            </Chart>
            <Button onClick={() => onExportVisual()}>Export as visual element</Button>
            <Header as='h4' color='orange'>
                Report values are in Thousands Saudi Riyals
            </Header>
        </Fragment>
    );
})







