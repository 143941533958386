export interface Currency {
    currencyId: number;
    currencyDescription: string;
    exchangeRate: number;
    createdStamp: string
    createdBy: string
}

export class CreateCurrencyFormValues {

    currencyId: number = 0;
    currencyDescription: string = '';
    exchangeRate: number = 0;
    createdStamp: string = '';
    createdBy: string = ''

    constructor(currency?: CreateCurrencyFormValues) {
        if (currency) {
            this.currencyId = currency.currencyId;
            this.currencyDescription = currency.currencyDescription
            this.exchangeRate = currency.exchangeRate;
            this.createdStamp = currency.createdStamp
            this.createdBy = currency.createdBy
        }
    }
}
