import React, {useEffect, useState} from 'react';
import {Button, Grid, Segment} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import FormInput from '../../../app/common/form/FormInput';
import {useStore} from '../../../app/stores/store';
import {useHistory} from 'react-router-dom';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {CreateCurrencyFormValues} from "../../../app/models/currency";
import FormNumericTextBox from "../../../app/common/form/FormNumericTextBox";


const CurrenciesForm = () => {
    const history = useHistory();
    const {ideaStore, adminStore} = useStore();


    const MyForm = React.useRef<any>()

    let {loadingInitial} = ideaStore;

    let {
        createCurrency,
        currencyLoaded,
        setCurrencyLoaded,
        selectedCurrency,
        clearSelectedCurrency,
        editCurrency
    } = adminStore;


    const [currency, setCurrency] = useState<CreateCurrencyFormValues>(new CreateCurrencyFormValues());
    const [buttonFlag, setButtonFlag] = useState(false);

    useEffect(() => {
        setCurrency(new CreateCurrencyFormValues(selectedCurrency));


    }, [selectedCurrency]);


    const cancelForm = () => {
        history.push(`/ideas/`);
    }
    
    const clearForm = () => {
        setCurrency(new CreateCurrencyFormValues());
        clearSelectedCurrency();
        setCurrencyLoaded(false);
    }
    
    const handleFormSubmit = (currency: CreateCurrencyFormValues) => {
        setButtonFlag(true)
        if (!currencyLoaded) {
            createCurrency(currency);
        } else {
            setCurrencyLoaded(false)
            editCurrency(currency);
        }
        clearForm()
    };


    const requiredValidator = (value: any, getter: any, fieldProps: any) =>
        value
            ? ''
            : fieldProps.name == 'currencyId' ? "Error: This field Currency Id is required."
                : fieldProps.name == 'currencyDescription' ? "Error: This field Currency Description is required."
                    : "Error: This field is required.";

    if (loadingInitial) return <LoadingComponent content='Loading currency...'/>

    return (
        <>
            <Grid>
                <Grid.Column width={12}>
                    <Segment clearing>
                        <Form
                            initialValues={currency}
                            ref={MyForm}
                            onSubmit={values => handleFormSubmit(values as CreateCurrencyFormValues)}
                            key={currency.currencyId}
                            render={(formRenderProps) => (

                                <FormElement>
                                    <fieldset className={'k-form-fieldset'}>
                                        {/*<Field
                                            id={'currencyId'}
                                            name={'currencyId'}
                                            label={'Currency Id *'}
                                            component={FormInput}
                                            autoComplete={"off"}
                                            validator={requiredValidator}
                                            disabled={currencyLoaded}
                                            hint={'Hint: Currency ID.'}
                                        />*/}

                                        <Field
                                            id={'currencyDescription'}
                                            name={'currencyDescription'}
                                            label={'Currency Description *'}
                                            component={FormInput}
                                            autoComplete={"off"}
                                            validator={requiredValidator}
                                            hint={'Hint: Currency Description.'}
                                        />

                                        <Field
                                            id={'exchangeRate'}
                                            format="n2"
                                            name={'exchangeRate'}
                                            label={'Exchange Rate with Saudi Riyal *'}
                                            component={FormNumericTextBox}
                                            validator={requiredValidator}
                                            hint={'Hint: Exchange Rate with Saudi Riyal.'}
                                        />


                                        <div className="k-form-buttons">
                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit }
                                            >
                                                {currencyLoaded ? "Update Currency" : "Add New Currency"}
                                            </Button>
                                            <Button onClick={cancelForm}>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => {
                                                clearForm()
                                            }
                                            }>
                                                Clear
                                            </Button>
                                        </div>

                                        {buttonFlag && loadingInitial &&
                                        <LoadingComponent content='Processing Currency...'/>}
                                    </fieldset>

                                </FormElement>

                            )}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>

        </>


    );
}

export default observer(CurrenciesForm);


