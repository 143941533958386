export interface FinancialImpact {
    ideaId: string;
    id: number;
    impactMonth: Date;
    estimated: number;
    actual: number;
}

export class FinancialImpact implements FinancialImpact {
    constructor() {

    }
}

export class FinancialImpactGrid {
    ind: number;
    month?: Date;
    saving?: number = 0;
    actual?: number = 0;
    inEdit: boolean = true;
    identifier?: number = 0;

    constructor(ind: number, month: Date, saving: number, actual?: number, identifier?: number) {
        this.ind = ind;
        this.month = month;
        this.saving = saving;
        this.actual = actual;
        this.identifier = identifier;
    }
}
