import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Chart,
    ChartArea,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
    exportVisual
} from '@progress/kendo-react-charts';

import {useStore} from '../../../app/stores/store';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {Button, Header} from "semantic-ui-react";
import {exportSVG} from "@progress/kendo-drawing";
import {saveAs} from "@progress/kendo-file-saver";


export default observer(function ProgressionByValue(props: any) {
    const {onEvent} = props;
    const {ideaStore} = useStore();
    let {
        loadProgressionByValueReportData3, progressionByValueChartDataWithTotals,
        loadingInitial, progressionByValueActualsChartData
    } = ideaStore;

    const refContainer: any = React.useRef();

    const onExportVisual = () => {
        const chartVisual = exportVisual(refContainer.current);
        if (chartVisual) {
            exportSVG(chartVisual).then(dataURI => saveAs(dataURI, 'ProgressionByValueChart.svg'));
        }
    };

    let todaysDate: Date = new Date();

    useEffect(() => {
        loadProgressionByValueReportData3();
    }, [])

    const barAxisMin = Math.min.apply(Math, progressionByValueChartDataWithTotals.map(function (o) {
        return o.total!;
    }))
    const barAxisMax = Math.max.apply(Math, progressionByValueChartDataWithTotals.map(function (o) {
        return o.total!;
    }))

    const lineAxisMin = Math.min.apply(Math, progressionByValueActualsChartData.map(function (o) {
        return o.actual;
    }))
    const lineAxisMax = Math.max.apply(Math, progressionByValueActualsChartData.map(function (o) {
        return o.actual;
    }))


    //console.log('progressionByValueChartData', progressionByValueChartData)
    const series = [
        {
            field: 'saving',
            categoryField: "month",
            ideaStatusId: 'COS 2',
            stack: true,
            note: "total",
            color: "green",
            axis: "bar"
        },
        {
            field: 'saving',
            categoryField: "month",
            ideaStatusId: 'COS 3',
            stack: false,
            note: "total",
            color: "yellow",
            axis: "bar"
        },
        {
            field: 'saving',
            categoryField: "month",
            ideaStatusId: 'COS 4',
            stack: false,
            note: "total",
            color: "blue",
            axis: "bar"
        },
        {
            field: 'saving',
            categoryField: "month",
            ideaStatusId: 'COS 5',
            stack: false,
            note: "total",
            color: "red",
            axis: "bar"
        },
    ]
    const notes: any = {
        label: {
            position: 'inside',
            color: 'black',
            background: 'yellow',
        },
        line: {
            length: 30
        },
        position: 'top',
    };

    const valueAxis = [
        {
            name: "bar",
            color: "#007eff",
            min: barAxisMin,
            max: barAxisMax,
            reverse: false
        },
        {
            name: "line",
            color: "#73c100",
            min: lineAxisMin,
            max: lineAxisMax,
            reverse: true
        }
    ];

    const axisCrossingValue: Array<number> = [0, 13];

    if (loadingInitial) return <LoadingComponent content='Generating report...'/>
    return (

        <Fragment>
            <Chart ref={refContainer} style={{
                background:
                    'center no-repeat url()'
            }}
                   transitions={false}
                   onAxisLabelClick={event => onEvent('axisLabelClick', event)}
                   onLegendItemClick={event => onEvent('legendItemClick', event)}
                   onLegendItemHover={event => onEvent('legendItemHover', event)}
                   onPlotAreaClick={event => onEvent('plotAreaClick', event)}
                   onPlotAreaHover={event => onEvent('plotAreaHover', event)}
                   onSeriesClick={event => onEvent('seriesClick', event)}
                   onSeriesHover={event => onEvent('seriesHover', event)}
            >
                <ChartArea background=""/>
                <ChartTitle
                    text={todaysDate.toLocaleString()}
                    color="blue"
                    font="10pt sans-serif"
                />
                <ChartTooltip/>
                <ChartLegend position="bottom" orientation="horizontal"/>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem majorGridLines={{visible: false}}
                                           startAngle={45}
                                           axisCrossingValue={axisCrossingValue}
                    >
                        <ChartCategoryAxisTitle text="Months"/>
                    </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem majorGridLines={{visible: false}}/>
                </ChartValueAxis>
                <ChartLegend position="top" orientation="horizontal"/>
                <ChartSeries>
                    {series.map((serie, index, series) => {
                        let seriesData = progressionByValueChartDataWithTotals.filter(item => item.ideaStatusId === serie.ideaStatusId);
                        if (series.length - 1 === index) {
                            return <ChartSeriesItem type="column"
                                                    key={serie.ideaStatusId}
                                                    stack={serie.stack}
                                                    data={seriesData}
                                                    field={serie.field}
                                                    categoryField={serie.categoryField}
                                                    name={serie.ideaStatusId}
                                                    noteTextField="total"
                                                    notes={notes}
                                                    color={serie.color}
                                                    labels={{visible: true, position: 'center'}}/>
                        } else {
                            return <ChartSeriesItem type="column"
                                                    key={serie.ideaStatusId}
                                                    stack={serie.stack}
                                                    data={seriesData}
                                                    field={serie.field}
                                                    categoryField={serie.categoryField}
                                                    name={serie.ideaStatusId}
                                                    color={serie.color}
                                                    labels={{visible: true, position: 'center'}}/>
                        }

                    })}
                </ChartSeries>
            </Chart>
            <Button onClick={() => onExportVisual()}>Export as visual element</Button>
            <Header as='h4' color='orange'>
                Report values are in Thousands Saudi Riyals
            </Header>
        </Fragment>
    );
})

//"assets/bklogoFade.jpg"







