import React, {Fragment, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from '../../../app/stores/store';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
    Grid,
    GridColumn as Column,
    GridDetailRowProps,
    GridExpandChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-grid";
import {Header} from "semantic-ui-react";
import {ExcelExport, KendoOoxml} from "@progress/kendo-react-excel-export";
import {saveAs} from "@progress/kendo-file-saver";
import {cosPotentialImpactChartReportByDepartment} from "../../../app/models/cosPotentialImpactChartReportByDepartment";

export default observer(function CosPotentialImpact() {
    const {ideaStore} = useStore();
    const [departments, setDepartments] = React.useState<cosPotentialImpactChartReportByDepartment[]>([]);

    let {
        loadCosPotentialImpactReportData, cosPotentialImpactByAffiliateAndStatusChartData,
        loadingInitial, cosPotentialImpactByDepartmentAndStatusChartData
    } = ideaStore;

    const expandChange = (event: GridExpandChangeEvent) => {
        event.dataItem.expanded = event.value;
        let affiliate = event.dataItem.affiliate;
        setDepartments([...cosPotentialImpactByDepartmentAndStatusChartData.filter(item => item.affiliate === affiliate)]);

        if (!event.value) {
            return;
        }

    };
    let todaysDate: Date = new Date();
    useEffect(() => {
        loadCosPotentialImpactReportData();
        setDepartments(cosPotentialImpactByDepartmentAndStatusChartData)
    }, [])

    const _export = React.useRef(null);

    const excelExport = () => {
        if (_export.current !== null) {

            // @ts-ignore
            let workbook = _export.current!.workbookOptions();
            let rows = workbook.sheets[0].rows;
            let headerOptions = rows[0].cells[0];

            for (let idx = cosPotentialImpactByAffiliateAndStatusChartData.length - 1; idx >= 0; idx--) {
                let departments = cosPotentialImpactByDepartmentAndStatusChartData.filter(
                    (element) => element.affiliate === cosPotentialImpactByDepartmentAndStatusChartData[idx].affiliate
                );
                for (
                    let deptIdx = departments.length - 1;
                    deptIdx >= 0;
                    deptIdx--
                ) {
                    const department = departments[deptIdx];
                    rows.splice(idx + 2, 0, {
                        cells: [
                            {},
                            {value: department.department},
                            {value: department.cos2},
                            {value: department.cos3},
                            {value: department.cos4},
                            {value: department.cos5},
                        ],
                    });
                }
                // Add the header for every details Grid
                rows.splice(idx + 2, 0, {
                    cells: [
                        {},
                        Object.assign({}, headerOptions, {value: "Department"}),
                        Object.assign({}, headerOptions, {value: "COS 2"}),
                        Object.assign({}, headerOptions, {value: "COS 3"}),
                        Object.assign({}, headerOptions, {value: "COS 4"}),
                        Object.assign({}, headerOptions, {value: "COS 5"}),
                    ],
                });

            }

            new KendoOoxml.Workbook(workbook).toDataURL().then((dataUrl) => {
                saveAs(dataUrl, "CosPotentialImpact.xlsx");

            })
        }
    }


    const DetailComponent = (props: GridDetailRowProps) => {
        return (
            <Grid
                data={departments}
                style={{height: "550px"}}>
                <Column field="department" title="Department" width="120px"/>
                <Column field="cos2" title="COS 2"/>
                <Column field="cos3" title="COS 3"/>
                <Column field="cos4" title="COS 4"/>
                <Column field="cos5" title="COS 5"/>
            </Grid>
        );
    };

    if (loadingInitial) return <LoadingComponent content='Generating report...'/>
    return (

        <Fragment>
            <ExcelExport data={cosPotentialImpactByAffiliateAndStatusChartData}
                         ref={_export}>
                <Grid
                    style={{height: "400px"}}
                    data={cosPotentialImpactByAffiliateAndStatusChartData}
                    detail={DetailComponent}
                    expandField="expanded"
                    onExpandChange={expandChange}
                >
                    <GridToolbar>
                        <button title="Export Excel" className="k-button k-primary" onClick={excelExport}>
                            Export to Excel
                        </button>
                    </GridToolbar>
                    <Column field="affiliate" title="Affiliate" width="120px"/>
                    <Column field="cos2" title="COS 2"/>
                    <Column field="cos3" title="COS 3"/>
                    <Column field="cos4" title="COS 4"/>
                    <Column field="cos5" title="COS 5"/>
                </Grid>
            </ExcelExport>
            <Header as='h4' color='orange'>
                Report values are in Thousands Saudi Riyals
            </Header>
            <Header as='h5' color='blue'>
                {todaysDate.toLocaleString()}
            </Header>
        </Fragment>
    );
})







